import {
  ALL_MATERI_GET,
  ALL_TUGAS_GET,
  ALL_LATIHAN_GET,
  ALL_TES_GET,
  ALL_TINGKAT_GET,
  ALL_KELAS_GET,
  MATERI_GET,
  TUGAS_GET,
  LATIHAN_GET,
  TES_GET,
  LATIHAN_ANSWER_GET,
} from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';
import { sortArrayObject } from '../../../../common/helpers/objectHelpers';

const initialState = {
  total: { materi: 0, tugas: 0, latihan: 0, tes: 0 },
  search: { materi: '', tugas: '', latihan: '', tes: '' },
  materi: [],
  tugas: [],
  latihan: [],
  tes: [],
  tingkat: [],
  kelas: [],
  latihanAnswer: [],
};

const allMateriGet = (state, payload) => {
  let total = { ...state.total, materi: payload.allMateri.total };
  let items = payload.allMateri.items;
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    total: total,
    materi: [...sortItems],
  };
};

const allTugasGet = (state, payload) => {
  let total = { ...state.total, tugas: payload.allTugas.total };
  return {
    ...state,
    total: total,
    tugas: [...payload.allTugas.items],
  };
};

const allLatihanGet = (state, payload) => {
  let total = { ...state.total, latihan: payload.allLatihan.total };
  return {
    ...state,
    total: total,
    latihan: [...payload.allLatihan.items],
  };
};

const allTesGet = (state, payload) => {
  let total = { ...state.total, tes: payload.allTes.total };
  return {
    ...state,
    total: total,
    tes: [...payload.allTes.items],
  };
};

const allTingkatGet = (state, payload) => {
  return {
    ...state,
    tingkat: [...payload.allTingkat],
  };
};

const allKelasGet = (state, payload) => {
  return {
    ...state,
    kelas: [...payload.allKelas],
  };
};

const materiGet = (state, payload) => {
  let items = [
    ...state.materi.filter((item) => item.id !== payload.materi.id),
    payload.materi,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    materi: [...sortItems],
  };
};

const tugasGet = (state, payload) => {
  let items = [
    ...state.tugas.filter((item) => item.id !== payload.tugas.id),
    payload.tugas,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    tugas: [...sortItems],
  };
};

const latihanGet = (state, payload) => {
  let items = [
    ...state.latihan.filter((item) => item.id !== payload.latihan.id),
    payload.latihan,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    latihan: [...sortItems],
  };
};

const tesGet = (state, payload) => {
  let items = [
    ...state.tes.filter((item) => item.id !== payload.tes.id),
    payload.tes,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    tes: [...sortItems],
  };
};

const latihanAnswerGet = (state, payload) => {
  let items;
  if (state.latihanAnswer && state.latihanAnswer[0]) {
    items = [
      ...state.latihanAnswer.filter(
        (item) => item.id !== payload.latihanAnswer.id
      ),
      payload.latihanAnswer,
    ];
  } else {
    items = [payload.latihanAnswer];
  }
  return {
    ...state,
    latihanAnswer: [...items],
  };
};

export default createReducer(initialState, {
  [ALL_MATERI_GET]: allMateriGet,
  [ALL_TUGAS_GET]: allTugasGet,
  [ALL_LATIHAN_GET]: allLatihanGet,
  [ALL_TES_GET]: allTesGet,
  [ALL_TINGKAT_GET]: allTingkatGet,
  [ALL_KELAS_GET]: allKelasGet,
  [MATERI_GET]: materiGet,
  [TUGAS_GET]: tugasGet,
  [LATIHAN_GET]: latihanGet,
  [TES_GET]: tesGet,
  [LATIHAN_ANSWER_GET]: latihanAnswerGet,
});
