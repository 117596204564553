import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import menusReducer from '../menu/navbar/Sidemenu/redux/menusReducer';
import authReducer from '../menu/login/redux/authReducer';
import asyncReducer from '../common/async/asyncReducer';
import swipeReducer from '../common/swipe/reduxReducer';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import modalReducer from '../menu/modals/redux/modalReducer';
import progressReducer from '../menu/pages/progress/redux/progressReducer';
import detailsReducer from '../menu/pages/details/redux/detailsReducer';
// Student Parents Portal
import userroleReducer from '../menu/navbar/redux/reduxReducer';
import slideshowReducer from '../menu/students-menu/slideshow/redux/reduxReducer';
import sprofilReducer from '../menu/students-menu/profil/redux/reduxReducer';
import spresensiReducer from '../menu/students-menu/presensi/redux/reduxReducer';
import pembelajaranReducer from '../menu/students-menu/pembelajaran/redux/reduxReducer';
import sjadwalReducer from '../menu/students-menu/jadwal/redux/reduxReducer';
import srapormidReducer from '../menu/students-menu/rapor-mid/redux/reduxReducer';
import sraporsemReducer from '../menu/students-menu/rapor-sem/redux/reduxReducer';
import srapormidkolabReducer from '../menu/students-menu/rapor-kolab/redux/reduxReducerMid';
import sraporsemkolabReducer from '../menu/students-menu/rapor-kolab/redux/reduxReducerSem';
import shasiltestpsikologismidReducer from '../menu/students-menu/hasil-test-psikologis/redux/reduxReducerMid';
import shasiltestpsikologissemReducer from '../menu/students-menu/hasil-test-psikologis/redux/reduxReducerSem';
import sprogressreportReducer from '../menu/students-menu/progress-report/redux/reduxReducer';
import sraporsemtkReducer from '../menu/students-menu/rapor-sem-tk/redux/reduxReducer';
import saccountsReducer from '../menu/students-menu/account-statement/redux/reduxReducer';
import sagendaReducer from '../menu/students-menu/agenda/redux/reduxReducer';
import skalenderReducer from '../menu/students-menu/kalender-akademik/redux/reduxReducer';
import spesanReducer from '../menu/students-menu/pesan-pribadi/redux/reduxReducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const appReducer = combineReducers({
  auth: authReducer,
  // Student Parents Portal
  slideshow: slideshowReducer,
  userrole: userroleReducer,
  sprofil: sprofilReducer,
  spresensi: spresensiReducer,
  pembelajaran: pembelajaranReducer,
  sjadwal: sjadwalReducer,
  srapormid: srapormidReducer,
  sraporsem: sraporsemReducer,
  srapormidkolab: srapormidkolabReducer,
  sraporsemkolab: sraporsemkolabReducer,
  shasiltestpsikologismid: shasiltestpsikologismidReducer,
  shasiltestpsikologissem: shasiltestpsikologissemReducer,
  sprogressreport: sprogressreportReducer,
  sraporsemtk: sraporsemtkReducer,
  saccounts: saccountsReducer,
  sagenda: sagendaReducer,
  skalender: skalenderReducer,
  spesan: spesanReducer,
  // End Student Parents Portal
  form: formReducer,
  menus: menusReducer,
  toastr: toastrReducer,
  async: asyncReducer,
  swipe: swipeReducer,
  modals: modalReducer,
  details: detailsReducer,
  progress: progressReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = initialState;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
