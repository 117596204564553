import {
  ALL_MATERI_GET,
  ALL_TUGAS_GET,
  ALL_LATIHAN_GET,
  ALL_TES_GET,
  ALL_TINGKAT_GET,
  ALL_KELAS_GET,
  MATERI_GET,
  TUGAS_GET,
  LATIHAN_GET,
  TES_GET,
  LATIHAN_ANSWER_GET,
} from './reduxConstant';

export const allMateriGet = (allMateri) => {
  return {
    type: ALL_MATERI_GET,
    payload: {
      allMateri,
    },
  };
};

export const allTugasGet = (allTugas) => {
  return {
    type: ALL_TUGAS_GET,
    payload: {
      allTugas,
    },
  };
};

export const allLatihanGet = (allLatihan) => {
  return {
    type: ALL_LATIHAN_GET,
    payload: {
      allLatihan,
    },
  };
};

export const allTesGet = (allTes) => {
  return {
    type: ALL_TES_GET,
    payload: {
      allTes,
    },
  };
};

export const allTingkatGet = (allTingkat) => {
  return {
    type: ALL_TINGKAT_GET,
    payload: {
      allTingkat,
    },
  };
};

export const allKelasGet = (allKelas) => {
  return {
    type: ALL_KELAS_GET,
    payload: {
      allKelas,
    },
  };
};

export const materiGet = (materi) => {
  return {
    type: MATERI_GET,
    payload: {
      materi,
    },
  };
};

export const tugasGet = (tugas) => {
  return {
    type: TUGAS_GET,
    payload: {
      tugas,
    },
  };
};

export const latihanGet = (latihan) => {
  return {
    type: LATIHAN_GET,
    payload: {
      latihan,
    },
  };
};

export const tesGet = (tes) => {
  return {
    type: TES_GET,
    payload: {
      tes,
    },
  };
};

export const latihanAnswerGet = (latihanAnswer) => {
  return {
    type: LATIHAN_ANSWER_GET,
    payload: {
      latihanAnswer,
    },
  };
};
