import React, { useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, Field, reset } from 'redux-form';
import { useHistory } from 'react-router-dom';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
  createValidator,
} from 'revalidate';
import TextInput from '../../../common/form/TextInput';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { updatePassword } from './redux/reduxApi';
import { openModal } from '../../modals/redux/modalActions';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';
import { useUnamePass } from '../../../common/helpers/othersHelpers';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'ubah-password'
    )[0];
  }
  return {
    auth: state.auth,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  updatePassword,
  openModal,
};

let newPassword;
const handleOnFormChange = (newValues, dispatch, props, previousValues) => {
  const { newPass: newNewPass } = newValues;
  newPassword = newNewPass;
};

const isPassEqual = createValidator(
  (message) => (value) => {
    if (value && value !== newPassword) {
      return message;
    }
  },
  'Password tidak sama!'
);

const isAlphaNum = createValidator(
  (message) => (value) => {
    if (value && !value.match(/^[0-9a-z]+$/)) {
      return message;
    }
  },
  'Password karakter harus huruf atau angka'
);

const validate = combineValidators({
  oldPass: composeValidators(
    isRequired({ message: 'Password lama harus diisi' })
  )(),
  newPass: composeValidators(
    isRequired({ message: 'Password baru harus diisi' }),
    hasLengthGreaterThan(3)({
      message: 'Password harus memiliki paling sedikit 4 karakter',
    }),
    isAlphaNum
  )(),
  confirmPass: composeValidators(
    isRequired({ message: 'Konfirmasi password harus diisi' }),
    isPassEqual
  )(),
});

function Index(props) {
  const uPass = useUnamePass();
  const dispatch = useDispatch();
  const [notif, setNotif] = useState('');
  const [hidden, setHidden] = useState('');
  let history = useHistory();
  const {
    auth,
    handleSubmit,
    invalid,
    loading,
    pristine,
    updatePassword,
    openModal,
  } = props;
  const username = auth.vusn;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      props.swipeToggleStatus(true);
    },
  });

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  const onFormSubmit = (values) => {
    setNotif('');
    setHidden(false);
    updatePassword(auth, history, values).then((messages) => {
      setNotif(messages[0]);
      if (messages[0] === 'Sukses ubah password.') {
        dispatch(reset('changePass'));
      }
      setTimeout(() => {
        setHidden(true);
      }, 3500);
    });
  };

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/pengaturan-akun'}>
        <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/pengaturan-akun')}
                        >
                          <i className='fas fa-sliders-h icon' /> Pengaturan
                          Akun
                        </span>
                      </p>
                    </div>
                  </header>
                  <div id='fadein' className='card-content' style={{ paddingLeft: -20 }}>
                    {notif === 'Invalid Old Password.' && (
                      <div
                        className='notification is-centered is-danger is-light custom-fade-out'
                        style={hidden ? { display: 'none' } : {}}
                      >
                        Password lama salah
                      </div>
                    )}
                    {notif === 'Sukses ubah password.' && (
                      <div
                        className='notification is-centered is-success is-light custom-fade-out'
                        style={hidden ? { display: 'none' } : {}}
                      >
                        {notif}
                      </div>
                    )}
                    <div className='content ml-3 mr-3'>
                      <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className='field'>
                          <label className='label is-6'>
                            <i className='fas fa-key icon' /> Ubah Password
                          </label>
                          <div className='control'>
                            <input
                              name='userId'
                              className='input'
                              type='text'
                              placeholder='Username'
                              value={auth.vusn}
                              disabled
                            />
                          </div>
                        </div>
                        <Field
                          name='oldPass'
                          type='password'
                          component={TextInput}
                          placeholder='Password Lama'
                          label='Password Lama'
                        />
                        <Field
                          name='newPass'
                          type='password'
                          component={TextInput}
                          placeholder='Password Baru'
                          label='Password Baru'
                        />
                        <Field
                          name='confirmPass'
                          type='password'
                          component={TextInput}
                          placeholder='Konfirmasi Password'
                          label='Konfirmasi Password'
                        />
                        <div className='field'>
                          <p className='control has-text-centered'>
                            <button
                              type='submit'
                              className='button is-success'
                              disabled={invalid || loading || pristine}
                            >
                              Submit
                            </button>
                          </p>
                        </div>
                      </form>
                      {uPass && uPass.includes('ios_') && (
                        <>
                          <hr style={{ marginTop: 35 }} />
                          <div className='field' style={{ marginTop: 35 }}>
                            <label className='label is-6'>
                              <i className='fas fa-user-slash icon' />
                              Hapus Akun
                            </label>
                            <div className='control'>
                              <input
                                name='userId'
                                className='input'
                                type='text'
                                placeholder='Username'
                                value={auth.vusn}
                                disabled
                              />
                            </div>
                          </div>
                          <div className='field'>
                            <div className='control has-text-centered pb-5'>
                              <p className='has-text-left'>
                                User dapat menghapus akun parent portal dengan
                                menekan tombol dibawah.
                              </p>
                              <p className='has-text-left is-italic is-size-7'>
                                note: User perlu menghubungi admin parent portal
                                sekolah jika ingin dapat mengakses kembali.
                              </p>
                              <button
                                onClick={() =>
                                  openModal('DeleteAccountModal', {
                                    auth,
                                    username,
                                    history,
                                    uPass,
                                  })
                                }
                                className='button is-danger mt-2'
                              >
                                Hapus
                              </button>
                              <br />
                              <br />
                              <br />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(
  mapState,
  actions
)(
  reduxForm({ form: 'changePass', onChange: handleOnFormChange, validate })(
    Index
  )
);
