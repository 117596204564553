import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getAgenda } from './redux/reduxApi';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter((i) => i.id === 'agenda')[0];
  }
  return {
    auth: state.auth,
    sagenda: state.sagenda,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getAgenda,
};

function Index(props) {
  let history = useHistory();
  const { swipeToggleStatus, getAgenda, auth, sagenda } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    getAgenda(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  async function handleClick(url) {
    let baseUrl = 'http://202.43.173.27:3381/IMAGELAIN/';
    window.open(baseUrl + url.replace('__', '/'), '_blank');
  }

  const ListItems =
    sagenda && sagenda.length > 0 ? (
      sagenda.map((item, key) => (
        <div
          className='card mb-2 no-shadow has-background-primary-light bubble'
          key={key}
          style={{ borderRadius: 10 }}
        >
          <header className='card-header' style={{ boxShadow: 'none' }}>
            <p className='card-header-title has-text-info'>{item.date}</p>
          </header>
          <div className='card-content' style={{ marginTop: -24 }}>
            <div className='content overflow-auto ml-3 mr-3'>
              {item.message}
              {item.files && item.files.length > 0 && (
                <>
                  <br />
                  <p>
                    {item.files.map((item, key) => (
                      <span
                        onClick={() => handleClick(item)}
                        className='mr-2 hand-pointer tag is-info'
                        key={key}
                      >
                        <i className='fas fa-file mr-1' />{' '}
                        {item.split('/').slice(-1)}
                      </span>
                    ))}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      ))
    ) : (
      <p id='fadein2' className='ml-2 is-italic mb-3'>
        Tidak ada agenda
      </p>
    );

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/agenda'}>
      <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/agenda')}
                        >
                          <i className='fas fa-bullhorn icon' /> Agenda
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content' style={{ paddingLeft: -20 }}>
                    <div id='fadein' className='content'>
                      <div
                        className='custom-agenda-flex ml-2 mr-2'
                        style={{ marginBottom: -20, marginTop: -14 }}
                      >
                        {ListItems}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
