import { AGENDA_GET } from './reduxConstant';

export const agendaGet = (agenda) => {
  return {
    type: AGENDA_GET,
    payload: {
      agenda,
    },
  };
};
