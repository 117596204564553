import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

export default function Gallery(props) {
  const [count, setCount] = useState(0);
  const { images } = props;

  setTimeout(() => {
    setCount(1);
  }, 1500);

  return (
    <PullDownWrapper>
      <div>
        <div style={{ display: 'none' }}>{count}</div>
        <ImageGallery
          items={images}
          showThumbnails={false}
          showBullets={true}
          showNav={false}
          autoPlay={true}
          slideInterval={4000}
          showFullscreenButton={false}
        />
      </div>
    </PullDownWrapper>
  );
}
