import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../../common/util/siteConfig';
import styled from 'styled-components';
import { ellipsis } from 'polished';

const DescriptionText = styled.div`
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  ${({ showMore }) => showMore && ellipsis(undefined, 16)}
`;

const ShowMoreText = styled.div`
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 30px;
  text-decoration: underline;
`;

function ViewMateriForm(props) {
  const { auth, history, initialValues, attachments, openModal, downloadFile } =
    props;

  const [isShowMore, setIsShowMore] = useState(true);
  const toggleReadMore = () => setIsShowMore((show) => !show);

  const onClickAttachment = (item) => {
    function checkImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    let isImage = checkImage(item.filelink);
    if (isImage) {
      openModal('PopUpImageModal', {
        data: SITE_ADDRESS_PEMBELAJARAN + item.filelink,
      });
    } else {
      downloadFile(auth, history, initialValues, item);
    }
  };

  return (
    <>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <div
            className='control mb-4'
            style={{
              padding: '2mm',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1 className='is-capitalized title is-5 mb-5 custom-text-overflow'>
                {initialValues.title}
              </h1>
              <button
                onClick={() => history.goBack()}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='fas fa-arrow-left icon' />
              </button>
            </div>
            <div className='is-hidden-mobile'>
              <div
                dangerouslySetInnerHTML={{
                  __html: initialValues.description,
                }}
              ></div>
            </div>
            <div className='is-hidden-tablet'>
              <DescriptionText showMore={isShowMore}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: initialValues.description,
                  }}
                ></div>
              </DescriptionText>
              <ShowMoreText onClick={toggleReadMore}>
                {isShowMore ? 'Show more...' : 'Show less'}
              </ShowMoreText>
            </div>
          </div>
          <hr />
          {attachments && attachments[0] && (
            <>
              <div className='field' style={{ marginBottom: -5 }}>
                <label className='label'>Attachment(s)</label>
              </div>
              <div className='column is-full-tablet is-three-quarters-desktop'>
                {attachments.map((item, index) => (
                  <div
                    key={index}
                    className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center'
                  >
                    <span
                      className='hand-pointer'
                      onClick={() => onClickAttachment(item)}
                    >
                      {item.filename} (
                      {item.filesize.length < 7
                        ? Math.round(parseInt(item.filesize) / 1000) + ' KB'
                        : Math.round(parseInt(item.filesize) / 1000000) + ' MB'}
                      )
                    </span>
                  </div>
                ))}
              </div>
            </>
          )}
          <label className='label'>Tags:</label>
          <div className='tags is-capitalized'>
            {initialValues &&
              initialValues.tags &&
              JSON.parse(initialValues.tags).map((item, index) => (
                <span key={index} className='tag is-success is-light'>
                  {item}
                </span>
              ))}
            {initialValues && !initialValues.tags && <>-</>}
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'materiViewForm',
  enableReinitialize: true,
})(ViewMateriForm);
