import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const DropzoneInput = ({
  sprofil,
  auth,
  site,
  userProfile,
  setFiles,
  setFileToLarge,
}) => {
  const maxSize = 200000;
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive, rejectedFiles } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: 'image/*',
      minSize: 1000,
      maxSize: maxSize,
    });

  useEffect(() => {
    const isFileTooLarge =
      rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
    if (isFileTooLarge) {
      setFileToLarge(true);
    } else {
      setFileToLarge(false);
    }
  }, [rejectedFiles, setFileToLarge]);

  return (
    <div
      {...getRootProps()}
      className={'dropzone ' + (isDragActive && 'dropzone--isActive')}
    >
      <input {...getInputProps()} />
      <figure id='profile-pic' className='image is-128x128'>
        <div>
          <div
            style={{
              width: 128,
              height: 128,
              backgroundImage: `url(${
                sprofil && sprofil.urlPhoto
                  ? site + sprofil.urlPhoto
                  : userProfile
              })`,
              borderRadius: '50%',
              backgroundSize: 'cover',
            }}
          />
          {sprofil && !sprofil.urlPhoto && (
            <i
              className='fas fa-plus icon'
              style={{
                fontSize: 50,
                position: 'relative',
                bottom: 75,
                color: 'rgb(0 0 0 / 10%)',
              }}
            />
          )}
        </div>
      </figure>
    </div>
  );
};

export default DropzoneInput;
