import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { accountsGet, accountsDetailGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';

export const getAccounts = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAccounts' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'account-statement/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const accounts = response.accounts;
      const newArr = accounts.split('content :').join('').split('kdctc=')[1];
      let newObj = {
        id: newArr && newArr.split(';')[0],
        content: newArr
      }
      dispatch(accountsGet(newObj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getAccountsDetail = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAccountsDetail' });
    const userId = auth.userId;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('accountsId', id);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'account-statement/view', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const accountsDetail = response.accountsDetail;
      const status = accountsDetail && JSON.parse(accountsDetail)?.[0].status;
      const content = status[0].ISI;
      const obj = {
        accountsContent: content,
      };
      dispatch(accountsDetailGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getAccountsDetailRaw = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAccountsDetail' });
    const userId = auth.userId;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('accountsId', id);
    console.log(id);
    
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'account-statement/view-raw', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const accountsDetail = response.accountsDetail;
      const content = accountsDetail && JSON.parse(accountsDetail)[0];
      const obj = {
        accountsContent: content,
      };
      dispatch(accountsDetailGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getAccountsDetailRawFetch = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAccountsDetail' });
    const userId = auth.userId;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('accountsId', id);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'account-statement/view-raw-fetch', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const accountsDetail = response.accountsDetail;
      const content = accountsDetail && JSON.parse(accountsDetail)[0];
      const obj = {
        accountsContent: content,
      };
      dispatch(accountsDetailGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getAccountsDetailString = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAccountsDetail' });
    const userId = auth.userId;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('accountsId', id);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'account-statement/view-string', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const accountsDetail = response.accountsDetail;
      const obj = {
        accountsContent: accountsDetail,
      };
      dispatch(accountsDetailGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getAccountsDetail2 = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAccountsDetail' });
    const userId = auth.userId;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('accountsId', id);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'account-statement/view2', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const accountsDetail = response.accountsDetail;
      const status = accountsDetail && JSON.parse(accountsDetail)?.[0].status;
      const content = status[0].ISI;
      const obj = {
        accountsContent: content,
      };
      dispatch(accountsDetailGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
