import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getMateri, downloadFile } from './redux/reduxApi';
import { openModal } from '../../modals/redux/modalActions';
import ViewMateriForm from './ViewMateriForm';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state, ownProps) {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'pembelajaran'
    )[0];
  }
  let materi = {};
  let attachments = [];
  if (
    state.pembelajaran &&
    state.pembelajaran.materi &&
    state.pembelajaran.materi.length > 0
  ) {
    materi = state.pembelajaran.materi.filter(
      (item) => item.id + '' === id + ''
    )[0];
    if (materi && materi.id) {
      attachments = materi.attachments ? JSON.parse(materi.attachments) : [];
    }
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    scope: scope,
    id: id,
    materi: materi,
    attachments: attachments,
  };
}

const actions = {
  swipeToggleStatus,
  getMateri,
  downloadFile,
  openModal,
};

function ViewMateri(props) {
  let history = useHistory();
  const {
    openModal,
    swipeToggleStatus,
    getMateri,
    downloadFile,
    auth,
    id,
    materi,
    attachments,
  } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    getMateri(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (values) => {};

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  return (
    <>
      <PullDownWrapper auth={auth}>
      <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/pembelajaran')}
                        >
                          <i className='fas fa-book icon' /> Pembelajaran
                        </span>
                        <i className='fas fa-angle-right icon' />
                        <span
                          className='hand-pointer has-text-white is-capitalized'
                          onClick={() =>
                            handleLink('/pembelajaran/materi/' + id)
                          }
                        >
                          Materi
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content ml-4 mr-4'>
                    <ViewMateriForm
                      auth={auth}
                      history={history}
                      id={id}
                      initialValues={materi}
                      attachments={attachments}
                      onFormSubmit={onFormSubmit}
                      openModal={openModal}
                      downloadFile={downloadFile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(ViewMateri);
