import { RAPORSEM_GET, RAPORSEMDETAIL_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = [];

const raporsemGet = (state, payload) => {
  return [...payload.raporsem];
};

const raporsemDetailGet = (state, payload) => {
  let raporsemDetail = {
    ...state.filter((item) => item.id === payload.raporsemDetail.id)[0],
    ...payload.raporsemDetail,
  };
  let newState = [
    ...state.filter((item) => item.id !== payload.raporsemDetail.id),
    raporsemDetail,
  ];
  console.log(newState);
  return [
    ...state.filter((item) => item.id !== payload.raporsemDetail.id),
    raporsemDetail,
  ];
};

export default createReducer(initialState, {
  [RAPORSEM_GET]: raporsemGet,
  [RAPORSEMDETAIL_GET]: raporsemDetailGet,
});
