import React from 'react';

const SelectInput = ({
  input,
  label,
  type,
  icon,
  placeholder,
  multiple,
  options,
  fullwidth,
  disabled,
  readOnly,
  style,
  meta: { touched, error },
}) => {
  return (
    <div className='field'>
      <label className='label is-size-7' htmlFor={input.name}>
        {label}
      </label>
      <div
        className={
          touched && !!error
            ? 'control is-danger is-expanded has-icons-left'
            : 'control is-expanded has-icons-left'
        }
      >
        <div
          className={fullwidth === true ? 'select is-fullwidth is-small is-rounded' : 'select is-small is-rounded'}
        >
          <select
            id={input.name}
            value={input.value}
            onChange={input.onChange}
            placeholder={placeholder}
            multiple={multiple}
            disabled={readOnly}
            style={style}
          >
            <option value=''>Pilih {label}</option>
            {options &&
              options.map((item) => (
                <option key={item.key} value={item.value} style={style}>
                  {item.text}
                </option>
              ))}
          </select>
        </div>
        <div className="icon is-small is-left">
          <i className={`fas fa-${icon}`}></i>
        </div>
        {touched && error && <p className='help is-danger'>{error}</p>}
      </div>
    </div>
  );
};

export default SelectInput;
