import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SITE_ADDRESS } from '../../../common/util/siteConfig';
import UserProfile from '../../../../images/user-default.png';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getProfil } from './redux/reduxApi';
import Photo from './Photo';
import ReactTooltip from 'react-tooltip';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter((i) => i.id === 'profil')[0];
  }
  return {
    auth: state.auth,
    sprofil: state.sprofil,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getProfil,
};

function Index(props) {
  let history = useHistory();
  const { swipeToggleStatus, getProfil, auth, sprofil, loading } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    getProfil(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  let ListItemsTimeline = '';
  if (sprofil && sprofil.student_history) {
    ListItemsTimeline = sprofil.student_history.map((item, key) => {
      return(
      <li key={key}>
        <div className='has-text-white' style={{maxWidth: 280}}>
          <time>Kelas {item && item.content.split(';')[3]}</time>
          <span>Jenjang {item && item.content.split(';')[2]}</span> <br />
          <span className='mr-3'>
            Tahun Ajaran {item && item.content.split(';')[0]}
          </span>{' '}
          <br />
          <span>Semester {item && item.content.split(';')[1]}</span>
        </div>
      </li>
    )});
  }

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/profil'}>
        <div {...handlers} style={SWIPE_STYLE} />
        <div
          id='maincontent'
          className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'
        >
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/profil')}
                        >
                          <i className='fas fa-user icon' />
                          Profil
                        </span>
                      </p>
                    </div>
                  </header>
                  <div id='fadein' className='card-content pl-4 pr-4'>
                    <div className='level mb-3'>
                      <div className='level-item'>
                        <div data-for='main' data-tip='Ukuran maks. 200Kb'>
                          <Photo
                            site={SITE_ADDRESS}
                            auth={auth}
                            sprofil={sprofil}
                            userProfile={UserProfile}
                            loading={loading}
                            history={history}
                          />
                        </div>
                        <ReactTooltip
                          id='main'
                          place='bottom'
                          type='dark'
                          effect='solid'
                          multiline={true}
                        />
                      </div>
                    </div>
                    <div className='level mb-3'>
                      <div className='level-item'>
                        <p className='title is-6 is-capitalized'>
                          {auth.username}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <table
                      className='table is-fullwidth is-narrow is-hoverable'
                      style={{ marginTop: -16, marginBottom: -16 }}
                    >
                      <tbody>
                        <tr>
                          <td>NIS / NISN</td>
                          <td className='has-text-right'>{sprofil.nis_nisn}</td>
                        </tr>
                        <tr>
                          <td>Alamat</td>
                          <td className='has-text-right'>
                            <p className='is-italic'>{sprofil.address}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>Tempat / Tanggal Lahir</td>
                          <td className='has-text-right'>
                            {sprofil.place_birth}
                          </td>
                        </tr>
                        <tr>
                          <td>Telepon</td>
                          <td className='has-text-right'>
                            <p className='is-italic'>{sprofil.phone}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {sprofil && sprofil.student_history && (
                      <>
                        <div className='mt-6'>
                          <p className='ml-2 has-text-weight-medium mb-2'>
                            Sejarah Siswa
                          </p>
                        </div>
                        <section className='timeline'>
                          <ul>{ListItemsTimeline}</ul>
                        </section>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
