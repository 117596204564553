import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import {
  getHasiltestpsikologismid,
  downloadHasiltestpsikologismid,
  getLinkDownloadHasiltestpsikologismid,
  getHasiltestpsikologissem,
  downloadHasiltestpsikologissem,
  getLinkDownloadHasiltestpsikologissem,
} from './redux/reduxApi';
import LoadingButton from '../../../main/LoadingButton';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'hasil-test-psikologis'
    )[0];
  }
  
  let filterShasiltestpsikologissem =
    state.shasiltestpsikologissem &&
    state.shasiltestpsikologissem[0] &&
    state.shasiltestpsikologissem.filter((item) => item?.id?.includes('PSIKO'));
    
  return {
    auth: state.auth,
    shasiltestpsikologismid: state.shasiltestpsikologismid,
    shasiltestpsikologissem: filterShasiltestpsikologissem,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getHasiltestpsikologismid,
  downloadHasiltestpsikologismid,
  getLinkDownloadHasiltestpsikologismid,
  getHasiltestpsikologissem,
  downloadHasiltestpsikologissem,
  getLinkDownloadHasiltestpsikologissem,
};

function Index(props) {
  let history = useHistory();
  const [dataLocal, setDataLocal] = useState('');
  const {
    swipeToggleStatus,
    getHasiltestpsikologismid,
    downloadHasiltestpsikologismid,
    getLinkDownloadHasiltestpsikologismid,
    getHasiltestpsikologissem,
    downloadHasiltestpsikologissem,
    getLinkDownloadHasiltestpsikologissem,
    loading,
    auth,
    shasiltestpsikologismid,
    shasiltestpsikologissem,
  } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    let data = localStorage.getItem('unamePass');
    if (data) {
      setDataLocal(data);
    }
  }, []);

  useEffect(() => {
    getHasiltestpsikologismid(auth, history);
    getHasiltestpsikologissem(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  const handleDownload = useCallback(
    async (e, id, published, period) => {
      e.preventDefault();
      if (dataLocal) {
        if (period === 'mid') {
          let link = await getLinkDownloadHasiltestpsikologismid(
            auth,
            history,
            id,
            published
          );
          window.ReactNativeWebView.postMessage('url:' + link);
        } else {
          let link = await getLinkDownloadHasiltestpsikologissem(
            auth,
            history,
            id,
            published
          );
          window.ReactNativeWebView.postMessage('url:' + link);
        }
      } else {
        if (period === 'mid') {
          await downloadHasiltestpsikologismid(auth, history, id, published);
        } else {
          await downloadHasiltestpsikologissem(auth, history, id, published);
        }
      }
    },
    [
      auth,
      history,
      downloadHasiltestpsikologismid,
      downloadHasiltestpsikologissem,
      getLinkDownloadHasiltestpsikologismid,
      getLinkDownloadHasiltestpsikologissem,
      dataLocal,
    ]
  );

  const Hasiltestpsikologismid =
    !loading &&
    shasiltestpsikologismid &&
    shasiltestpsikologismid.length > 0 ? (
      shasiltestpsikologismid.map((item, key) => (
        <tr key={key}>
          {item && item.classgroup && <td>{key + 1}</td>}
          <td className='has-text-left'>{item.classgroup}</td>
          <td>
            {item.year}{' '}
            <span className='is-hidden-tablet'>
              {item?.sem?.replace('Semester', 'Sem')}
            </span>
          </td>
          <td className='is-hidden-mobile'>{item.sem}</td>
          <td>
            <button
              disabled={loading}
              onClick={(e) => handleDownload(e, item.id, item.published, 'mid')}
              className='button is-info is-small is-rounded is-outlined'
            >
              Download
            </button>
          </td>
        </tr>
      ))
    ) : (
      <></>
    );

  const Hasiltestpsikologissem =
    !loading &&
    shasiltestpsikologissem &&
    shasiltestpsikologissem.length > 0 ? (
      shasiltestpsikologissem.map((item, key) => {
        return (
          <tr key={key}>
            {item && item.classgroup && <td>{key + 1}</td>}
            <td className='has-text-left'>{item.classgroup}</td>
            <td>
              {item.year}
              <span className='is-hidden-tablet'>
                {' '}
                {item?.sem?.replace('Semester', 'Sem')}
              </span>
            </td>
            <td className='is-hidden-mobile'>{item.sem}</td>
            <td>
              <button
                disabled={loading}
                onClick={(e) =>
                  handleDownload(e, item.id, item.published, 'sem')
                }
                className='button is-info is-small is-rounded is-outlined'
              >
                {loading ? 'Downloading' : 'Download'}
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <></>
    );

  const noHasiltestpsikologis =
    !loading &&
    shasiltestpsikologismid &&
    shasiltestpsikologismid.length < 1 &&
    shasiltestpsikologissem &&
    shasiltestpsikologissem.length < 1 ? (
      <tr>
        <td colSpan={5}>
          <p id='fadein2' className='has-text-left mt-3 is-italic'>
            Tidak ada data.
          </p>
        </td>
      </tr>
    ) : (
      <></>
    );

  return (
    <>
      <PullDownWrapper
        auth={auth}
        history={history}
        link={'/hasil-test-psikologis'}
      >
        <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/hasil-test-psikologis')}
                        >
                          <i className='fas fa-id-card icon' /> Hasil Test
                          Psikologis
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content' style={{ paddingLeft: -20 }}>
                    <div
                      className='content overflow-auto'
                      style={{ marginTop: -16 }}
                    >
                      <table id='fadein' className='table has-text-centered'>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Kelas</th>
                            <th>
                              <span className='is-hidden-mobile'>
                                Tahun Ajaran
                              </span>
                              <span className='is-hidden-tablet'>
                                {' '}
                                TA / Sem
                              </span>
                            </th>
                            <th className='is-hidden-mobile'>Semester</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading && (
                            <tr>
                              <td>
                                <LoadingButton />
                              </td>
                            </tr>
                          )}
                          {!loading && (
                            <>
                              {shasiltestpsikologismid &&
                                shasiltestpsikologismid.length > 0 && (
                                  <tr>
                                    <td colSpan={5}>
                                      <p className='has-text-left has-text-weight-medium'>
                                        Mid
                                      </p>
                                    </td>
                                  </tr>
                                )}
                              {Hasiltestpsikologismid}
                              {shasiltestpsikologissem &&
                                shasiltestpsikologissem.length > 0 && (
                                  <tr>
                                    <td colSpan={5}>
                                      <p className='has-text-left has-text-weight-medium'>
                                        Semester
                                      </p>
                                    </td>
                                  </tr>
                                )}
                              {Hasiltestpsikologissem}
                              {noHasiltestpsikologis}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
