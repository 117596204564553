import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { slideshowGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import { timeToUnix } from '../../../../common/helpers/timeConvert';

export const getSlideshow = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getSlideshow' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'slideshow/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const slideshow = response.slideshow;
      const newArr = slideshow.split('<br/>').filter((e) => /\S/.test(e));
      let arrSlideshow = [];
      if (newArr.length > 0) {
        newArr.forEach((item) => {
          let imageDate = item.split('IMAGELAIN/')[1].split(',');
          let image = imageDate[0];
          let date = timeToUnix(imageDate[1]);

          let newObj = { image, date };
          arrSlideshow.push(newObj);
        });
      }
      dispatch(slideshowGet(arrSlideshow));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
