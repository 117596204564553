import { PESAN_GET } from './reduxConstant';
import { STATUS_UPDATE } from './reduxConstant';

export const pesanGet = (pesan) => {
  return {
    type: PESAN_GET,
    payload: {
      pesan,
    },
  };
};

export const statusUpdate = (pesan) => {
  return {
    type: STATUS_UPDATE,
    payload: {
      pesan,
    },
  };
};
