import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import SideMenu from './Sidemenu/SideMenu';
import LogoPicture from '../../../images/logo.png';
import { sessionLogout } from '../login/redux/authApi';
import { swipeToggleStatus } from '../../common/swipe/reduxApi';
import { getProfil } from '../students-menu/profil/redux/reduxApi';
import { updateAuth } from '../login/redux/authApi';
import BottomNavbar from '../navbar/BottomNavbar';
import { SITE_ADDRESS } from '../../common/util/siteConfig';
import UserProfile from '../../../images/user-default.png';
import { useUnamePass } from '../../common/helpers/othersHelpers';

const mapState = (state) => {
  let unreadPesan = [];
  if (state.spesan) {
    unreadPesan = state.spesan.filter((item) => {
      return item.status + '' === '0';
    });
  }
  return {
    auth: state.auth,
    userrole: state.userrole,
    menus: state.menus,
    loading: state.async.loading,
    unreadPesan: unreadPesan,
    elementName: state.async.elementName,
    swipe: state.swipe,
    sprofil: state.sprofil,
  };
};

const actions = {
  sessionLogout,
  swipeToggleStatus,
  getProfil,
  updateAuth,
};

export class Navbar extends Component {
  _isMounted = false;
  state = {
    width: 0,
    isTogglerActive: false,
    inside: false,
    outside: false,
    uPass: null,
  };

  componentDidMount = (prevProps, prevState) => {
    const { auth, history } = this.props;
    let uPass = useUnamePass();
    this.setState({
      ...this.state,
      uPass,
    });
    this._isMounted = true;
    getProfil(auth, history);
    const bodyElement = document.getElementsByTagName('BODY')[0];
    this.setState({
      width: bodyElement.offsetWidth,
    });

    if (window.addEventListener) {
      window.addEventListener('resize', this.onResizeEvent, true);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    window.removeEventListener('resize', this.onResizeEvent, true);
  };

  onResizeEvent = () => {
    const bodyElement = document.getElementsByTagName('BODY')[0];
    const newWidth = bodyElement.offsetWidth;
    if (newWidth !== this.state.width) {
      this.setState({
        width: newWidth,
      });
    }
  };

  handleToggler = () => {
    this.setState({
      isTogglerActive: !this.state.isTogglerActive,
    });
    const burger = document.getElementById('burger');
    if (!this.state.isTogglerActive) {
      burger.classList.add('active');
      burger.classList.remove('not-active');
    } else {
      burger.classList.add('not-active');
      burger.classList.remove('active');
    }
  };

  handleInsideClick = (value) => {
    this.setState({
      inside: value,
    });
  };

  handleTogglerOnOff = (value) => {
    this.setState({
      isTogglerActive: value,
    });
  };

  handleActiveLink = () => {
    const { auth, location } = this.props;
    const regex = new RegExp(`/profile/${auth.userId}`);
    const isActive = regex.test(location.pathname);
    return isActive;
  };

  handleLink = (value) => {
    this.props.history.push(value.split('_')[0]);
  };

  handleSessionLogout = (elementName) => {
    const { sessionLogout, history } = this.props;
    if (this.state && this.state.uPass && this.state.uPass.length > 0) {
      window.ReactNativeWebView.postMessage('logout');
    }
    sessionLogout(elementName, history);
  };

  render() {
    const {
      auth,
      menus,
      location,
      history,
      loading,
      elementName,
      swipe,
      swipeToggleStatus,
      unreadPesan,
      sprofil,
      userrole,
      updateAuth,
    } = this.props;
    const { isTogglerActive } = this.state;
    let uPass = this.state && this.state.uPass;
    return (
      <>
        <nav id='navbarTop' className={`navbar is-fixed-top box-shadow-y`}>
          <div className='navbar-brand'>
            {auth.isAuth === true && (
              <div
                style={!uPass ? {} : { display: 'none' }}
                ref={this.navbarBurger}
                id='burger'
                onClick={this.handleToggler}
                className='navbar-burger toggler not-active is-hidden-tablet'
              >
                <span />
                <span />
                <span />
              </div>
            )}
            <div
              className='navbar-item is-size-5 has-text-weight-semibold hand-pointer2'
              onClick={() => this.handleLink('/dashboard_')}
              style={
                auth.isAuth === true && this.state.width < 769 && !uPass
                  ? { marginLeft: 38 }
                  : {}
              }
            >
              <img alt='profile-pic' src={LogoPicture} />
              <span className='ml-1'>Sekolah Makarios</span>
            </div>
            {/* profile picture apps */}
            {auth.isAuth === true && (
              <div
                className='is-hidden-desktop'
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                {!uPass && unreadPesan && unreadPesan.length > 0 && (
                  <div
                    onClick={() => this.handleLink('/pesan-pribadi_')}
                    className='navbar-item hand-pointer'
                    style={{ marginRight: -8 }}
                  >
                    <i className='fas fa-envelope icon is-size-5' />
                    <span
                      style={{
                        height: 8,
                        width: 8,
                        backgroundColor: 'red',
                        borderRadius: 100,
                        position: 'absolute',
                        left: 29,
                        top: 20,
                      }}
                    ></span>
                  </div>
                )}
                <div
                  onClick={() => this.handleLink('/profil_')}
                  className='navbar-item hand-pointer2'
                  style={{ marginRight: -12 }}
                >
                  <div className='navbar-item'>
                    <div
                      style={{
                        width: 28,
                        height: 28,
                        backgroundImage: `url(${
                          sprofil && sprofil.urlPhoto
                            ? SITE_ADDRESS + sprofil.urlPhoto
                            : UserProfile
                        })`,
                        borderRadius: '50%',
                        backgroundSize: 'cover',
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {auth.isAuth === true && (
            <div className='navbar-menu'>
              <div className='navbar-end'>
                {unreadPesan && unreadPesan.length > 0 && (
                  <div
                    className='navbar-item has-dropdown is-hoverable'
                    onClick={() => this.handleLink('/pesan-pribadi_')}
                  >
                    <div id='navBell' className='navbar-item hand-pointer'>
                      <i className='fas fa-envelope icon' />
                      <span
                        style={{
                          height: 8,
                          width: 8,
                          backgroundColor: 'red',
                          borderRadius: 100,
                          position: 'absolute',
                          left: 27,
                          top: 17,
                        }}
                      ></span>
                    </div>
                  </div>
                )}
                {location.pathname !== '/register-2' &&
                  location.pathname !== '/register-3' && (
                    <div className='navbar-item has-dropdown is-hoverable'>
                      <div id='navUser' className='navbar-item hand-pointer'>
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            backgroundImage: `url(${
                              sprofil && sprofil.urlPhoto
                                ? SITE_ADDRESS + sprofil.urlPhoto
                                : UserProfile
                            })`,
                            borderRadius: '50%',
                            backgroundSize: 'cover',
                          }}
                        />
                        <span className='is-size-7 ml-1'>{auth.vusn}</span>
                      </div>
                      <div className='navbar-dropdown is-right'>
                        <Link
                          to={`/profil`}
                          className={
                            this.handleActiveLink() === true
                              ? 'navbar-item is-active has-background-white-ter'
                              : 'navbar-item'
                          }
                        >
                          <i className='fas fa-user-circle icon mr-1'></i>Profil
                          Siswa
                        </Link>
                        <Link to={`/pengaturan-akun`} className='navbar-item'>
                          <i className='fas fa-key icon mr-1'></i> Ubah Password
                        </Link>
                        <hr className='navbar-divider' />
                        {loading === true && elementName === 'logoutNav' ? (
                          <button
                            disabled
                            className='button is-loading is-text is-small'
                          >
                            <i className='fas fa-sign-out-alt icon mr-1'></i>{' '}
                            Logout
                          </button>
                        ) : (
                          <Fragment>
                            <div
                              onClick={() =>
                                this.handleSessionLogout('logoutNav')
                              }
                              className='navbar-item hand-pointer'
                            >
                              <i className='fas fa-sign-out-alt icon mr-1'></i>{' '}
                              Logout
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </nav>
        {auth.isAuth === true &&
          location.pathname !== '/register-2' &&
          location.pathname !== '/register-3' && (
            <>
              <SideMenu
                toggler={isTogglerActive}
                inside={this.state.inside}
                swipe={swipe}
                swipeToggleStatus={swipeToggleStatus}
                handleInsideClick={this.handleInsideClick}
                handleTogglerOnOff={this.handleTogglerOnOff}
                sprofil={sprofil}
                auth={auth}
                menus={menus}
                logout={this.handleSessionLogout}
                loading={loading}
                elementName={elementName}
                pathname={location.pathname}
                history={history}
                userrole={userrole}
                updateAuth={updateAuth}
              />
              {uPass && uPass.length > 0 && (
                <BottomNavbar
                  sprofil={sprofil}
                  unreadPesan={unreadPesan}
                  logout={this.handleSessionLogout}
                  userrole={userrole}
                  updateAuth={updateAuth}
                  pathname={location.pathname}
                  uPass={uPass}
                  menus={menus}
                />
              )}
            </>
          )}
      </>
    );
  }
}

export default withRouter(connect(mapState, actions)(Navbar));
