import { RAPORMIDKOLAB_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = [];

const rapormidkolabGet = (state, payload) => {
  return [...payload.rapormidkolab];
};

export default createReducer(initialState, {
  [RAPORMIDKOLAB_GET]: rapormidkolabGet,
});
