import React from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { combineValidators, isRequired } from 'revalidate';
import { reduxForm, Field } from 'redux-form';
import CustomTextInput from '../../common/form/CustomTextInput';
import { loginAuth } from './redux/authApi';
import PullDownWrapper from '../../common/layout/PullDownWrapper';
import { useUnamePass } from '../../common/helpers/othersHelpers';
import LoadingButton from '../../common/layout/LoadingButton';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
  };
}

const actions = {
  loginAuth,
};

const validate = combineValidators({
  username: isRequired({ message: 'Username is required' }),
  password: isRequired({ message: 'Password is required' }),
});

function Login(props) {
  const { loginAuth, handleSubmit, auth, error, loading } = props;
  const uPass = useUnamePass();

  const handleLogin = async (authData) => {
    await loginAuth(authData);
  };

  if (auth && auth.isAuth && !auth.registerParents)
    return <Redirect to='/dashboard' />;
  if (auth && auth.isAuth && auth.registerParents)
    return <Redirect to='/register-2' />;

  return (
    <>
      <PullDownWrapper>
        <section className='hero is-fullheight' style={{ marginTop: -52 }}>
          {uPass && (
            <div
              className='hero-body'
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                height: '100%',
              }}
            >
              <LoadingButton />
            </div>
          )}
          {!uPass && (
            <>
              <div className='hero-body'>
                <div className='container'>
                  <div className='columns'>
                    <div className='column is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd'></div>
                    <div className='column is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd is-centered'>
                      <div className='box' style={{ minWidth: 300 }}>
                        <div className='media'>
                          <div className='media-content'>
                            <div className='content'>
                              <div className='title has-text-centered'>
                                Login
                              </div>
                              <form
                                onSubmit={handleSubmit(handleLogin)}
                                autoComplete='off'
                              >
                                <Field
                                  name='username'
                                  type='text'
                                  component={CustomTextInput}
                                  placeholder='Username'
                                  autoComplete='new-password'
                                  label='Username'
                                  icon='user'
                                />
                                <Field
                                  name='password'
                                  type='password'
                                  autoComplete='new-password'
                                  component={CustomTextInput}
                                  placeholder='Password'
                                  label='Password'
                                  icon='lock'
                                />
                                <div
                                  className='field has-text-centered'
                                  style={{ marginTop: 25 }}
                                >
                                  {error && (
                                    <p className='help is-danger'>{error}</p>
                                  )}
                                  <button
                                    disabled={loading}
                                    type='submit'
                                    style={{ marginRight: 10 }}
                                    className='button is-link is-small is-rounded is-outlined'
                                  >
                                    {loading ? 'Submitting' : 'Submit'}
                                  </button>
                                </div>
                              </form>
                              <div className='has-text-link is-size-7 has-text-right is-italic hand-pointer mt-4'>
                                <Link to='/register'>
                                  <i className='fas fa-user-plus icon' />
                                  Register Orang Tua/Wali
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='column is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd'></div>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </PullDownWrapper>
    </>
  );
}

export default withRouter(
  connect(mapState, actions)(reduxForm({ form: 'formLogin', validate })(Login))
);
