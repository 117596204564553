import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { getAgenda } from '../agenda/redux/reduxApi';
import { useHistory } from 'react-router-dom';

function mapState(state) {
  return {
    auth: state.auth,
    agenda: state.sagenda,
  };
}

const actions = {
  getAgenda
}

function Slide(props) {
  const history = useHistory();
  const [count, setCount] = useState(0);
  const { auth, getAgenda, agenda } = props;

  useEffect(() => {
    getAgenda(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (agenda && count < agenda.length - 1) {
        setCount(count + 1);
      } else {
        setCount(0);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [agenda, count]);

  const handleClick = useCallback(() => {
    history.push(`/agenda`);
  }, [history]);

  return (
    <>
      {agenda && agenda.length > 0 && (
        <div
          id='dashboard-bubble'
          onClick={handleClick}
          className='content hand-pointer border has-background-primary-light pl-3 pr-3 pt-2 pb-2'
          style={{
            marginTop: -12,
            marginBottom: -10,
            height: 83,
            overflow: 'hidden',
            borderRadius: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h6 style={{ marginBottom: 4 }}>{agenda[count].date}</h6>
            {agenda[count].files && agenda[count].files.length > 0 && (
              <span className='is-italic'>
                <i className='fas fa-file' /> +{agenda[count].files.length} file
                {agenda[count].files.length > 0 ? 's' : ''}
              </span>
            )}
          </div>
          {agenda[count].message}
        </div>
      )}
      {agenda && agenda.length <= 0 && (
        <p
          id='fadein2'
          className='is-italic mb-2 has-text-weight-normal has-text-grey'
        >
          <span style={{ fontSize: 14 }}>Tidak ada agenda</span>
        </p>
      )}
    </>
  );
}

export default connect(mapState, actions)(Slide)
