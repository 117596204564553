import React from 'react'
import {connect} from 'react-redux';
import NotAuthorizedModal from './NotAuthorizedModal';
import TestModal from '../tests/TestModal'
import MenuModal from '../navbar/MenuModal';
import ImageModal from '../../common/components/ImageModal';
import DeleteAccountModal from '../students-menu/pengaturan-akun/DeleteAccountModal';
import PopUpImageModal from '../../common/layout/PopUpImageModal';

const modalLookup = {
    NotAuthorizedModal,
    MenuModal,
    ImageModal,
    PopUpImageModal,
    DeleteAccountModal,
    TestModal,
}

const mapState = (state) => ({
    currentModal: state.modals
})

const ModalManager = ({currentModal}) => {
    let renderedModal;

    if(currentModal) {
        const {modalType, modalProps} = currentModal;
        const ModalComponent = modalLookup[modalType];

        renderedModal = <ModalComponent {...modalProps} />
    }
    return (
        <span>{renderedModal}</span>
    )
}

export default connect(mapState)(ModalManager)
