import { RAPORSEMTK_GET, RAPORSEMTKDETAIL_GET, RAPORSEMTKPDF_GET } from './reduxConstant';

export const raporsemtkGet = (raporsemtk) => {
  return {
    type: RAPORSEMTK_GET,
    payload: {
      raporsemtk,
    },
  };
};

export const raporsemtkDetailGet = (raporsemtkDetail) => {
  return {
    type: RAPORSEMTKDETAIL_GET,
    payload: {
      raporsemtkDetail,
    },
  };
};

export const raporsemtkPdfGet = (raporsemtkPdf) => {
  return {
    type: RAPORSEMTKPDF_GET,
    payload: {
      raporsemtkPdf,
    },
  };
};
