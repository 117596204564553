// Fetch with timeout
export const fetchWithTimeOut = async (url, options, time) => {
  let timeout = time * 1000;
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Take to long, Connection was reset. Please try again.')), timeout)
    ),
  ]);
};

// Get Random Number
export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const useUnamePass = () => {
  let uPass = localStorage.getItem('unamePass');
  return uPass;
};
