import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../menu/modals/redux/modalActions';

const actions = {
  closeModal,
};

class ImageModal extends Component {
  render() {
    const { url, closeModal } = this.props;
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-content'>
          <p className='image is-4by3'>
            <img
              src={url}
              alt='preview'
            />
          </p>
        </div>
        <button className='modal-close is-large' aria-label='close' onClick={closeModal}></button>
      </div>
    );
  }
}

export default connect(null, actions)(ImageModal);
