import { PROGRESSREPORT_GET, PROGRESSREPORTDETAIL_GET, PROGRESSREPORTPDF_GET } from './reduxConstant';

export const progressreportGet = (progressreport) => {
  return {
    type: PROGRESSREPORT_GET,
    payload: {
      progressreport,
    },
  };
};

export const progressreportDetailGet = (progressreportDetail) => {
  return {
    type: PROGRESSREPORTDETAIL_GET,
    payload: {
      progressreportDetail,
    },
  };
};

export const progressreportPdfGet = (progressreportPdf) => {
  return {
    type: PROGRESSREPORTPDF_GET,
    payload: {
      progressreportPdf,
    },
  };
};
