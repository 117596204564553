import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import { authLogout } from '../../../login/redux/authAction';

export const updatePassword = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'updatePassword' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    formData.append('oldPass', values.oldPass);
    formData.append('newPass', values.newPass);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'password/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const messages = response.messages
        .split('content :')
        .join('')
        .split('<br/>')
        .filter((e) => /\S/.test(e));
      dispatch(asyncActionFinish());
      return messages;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const deleteAccount = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteAccount' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('vusn', vusn);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'delete-account/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      history.push("/");
      dispatch(authLogout());
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
