import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { profilGet, profilUpdate } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import { Menus, PrJuSeMenus, KGMenus } from '../../../../common/util/menus';
import { menusGet } from '../../../navbar/Sidemenu/redux/menusAction';
import { sortArrayObject } from '../../../../common/helpers/objectHelpers';

export const getProfil = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getProfil' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'profil/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const profil = response.profil;
      const checkTK = profil.includes('TK');
      const checkSD = profil.includes('SD');
      const checkSMP = profil.includes('SMP');
      const checkSMA = profil.includes('SMA');
      const urlPhoto = response.urlPhoto;
      const newArr = profil.split('<br/>');
      let obj = {
        nis_nisn: '',
        address: '',
        place_birth: '',
        phone: '',
        student_history: [],
        urlPhoto: '',
        isTK: false,
        isSD: false,
        isSMP: false,
        isSMA: false,
      };
      if (newArr.length > 0) {
        obj.nis_nisn = newArr && newArr[1];
        obj.address = newArr && newArr[3];
        obj.place_birth = newArr && newArr[4];
        obj.phone = newArr && newArr[5];
        let arrObj = newArr && newArr.slice(6, newArr.length);
        let nosort_student_history = [];
        for (let i = 0; i < arrObj.length; i++) {
          let dataArr = arrObj[i].split(';');
          let dataObj = {
            id: parseInt(dataArr[3].charAt(0) + '' + dataArr[1]),
            content: arrObj[i],
          };
          nosort_student_history.push(dataObj);
        }
        obj.student_history = nosort_student_history.sort((a, b) =>
          a.id < b.id ? 1 : b.id < a.id ? -1 : 0
        );
        obj.urlPhoto = urlPhoto;
        obj.isTK = checkTK;
        obj.isSD = checkSD;
        obj.isSMP = checkSMP;
        obj.isSMA = checkSMA;
      }
      let FullMenus = [...Menus];
      if (checkTK === true) {
        FullMenus = [...FullMenus, ...KGMenus];
      }
      if (checkSD || checkSMP || checkSMA) {
        FullMenus = [...FullMenus, ...PrJuSeMenus];
      }
      if (checkSD) {
        FullMenus = [...FullMenus];
      }
      const sortMenus = sortArrayObject(FullMenus, 'order', 'asc');
      dispatch(menusGet(sortMenus));
      dispatch(profilGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const photoUpload = (data) => {
  return async (dispatch) => {
    console.log('photo upload');
    dispatch({ type: ASYNC_ACTION_START, payload: 'photoUpload' });
    const sessionId = data.authData.sessionId;
    const userId = data.authData.userId;
    const username = data.authData.username;
    const image = data.image;
    const filename = data.filename;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username.toLowerCase().split(' ').join('_'));
    formData.append('image', image);
    formData.append('filename', filename);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS + 'profil/upload-photo/' + userId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + data.authData.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(
        response,
        fetchData,
        dispatch,
        200,
        data.history
      );
      if (resultCheck) {
        throw resultCheck;
      }
      const urlPhoto = response.urlPhoto;
      let obj = {
        urlPhoto: urlPhoto,
      };
      dispatch(profilUpdate(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
