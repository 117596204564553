import { HASILTESTPSIKOLOGISSEM_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = [];

const hasiltestpsikologissemGet = (state, payload) => {
  return [...payload.hasiltestpsikologissem];
};

export default createReducer(initialState, {
  [HASILTESTPSIKOLOGISSEM_GET]: hasiltestpsikologissemGet,
});
