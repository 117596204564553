import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import {
  getRaporsem,
  downloadRaporSem,
  getLinkDownloadRaporSem,
} from './redux/reduxApi';
import { getHasiltestpsikologissem } from '../hasil-test-psikologis/redux/reduxApi';
import LoadingButton from '../../../main/LoadingButton';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter((i) => i.id === 'erapor')[0];
  }

  let filterraporsem =
    state.shasiltestpsikologissem &&
    state.shasiltestpsikologissem[0] &&
    state.shasiltestpsikologissem.filter((item) => item?.id?.includes('SEM'));

  let newFilterraporsem = [];
  if (filterraporsem && filterraporsem[0]) {
    for (const item of filterraporsem) {
      let duplicate = false;
      for (const itemFilter of state.sraporsem) {
        if (item.id.includes(itemFilter.id)) {
          duplicate = true;
        }
      }
      if (!duplicate) {
        newFilterraporsem.push({ ...item, source: 'PSIKO' });
      }
    }
  }

  let newSraporsem = [
    ...state.sraporsem,
    ...(newFilterraporsem && newFilterraporsem[0] ? newFilterraporsem : []),
  ];

  const uniques = [...new Set(newSraporsem.map((o) => JSON.stringify(o)))].map(
    (s) => JSON.parse(s)
  );

  return {
    auth: state.auth,
    sraporsem: uniques,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getRaporsem,
  downloadRaporSem,
  getLinkDownloadRaporSem,
  getHasiltestpsikologissem,
};

function Index(props) {
  let history = useHistory();
  const [dataLocal, setDataLocal] = useState('');
  const {
    swipeToggleStatus,
    getRaporsem,
    downloadRaporSem,
    getLinkDownloadRaporSem,
    getHasiltestpsikologissem,
    loading,
    auth,
    sraporsem,
  } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    let data = localStorage.getItem('unamePass');
    if (data) {
      setDataLocal(data);
    }
  }, []);

  useEffect(() => {
    getRaporsem(auth, history);
    getHasiltestpsikologissem(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  const handleDownload = async (id, published, item) => {
    if (dataLocal) {
      let link = await getLinkDownloadRaporSem(
        auth,
        history,
        id,
        published,
        item.source
      );
      window.ReactNativeWebView.postMessage('url:' + link);
    } else {
      await downloadRaporSem(auth, history, id, published, item.source);
    }
  };

  const ListItems =
    !loading && sraporsem.length > 0 ? (
      sraporsem.map((item, key) => (
        <tr key={key}>
          {item && item.classgroup && <td>{key + 1}</td>}
          <td className='has-text-left'>{item.classgroup}</td>
          <td>
            {item.year}
            <span className='is-hidden-tablet'>
              {' '}
              {item?.sem?.replace('Semester', 'Sem')}
            </span>
          </td>
          <td className='is-hidden-mobile'>{item.sem}</td>
          {item.type === 'html' && (
            <td>
              <button
                onClick={() => handleLink(`/rapor-sem/${item.id}`)}
                className='button is-info is-small is-rounded is-outlined'
              >
                Lihat
              </button>
            </td>
          )}
          {item.type === 'pdf' && (
            <td>
              <button
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  handleDownload(item.id, item.published, item);
                }}
                className='button is-info is-small is-rounded is-outlined'
              >
                Download
              </button>
            </td>
          )}
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={5}>
          <p id='fadein2' className='has-text-left mt-3 is-italic'>
            Tidak ada rapor semester.
          </p>
        </td>
      </tr>
    );

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/rapor-sem'}>
        <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/rapor-sem')}
                        >
                          <i className='fas fa-id-card icon' /> Rapor Semester
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content' style={{ paddingLeft: -20 }}>
                    <div
                      className='content overflow-auto'
                      style={{ marginTop: -16 }}
                    >
                      <table id='fadein' className='table has-text-centered'>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Kelas</th>
                            <th>
                              <span className='is-hidden-mobile'>
                                Tahun Ajaran
                              </span>
                              <span className='is-hidden-tablet'>
                                {' '}
                                TA / Sem
                              </span>
                            </th>
                            <th className='is-hidden-mobile'>Semester</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td>
                                <LoadingButton />
                              </td>
                            </tr>
                          ) : (
                            ListItems
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
