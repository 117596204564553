import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { pesanGet, statusUpdate } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';

export const getPesan = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPesan' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'pesan/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const pesan = response.pesan;
      const newArr = pesan.split('content :').join('').split('~~~PEMISAH~~~').filter((e) => /\S/.test(e));
      const arrObj = [];
      for (let i = 0; i < newArr.length; i++) {
        let arrData = newArr[i].split('<br/>').filter((e) => /\S/.test(e));
        let obj = { };
        obj.date = arrData[0]
          .replace(/(<([^>]+)>)/gi, '');
        obj.messageContent = arrData[1];
        obj.messageId = arrData[2].split('ID PESAN : ')[1];
        obj.status = arrData[3].split('Status : ')[1];
        obj.title = arrData[4].split('JUDUL PESAN : ')[1];
        arrObj.push(obj);
      }
      dispatch(pesanGet(arrObj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const updateStatus = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'updateStatus' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const messageId = values.messageId;
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    formData.append('messageId', messageId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'pesan/update-status', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const pesan = values;
      pesan.status = 1;
      dispatch(statusUpdate(pesan));
      dispatch(asyncActionFinish());
      return true;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
