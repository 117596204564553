import { KALENDER_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = {};

const kalenderGet = (state, payload) => {
  return { ...payload.kalender };
};

export default createReducer(initialState, {
  [KALENDER_GET]: kalenderGet,
});
