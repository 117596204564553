import { RAPORMID_GET, RAPORMIDDETAIL_GET } from './reduxConstant';

export const rapormidGet = (rapormid) => {
  return {
    type: RAPORMID_GET,
    payload: {
      rapormid,
    },
  };
};

export const rapormidDetailGet = (rapormidDetail) => {
  return {
    type: RAPORMIDDETAIL_GET,
    payload: {
      rapormidDetail,
    },
  };
};
