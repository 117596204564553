import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getProfil } from '../profil/redux/reduxApi';
import { getUserrole } from '../../navbar/redux/reduxApi';
import { getPesan } from '../pesan-pribadi/redux/reduxApi';
import { getSlideshow } from '../slideshow/redux/reduxApi';
import Slide from './Slide';
import Gallery from '../slideshow/Index';
import { SITE_ADDRESS } from '../../../common/util/siteConfig';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';
import { useUnamePass } from '../../../common/helpers/othersHelpers';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'dashboard'
    )[0];
  }
  let unreadPesan = [];
  if (state.spesan && state.spesan.length > 0) {
    unreadPesan = state.spesan.filter((item) => {
      return item.status + '' === '0';
    });
  }
  return {
    auth: state.auth,
    role: state.userrole,
    unreadPesan: unreadPesan,
    slideshow: state.slideshow,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getProfil,
  getUserrole,
  getPesan,
  getSlideshow,
};

function Index(props) {
  let history = useHistory();
  const {
    swipeToggleStatus,
    getProfil,
    getUserrole,
    getPesan,
    auth,
    unreadPesan,
    getSlideshow,
    slideshow,
  } = props;
  const uPass = useUnamePass();
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    getProfil(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserrole(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSlideshow(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPesan(auth, history);
  }, [getPesan, auth, history]);

  useEffect(() => {
    if (uPass && uPass.length > 0) {
      if (unreadPesan && unreadPesan.length > 0) {
        document.querySelector('#bottomNavNotif').style.display = 'block';
      } else {
        document.querySelector('#bottomNavNotif').style.display = 'none';
      }
    }
  }, [uPass, unreadPesan]);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  const images = [];

  slideshow.forEach((item, index) => {
    let image = {
      original: `${SITE_ADDRESS}slideshow/view/${item.date}_${item.image}`,
      originalTitle: item.image,
    };
    images.push(image);
  });

  let slideShowItems =
    images && images.length > 0 ? <Gallery images={images} /> : <></>;

  if (auth && !auth.isAuth) {
    if (uPass && uPass.length > 0) {
      window.ReactNativeWebView.postMessage('logout');
    }
    return <Redirect to='/login' />;
  }

  if (auth && auth.isAuth && auth.registerParents)
    return <Redirect to='/register-2' />;

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/dashboard'}>
        <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/dashboard')}
                        >
                          <i className='fas fa-th-large icon' />
                          Dashboard
                        </span>
                      </p>
                    </div>
                  </header>
                  <div id='fadein' className='card-content pl-3 mr-3'>
                    {slideshow && slideshow.length > 0 && (
                      <>
                        <h1
                          className='mb-1 has-text-weight-bold has-text-info'
                          style={{ marginTop: -20 }}
                        >
                          <i className='fas fa-images mr-1' /> Galeri
                        </h1>
                        {slideShowItems}
                        <hr style={{ margin: 18 }} />
                      </>
                    )}
                    <h1
                      className='has-text-weight-bold has-text-link mb-4'
                      style={{ marginTop: -10 }}
                    >
                      <i className='fas fa-bullhorn mr-1' /> Agenda
                    </h1>
                    <Slide
                      history={history}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
