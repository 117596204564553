import { KALENDER_GET } from './reduxConstant';

export const kalenderGet = (kalender) => {
  return {
    type: KALENDER_GET,
    payload: {
      kalender,
    },
  };
};
