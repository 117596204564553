import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { raporsemGet, raporsemDetailGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import getUnixTime from 'date-fns/getUnixTime';
import { saveAs } from 'file-saver';

export const getRaporsem = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRaporsem' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'rapor-sem/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const raporsem = response.raporsem;
      const newArr = raporsem
        .split('content :')
        .join('')
        .split('<br/>')
        .filter((e) => /\S/.test(e));
      let newObj = [];
      if (!raporsem.includes('Tidak ada Rapor')) {
        for (let i = 0; i < newArr.length; i++) {
          if (!raporsem.includes('.pdf')) {
            let id = newArr[i].split('kdrapot=')[1].split(';')[0];
            let content = newArr[i].split('kdrapot=')[1];
            let year = content.split(';')[1];
            let sem = content.split(';')[2];
            let classgroup = content.split(';')[3];
            let obj = {
              id: id,
              published: '',
              year: year,
              sem: sem,
              classgroup: classgroup,
              type: 'html',
            };
            newObj.push(obj);
          } else {
            let id = newArr[i].split('IMAGEMURID/')[1].split('SEM.pdf')[0];
            let dateTime = newArr[i].split('pdf-(')[1].split(')')[0];
            let amPM = dateTime.split(' ')[2];
            let dataDate = dateTime.split(' ')[0];
            let Day = dataDate.split('/')[1];
            let Month = dataDate.split('/')[0] - 1;
            let Year = dataDate.split('/')[2];
            let dataTime = dateTime.split(' ')[1];
            let Hour =
              amPM === 'PM'
                ? parseInt(dataTime.split(':')[0]) + 12
                : dataTime.split(':')[0];
            let Minute = dataTime.split(':')[1];
            let Second = dataTime.split(':')[2];
            // get unixTime for unique naming pdf
            let published = getUnixTime(
              new Date(
                parseInt(Year),
                parseInt(Month),
                parseInt(Day),
                parseInt(Hour),
                parseInt(Minute),
                parseInt(Second)
              )
            );
            let content = newArr[i].split(');')[1];
            let year = content.split(';')[0];
            let sem = content.split(';')[1];
            let classgroup = content.split(';')[2];
            let obj = {
              id: id,
              published: published,
              year: year,
              sem: sem,
              classgroup: classgroup,
              type: 'pdf',
            };
            newObj.push(obj);
          }
        }
      }
      dispatch(raporsemGet(newObj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getRaporsemDetail = (auth, history, id, linkRapor) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRaporsemDetail' });
    const userId = auth.userId;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('raporsemId', id);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'rapor-sem/view', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const raporsemDetail = response.raporsemDetail;
      const raporsemContent = raporsemDetail.split('content :').join('');
      const obj = {
        id: response.raporsemId,
        content: linkRapor,
        raporsemContent: raporsemContent,
      };
      dispatch(raporsemDetailGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const downloadRaporSem = (auth, history, id, published, source) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadRaporSem' });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('published', published);
    try {
      if (source === 'PSIKO') {
        formData.append('raporsemkolabId', id);
        const fetchData = await fetch(
          SITE_ADDRESS + 'rapor-sem-kolab/download',
          {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: 'Bearer ' + auth.token,
            },
          }
        );
        const blob = await fetchData.blob();
        saveAs(blob, `rapor_semester_${id}.pdf`);
        dispatch(asyncActionFinish());
      } else {
        formData.append('raporsemId', id);
        const fetchData = await fetch(SITE_ADDRESS + 'rapor-sem/download', {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        });
        const blob = await fetchData.blob();
        let name = id.split('/');
        saveAs(blob, `rapor_semester_${name[0]}_${name[1]}.pdf`);
        dispatch(asyncActionFinish());
      }
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getLinkDownloadRaporSem = (
  auth,
  history,
  id,
  published,
  source
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLinkDownloadRaporSem' });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('published', published);
    try {
      formData.append('raporsemkolabId', id);
      if (source === 'PSIKO') {
        const fetchData = await fetch(
          SITE_ADDRESS + 'rapor-sem-kolab/link-download',
          {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: 'Bearer ' + auth.token,
            },
          }
        );
        const response = await fetchData.json();
        const linkRapor = response.url;
        dispatch(asyncActionFinish());
        return linkRapor;
      } else {
        formData.append('raporsemId', id);
        const fetchData = await fetch(
          SITE_ADDRESS + 'rapor-sem/link-download',
          {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: 'Bearer ' + auth.token,
            },
          }
        );
        const response = await fetchData.json();
        const linkRapor = response.url;
        dispatch(asyncActionFinish());
        return linkRapor;
      }
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
