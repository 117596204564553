import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import View from './View2';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'accounts'
    )[0];
  }
  return {
    auth: state.auth,
    saccounts: state.saccounts,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
};

function Index(props) {
  let history = useHistory();
  const { swipeToggleStatus, auth, saccounts, scope, loading } =
    props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );
// Index used
  return (
    <>
      <PullDownWrapper
        auth={auth}
        history={history}
        link={'/account-statement'}
      >
        <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <i className='fas fa-file-invoice-dollar icon' />
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/account-statement')}
                        >
                          Account Statement Detail
                        </span>
                      </p>
                    </div>
                  </header>
                  <div
                    className='card-content'
                    style={{ paddingLeft: -20, paddingBottom: -25 }}
                  >
                    <div id='fadein' className='content'>
                      <div
                        id='accounts'
                        className='custom-agenda-flex ml-2 mr-2 overflow-auto'
                      >
                        <View
                          auth={auth}
                          loading={loading}
                          scope={scope}
                          saccounts={saccounts}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
