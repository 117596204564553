import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { rapormidkolabGet, raporsemkolabGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import getUnixTime from 'date-fns/getUnixTime';
import { saveAs } from 'file-saver';
// Mid
export const getRapormidkolab = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRapormidkolab' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'rapor-mid-kolab/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const rapormidkolab = response.rapormidkolab;
      const newArr = rapormidkolab
        .split('content :')
        .join('')
        .split('<br/>')
        .filter((e) => /\S/.test(e));
      let newObj = [];
      if (!rapormidkolab.includes('Tidak ada Rapor')) {
        for (let i = 0; i < newArr.length; i++) {
          let id = newArr[i].split('IMAGEMURID/')[1].split('MID.pdf')[0];
          let dateTime = newArr[i].split('pdf-(')[1].split(')')[0];
          let amPM = dateTime.split(' ')[2];
          let dataDate = dateTime.split(' ')[0];
          let Day = dataDate.split('/')[1];
          let Month = dataDate.split('/')[0] - 1;
          let Year = dataDate.split('/')[2];
          let dataTime = dateTime.split(' ')[1];
          let Hour =
            amPM === 'PM'
              ? parseInt(dataTime.split(':')[0]) + 12
              : dataTime.split(':')[0];
          let Minute = dataTime.split(':')[1];
          let Second = dataTime.split(':')[2];
          // get unixTime for unique naming pdf
          let published = getUnixTime(
            new Date(
              parseInt(Year),
              parseInt(Month),
              parseInt(Day),
              parseInt(Hour),
              parseInt(Minute),
              parseInt(Second)
            )
          );
          let content = newArr[i].split(');')[1];
          let year = content.split(';')[0];
          let sem = content.split(';')[1];
          let classgroup = content.split(';')[2];
          let obj = {
            id: id,
            published: published,
            year: year,
            sem: sem,
            classgroup: classgroup,
            type: 'pdf',
          };
          newObj.push(obj);
        }
      }
      dispatch(rapormidkolabGet(newObj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const downloadRapormidkolab = (auth, history, id, published) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadRapormidkolab' });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('rapormidkolabId', id);
    formData.append('published', published);
    try {
      const fetchData = await fetch(SITE_ADDRESS + 'rapor-mid-kolab/download', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const blob = await fetchData.blob();
      let name = id.split('/');
      saveAs(blob, `rapor_mid_kolab_${name[0]}_${name[1]}.pdf`);
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getLinkDownloadRapormidkolab = (auth, history, id, published) => {
  return async (dispatch) => {
    dispatch({
      type: ASYNC_ACTION_START,
      payload: 'getLinkDownloadRapormidkolab',
    });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('rapormidkolabId', id);
    formData.append('published', published);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'rapor-mid-kolab/link-download',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const linkRapor = response.url;
      dispatch(asyncActionFinish());
      return linkRapor;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
// Sem
export const getRaporsemkolab = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRaporsemkolab' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'rapor-sem-kolab/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const raporsemkolab = response.raporsemkolab;
      const newArr = raporsemkolab
        .split('content :')
        .join('')
        .split('<br/>')
        .filter((e) => /\S/.test(e));
      console.log(newArr);
      let newObj = [];
      if (!raporsemkolab.includes('Tidak ada Rapor')) {
        for (let i = 0; i < newArr.length; i++) {
          let id = newArr[i].split('IMAGEMURID/')[1].split('SEM.pdf')[0];
          let dateTime = newArr[i].split('pdf-(')[1].split(')')[0];
          let amPM = dateTime.split(' ')[2];
          let dataDate = dateTime.split(' ')[0];
          let Day = dataDate.split('/')[1];
          let Month = dataDate.split('/')[0] - 1;
          let Year = dataDate.split('/')[2];
          let dataTime = dateTime.split(' ')[1];
          let Hour =
            amPM === 'PM'
              ? parseInt(dataTime.split(':')[0]) + 12
              : dataTime.split(':')[0];
          let Minute = dataTime.split(':')[1];
          let Second = dataTime.split(':')[2];
          // get unixTime for unique naming pdf
          let published = getUnixTime(
            new Date(
              parseInt(Year),
              parseInt(Month),
              parseInt(Day),
              parseInt(Hour),
              parseInt(Minute),
              parseInt(Second)
            )
          );
          let content = newArr[i].split(');')[1];
          let year = content.split(';')[0];
          let sem = content.split(';')[1];
          let classgroup = content.split(';')[2];
          let obj = {
            id: id,
            published: published,
            year: year,
            sem: sem,
            classgroup: classgroup,
            type: 'pdf',
          };
          newObj.push(obj);
        }
      }
      dispatch(raporsemkolabGet(newObj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const downloadRaporsemkolab = (auth, history, id, published) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadRaporsemkolab' });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('raporsemkolabId', id);
    formData.append('published', published);
    try {
      const fetchData = await fetch(SITE_ADDRESS + 'rapor-sem-kolab/download', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const blob = await fetchData.blob();
      let name = id.split('/');
      saveAs(blob, `rapor_sem_kolab_${name[0]}_${name[1]}.pdf`);
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getLinkDownloadRaporsemkolab = (auth, history, id, published) => {
  return async (dispatch) => {
    dispatch({
      type: ASYNC_ACTION_START,
      payload: 'getLinkDownloadRaporsemkolab',
    });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('raporsemkolabId', id);
    formData.append('published', published);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'rapor-sem-kolab/link-download',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const linkRapor = response.url;
      dispatch(asyncActionFinish());
      return linkRapor;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
