import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getRapormidDetail } from './redux/reduxApi';
import parse from 'html-react-parser';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state, ownProps) {
  const rapormidId = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'rapor-mid'
    )[0];
  }
  let rapormidDetail = {};
  if (state.srapormid && state.srapormid.length > 0) {
    rapormidDetail = state.srapormid.filter(
      (item) => item.id === rapormidId
    )[0];
  }
  return {
    auth: state.auth,
    rapormidId: rapormidId,
    rapormidDetail: rapormidDetail,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getRapormidDetail,
};

function Index(props) {
  let history = useHistory();
  const {
    swipeToggleStatus,
    getRapormidDetail,
    auth,
    rapormidId,
    rapormidDetail,
  } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    getRapormidDetail(auth, history, rapormidId, rapormidDetail.content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table =
    rapormidDetail &&
    rapormidDetail.rapormidContent &&
    parse(
      rapormidDetail.rapormidContent
        .split('=box')
        .join('=boxr')
        .split('="box')
        .join('=boxr')
        .split('<table')
        .join('<table class="is-bordered"')
        .split('30%">')
        .join('50%">')
        .split('70%">')
        .join('50%">')
        .replace(
          /(<(pre|script|style|textarea)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/g,
          '$1$3'
        )
    );

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  return (
    <>
      <PullDownWrapper auth={auth}>
      <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div id='fadein' className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/rapor-mid')}
                        >
                          <i className='fas fa-file-invoice icon' />
                          Rapor Mid
                        </span>
                        <i className='fas fa-angle-right icon' />{' '}
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/rapor-mid/' + rapormidId)}
                        >
                          Detail
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content'>
                    <div
                      id='rapor-style'
                      className='content overflow-auto ml-4 mr-4'
                    >
                      {table}
                    </div>
                    {table && (
                      <div className='ml-4'>
                        <span className='tag is-dark is-italic'>
                          Note: Preview Rapor Mid
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
