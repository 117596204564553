import { JADWAL_GET } from './reduxConstant';

export const jadwalGet = (jadwal) => {
  return {
    type: JADWAL_GET,
    payload: {
      jadwal,
    },
  };
};
