import { AGENDA_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = [];

const agendaGet = (state, payload) => {
  return [ ...payload.agenda ];
};

export default createReducer(initialState, {
  [AGENDA_GET]: agendaGet,
});
