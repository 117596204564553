import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import {
  getProgressreport,
  getProgressreportPdf,
  getLinkDownloadProgressreport,
  downloadProgressreport,
} from './redux/reduxApi';
import LoadingButton from '../../../main/LoadingButton';
import { sortArrayObject } from '../../../common/helpers/objectHelpers';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'progressreport'
    )[0];
  }
  return {
    auth: state.auth,
    sprogressreport: state.sprogressreport,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getProgressreport,
  getProgressreportPdf,
  getLinkDownloadProgressreport,
  downloadProgressreport,
};

function Index(props) {
  let history = useHistory();
  const [dataLocal, setDataLocal] = useState('');
  const {
    swipeToggleStatus,
    auth,
    loading,
    sprogressreport,
    getProgressreport,
    getProgressreportPdf,
    getLinkDownloadProgressreport,
    downloadProgressreport,
  } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    let data = localStorage.getItem('unamePass');
    if (data) {
      setDataLocal(data);
    }
  }, []);

  useEffect(() => {
    getProgressreport(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProgressreportPdf(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  const handleDownload = useCallback(
    async (id, published, period) => {
      if (dataLocal) {
        let link = await getLinkDownloadProgressreport(
          auth,
          history,
          id,
          published
        );
        window.ReactNativeWebView.postMessage('url:' + link);
      } else {
        await downloadProgressreport(auth, history, id, published);
      }
    },
    [
      auth,
      history,
      downloadProgressreport,
      getLinkDownloadProgressreport,
      dataLocal,
    ]
  );

  let progressreport = sprogressreport?.progressreport;
  let progressreportPdf = sprogressreport?.progressreportPdf;

  const joinItems = [...progressreport, ...progressreportPdf];
  const sortJoinItems = sortArrayObject(joinItems, 'no', 'desc');

  const ListItems =
    !loading && sortJoinItems && sortJoinItems.length > 0 ? (
      sortJoinItems.map((item, key) => (
        <tr key={key}>
          <td>{key + 1}</td>
          <td className='has-text-left'>{item.classgroup}</td>
          <td className='is-hidden-mobile'>{item.year}</td>
          <td className='is-hidden-mobile'>{item.sem}</td>
          <td>
            <span className='is-hidden-tablet'>{item.year} / </span>
            {item.sem === 'Semester 2'
              ? 'Quarter ' + (2 + parseInt(item.quarter.split(' ')[1]))
              : item.quarter}
          </td>
          <td>
            {item.type === 'pdf' && (
              <button
                disabled={loading}
                onClick={() => handleDownload(item.id, item.published, 'mid')}
                className='button is-info is-small is-rounded is-outlined'
              >
                Download
              </button>
            )}
            {item.type !== 'pdf' && (
              <button
                onClick={() => handleLink(`/progress-report/${item.id}`)}
                className='button is-info is-small is-rounded is-outlined'
              >
                Lihat
              </button>
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={5}>
          <p id='fadein2' className='has-text-left mt-3 is-italic'>
            Tidak ada progress report.
          </p>
        </td>
      </tr>
    );

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/progress-report'}>
        <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/progress-report')}
                        >
                          <i className='fas fa-file-invoice icon' />
                          Progress Report
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content' style={{ paddingLeft: -20 }}>
                    <div
                      className='content overflow-auto'
                      style={{ marginTop: -16 }}
                    >
                      <table id='fadein' className='table has-text-centered'>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Kelas</th>
                            <th className='is-hidden-mobile'>Tahun Ajaran</th>
                            <th className='is-hidden-mobile'>Semester</th>
                            <th>
                              <span className='is-hidden-tablet'>Tahun / </span>{' '}
                              Quarter
                            </th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td>
                                <LoadingButton />
                              </td>
                            </tr>
                          ) : (
                            ListItems
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
