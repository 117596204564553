import { PROGRESSREPORT_GET, PROGRESSREPORTDETAIL_GET, PROGRESSREPORTPDF_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = { progressreport: [], progressreportPdf: [] };

const progressreportGet = (state, payload) => {
  return { ...state, progressreport: [...payload.progressreport] };
};

const progressreportDetailGet = (state, payload) => {
  let progressreportDetail = {...state.progressreport.filter(
    (item) => item.id === payload.progressreportDetail.id
  )[0], ...payload.progressreportDetail};
  return {
    ...state,
    progressreport: [
      ...state.progressreport.filter(
        (item) => item.id !== payload.progressreportDetail.id
      ),
      progressreportDetail,
    ],
  };
};

const progressreportPdfGet = (state, payload) => {
  return { ...state, progressreportPdf: [...payload.progressreportPdf] };
};

export default createReducer(initialState, {
  [PROGRESSREPORT_GET]: progressreportGet,
  [PROGRESSREPORTDETAIL_GET]: progressreportDetailGet,
  [PROGRESSREPORTPDF_GET]: progressreportPdfGet,
});
