import { SLIDESHOW_GET } from './reduxConstant';

export const slideshowGet = (slideshow) => {
  return {
    type: SLIDESHOW_GET,
    payload: {
      slideshow,
    },
  };
};
