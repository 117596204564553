import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../modals/redux/modalActions';
import MenuDetail from './MenuDetail';

const actions = {
  closeModal,
};

const MenuModal = (props) => {
  const { closeModal, auth, sprofil, logout, menus, pathname, url } = props;
  const remainder = 3 - (menus.length % 3);
  let arrayDiv = [];
  if (remainder > 0) {
    arrayDiv = new Array(remainder).fill(1);
  }
  return (
    <div id='menu-popup' className='modal is-active is-hidden-tablet animate pop'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card content' style={{ height: 'calc(100% - 120px)' }}>
        <div className='box c-box-menu-modal ml-1 mr-1'>
          <div className='c-menu-detail'>
            <div className='is-size-4 c-title-menu-modal has-text-weight-medium has-text-white'>
              <i className='fas fa-th icon is-size-5 mr-1' />
              Menu
            </div>
            <hr className='mt-2 mb-2' />
            <div className='c-box-menu-detail'>
              {menus &&
                menus.map((i) => (
                  <MenuDetail
                    key={i.id}
                    sprofil={sprofil}
                    pathname={pathname}
                    m={i}
                    c={auth.authorities.detail}
                    url={url}
                    closeModal={closeModal}
                  />
                ))}
              <div
                onClick={() => logout('logoutNav')}
                className='has-text-white c-menu-item'
              >
                <div>
                  <i className='fas fa-sign-out-alt icon is-size-4 mb-2' />
                </div>
                <p className='is-size-7 has-text-centered c-height-25'>
                  Logout
                </p>
              </div>
              {remainder > 0 &&
                arrayDiv.map((i, key) => (
                  <div key={key} className='has-text-white c-menu-item'></div>
                ))}
            </div>
          </div>
          <div
            onClick={closeModal}
            className='has-text-white c-close-menu-modal'
          >
            <i className='fas fa-times-circle icon is-size-4' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, actions)(MenuModal);
