import React from 'react';
import { useHistory } from 'react-router-dom';

export default function MenuDetail(props) {
  const { m, url, closeModal } = props;
  const history = useHistory();

  function handleLink(value) {
    if (url === value) {
      return;
    }
    closeModal();
    history.push(value);
  }

  return (
    <div
      onClick={() => handleLink(m.url)}
      className={`has-text-white c-menu-item${
        url === m.url ? ' no-pointer c-active-menu' : ''
      }`}
    >
      <div>
        <i className={`fas fa-${m.icon} icon is-size-4 mb-2`} />
      </div>
      <p className='is-size-7 has-text-centered c-height-25'>{m.title}</p>
    </div>
  );
}
