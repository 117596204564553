import { PESAN_GET, STATUS_UPDATE } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = [];

const pesanGet = (state, payload) => {
  return [ ...payload.pesan ];
};

const statusUpdate = (state, payload) => {
  return [...state.filter((pesan) => pesan.messageId + '' !== payload.pesan.messageId), payload.pesan];
};

export default createReducer(initialState, {
  [PESAN_GET]: pesanGet,
  [STATUS_UPDATE]: statusUpdate,
});
