import { PRESENSI_GET } from './reduxConstant';

export const presensiGet = (presensi) => {
  return {
    type: PRESENSI_GET,
    payload: {
      presensi,
    },
  };
};
