import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { kalenderGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import format from 'date-fns/format';

const MONTHS = {
  'JAN': 0,
  'Feb': 1,
  'Mar': 2,
  'Apr': 3,
  'May': 4,
  'Jun': 5,
  'Jul': 6,
  'Aug': 7,
  'Sep': 8,
  'Oct': 9,
  'Nov': 10,
  'Dec': 11,
}

export const getKalender = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getKalender' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'kalender-akademik/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const kalender = response.kalender;
      const newArr = kalender.split('content :').filter((e) => /\S/.test(e));
      let data = [];
      let obj = {
        name: '',
        start: '',
        end: '',
        options: [],
        allEvents: [],
      };
      if (newArr[0].includes('<br/>')) {
        data = newArr[0].split('<br/>').filter((e) => /\S/.test(e));
        if (data.length === 1) {
          return;
        }
        obj.name = data && data[0].split(';')[1];
        obj.start = data && data[1].split(';')[1];
        obj.end = data && data[2].split(';')[1];
        obj.options =
          data &&
          data[4]
            .split(';')[1]
            .split('/')
            .filter((e) => /\S/.test(e));
        let dataAllEvents = data && data.slice(5, data.length);
        let newObjAllEvents = [];
        for (let i = 0; i < dataAllEvents.length; i++) {
          let newArr = dataAllEvents[i].split(';');
          let splitEvent = newArr[2].split(' ');
          let dateEvent = splitEvent[0];
          let monthEvent = MONTHS[splitEvent[1]];
          let yearEvent = splitEvent[2];
          let newObj = {
            id: newArr[0],
            color: newArr[3] === 'Event Sekolah' ? '#1ccb9e' : '#fd3153',
            date: newArr[2],
            from: format(new Date(parseInt(yearEvent), parseInt(monthEvent), parseInt(dateEvent), 14), "yyyy-MM-dd'T'HH:mm"),
            to: format(new Date(parseInt(yearEvent), parseInt(monthEvent), parseInt(dateEvent), 15), "yyyy-MM-dd'T'HH:mm"),
            title: newArr[1],
            type: newArr[3],

          }
          newObjAllEvents.push(newObj);
        }
        obj.allEvents = newObjAllEvents;
      }
      dispatch(kalenderGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
