import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { getAccountsDetailString } from './redux/reduxApi';
import parse from 'html-react-parser';
// import LoadingButton from '../../../main/LoadingButton';

function mapState(state) {
  return {
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getAccountsDetailString,
};

function View(props) {
  let history = useHistory();
  const { getAccountsDetailString, auth, saccounts, loading } = props;

  useEffect(() => {
    getAccountsDetailString(auth, history, auth.userId);
  }, [auth, getAccountsDetailString, history]);

  const tableRaw =
    saccounts &&
    saccounts.accountsContent &&
    saccounts.accountsContent.split('<body>')[1].split('</body>')[0];

  const table = parse(
    tableRaw.replace(
      /(<(pre|script|style|textarea)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/g,
      '$1$3'
    )
  );

  return (
    <div id='account-statement'>
      {/* {loading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 80,
          }}
        >
          <LoadingButton />
        </div>
      )} */}
      {!loading && table}
      <div>
        <a
          className='ml-2'
          target='_blank'
          rel='noreferrer'
          href={`http://202.43.173.27:3380/makariosadm/tm/kontak/tm_ctc_printar4ppses.asp?kdctc=${auth.userId}&sessionid=${auth.sessionId}`}
        >
          Link Account Statement
        </a>
      </div>
    </div>
  );
}

export default connect(mapState, actions)(View);
