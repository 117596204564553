import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getPesan, updateStatus } from './redux/reduxApi';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';
import { useUnamePass } from '../../../common/helpers/othersHelpers';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'pesan-pribadi'
    )[0];
  }
  return {
    auth: state.auth,
    spesan: state.spesan.sort((a, b) =>
      parseInt(a.messageId) < parseInt(b.messageId)
        ? 1
        : parseInt(b.messageId) < parseInt(a.messageId)
        ? -1
        : 0
    ),
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getPesan,
  updateStatus,
};

function Index(props) {
  let uPass = useUnamePass();
  let history = useHistory();
  const { swipeToggleStatus, getPesan, auth, spesan, updateStatus } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    getPesan(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  const handleUpdateStatus = async (val) => {
    if (val.status + '' !== '0') {
      return;
    }
    let result = await updateStatus(auth, history, val);
    if (result) {
      let unreadPesan =
        spesan &&
        spesan.filter((item) => {
          return item.status + '' === '0';
        });
      if (uPass && uPass.length > 0 && unreadPesan) {
        if (unreadPesan.length < 1) {
          document.querySelector('#bottomNavNotif').style.display = 'none';
        }
      }
    }
  };

  const ListItems =
    spesan && spesan.length > 0 ? (
      spesan.map((item, key) => (
        <div
          onClick={() => handleUpdateStatus(item)}
          className={`card mb-3 no-shadow ${
            item.status + '' !== 0 + ''
              ? 'custom-light-grey bubblen'
              : 'has-background-primary-light hand-pointer2 bubblea'
          }`}
          style={{ borderRadius: 10 }}
          key={key}
        >
          <header className='card-header' style={{ boxShadow: 'none' }}>
            <p
              className={`card-header-title has-text-${
                item.status + '' !== 0 + '' ? 'grey-dark' : 'info'
              }`}
            >
              {item.title}
            </p>
          </header>
          <div className='card-content' style={{ marginTop: -24 }}>
            <div className='content overflow-auto ml-3 mr-3'>
              {item.messageContent} <br />
              <span className='has-text-grey-light is-size-7 is-family-sans-serif'>
                {item.date}
              </span>
            </div>
          </div>
          <span
            className={`is-overlay mt-3 mr-3 has-text-right has-text-${
              item.status + '' !== 0 + '' ? 'grey-dark' : 'info'
            }`}
          >
            <i
              className={`fas fa-envelope${
                item.status + '' !== '0' ? '-open' : ''
              }`}
            />
          </span>
        </div>
      ))
    ) : (
      <p id='fadein2' className='ml-2 is-italic mb-3'>
        Tidak ada pesan
      </p>
    );

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/pesan-pribadi'}>
      <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/pesan-pribadi')}
                        >
                          <i className='fas fa-envelope icon' /> Pesan Pribadi
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content' style={{ paddingLeft: -20 }}>
                    <div id='fadein' className='content'>
                      <div
                        className='custom-agenda-flex ml-2 mr-2'
                        style={{ marginBottom: -20, marginTop: -14 }}
                      >
                        {ListItems}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
