import { authLogout } from '../../menu/login/redux/authAction';

export const checkRes = (res, fetchData, dispatch, status, history) => {
  if (res.status === 'duplicate') {
    history.push('/register-2');
  }
  if (res.status === 'unauthorized') {
    dispatch(authLogout());
    history.push('/');
  }
  if (res.message === 'jwt expired' || res.message === 'jwt malformed') {
    let unamePass = localStorage.getItem('unamePass');
    if (unamePass && unamePass.length > 0) {
      return window.open(window.location.href + `authDashboard`, '_self');
    } else {
      return window.open(window.location.href, '_self');
    }
  }
  if (status === 200 && fetchData.status !== 200) {
    const error = new Error(res.message);
    return error;
  }
  if (status === 201 && fetchData.status !== 201) {
    const error = new Error(res.message);
    return error;
  }
};

export const checkErr = (error) => {
  console.log(error);
};
