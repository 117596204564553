import { authLogin, authLogout, authUpdate } from './authAction';
import { menusGet } from '../../navbar/Sidemenu/redux/menusAction';
import {
  asyncActionFinish,
  asyncActionError,
} from '../../../common/async/asyncActions';
import { ASYNC_ACTION_START } from '../../../common/async/asyncConstant';
import { toastr } from 'react-redux-toastr';
import { closeModal } from '../../modals/redux/modalActions';
import { SITE_ADDRESS } from '../../../common/util/siteConfig';
import { authUpdating } from '../../../common/util/checkAuth';
import { profilUpdate } from '../../students-menu/profil/redux/reduxAction';
import { checkRes } from '../../../common/helpers/checkRes';
import { fetchWithTimeOut } from '../../../common/helpers/othersHelpers';
import {
  Menus,
  PrJuSeMenus,
  KGMenus,
} from '../../../common/util/menus';

const jwtDecode = require('jwt-decode');

export const loginAuth = (creds) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'loginAuth' });
    try {
      const formData = new FormData();
      const username = creds.username.trim();
      const password = creds.password.trim();
      formData.append('username', username);
      formData.append('password', password);
      let authUrl = 'auth/';
      let fetchData = await fetchWithTimeOut(
        SITE_ADDRESS + authUrl,
        {
          method: 'POST',
          body: formData,
        },
        15
      );
      const response = await fetchData.json();
      if (fetchData.status !== 200) {
        const error = new Error(response.message);
        throw error;
      }
      const user = response.user;
      const sessionId = response.sessionId;
      const deviceId = response.deviceId;
      const FullMenus = [...Menus, ...PrJuSeMenus, ...KGMenus];
      const urlPhoto = response.urlPhoto;
      const authUpdated = authUpdating(user.roles, FullMenus, user.userId);
      const expiresIn = jwtDecode(user.token);
      const setUser = {
        isAuth: true,
        token: user.token,
        sessionId: sessionId,
        deviceId: deviceId,
        vusn: creds.username,
        userId: user.userId,
        authorities: authUpdated,
        mainPhoto: urlPhoto,
        username: user.username,
        expiresIn: expiresIn.exp * 1000,
      };
      dispatch(menusGet(FullMenus));
      dispatch(authLogin(setUser));
      dispatch(profilUpdate({ urlPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (err) {
      console.log(err);
      toastr.warning('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};

export const loginAuthNew = (creds) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'loginAuthNew' });
    try {
      const formData = new FormData();
      const username = creds.username.trim();
      const password = creds.password.trim();
      formData.append('username', username);
      formData.append('password', password);
      let authUrl = 'auth/new-login';
      let fetchData = await fetchWithTimeOut(
        SITE_ADDRESS + authUrl,
        {
          method: 'POST',
          body: formData,
        },
        1
      );
      const response = await fetchData.json();
      if (fetchData.status !== 200) {
        const error = new Error(response.message);
        throw error;
      }
      const user = response.user;
      const sessionId = response.sessionId;
      const deviceId = response.deviceId;
      const FullMenus = [...Menus, ...PrJuSeMenus, ...KGMenus];
      const urlPhoto = response.urlPhoto;
      const authUpdated = authUpdating(user.role, FullMenus, user.userId);
      const expiresIn = jwtDecode(user.token);
      const setUser = {
        isAuth: true,
        token: user.token,
        sessionId: sessionId,
        deviceId: deviceId,
        vusn: creds.username,
        userId: user.userId,
        username: user.siswa,
        nis: user.nis,
        active_year: user.active_year,
        active_sem: user.active_sem,
        active_kelas: user.active_kelas,
        active_tingkat: user.active_tingkat,
        active_jenjang: user.active_jenjang,
        authorities: authUpdated,
        mainPhoto: urlPhoto,
        expiresIn: expiresIn.exp * 1000,
      };
      dispatch(menusGet(FullMenus));
      dispatch(authLogin(setUser));
      dispatch(profilUpdate({ urlPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (err) {
      console.log(err);
      toastr.warning('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};

export const authDashboard = (history, creds) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'authDashboard' });
    try {
      const formData = new FormData();
      formData.append('vusn', creds.vusn);
      formData.append('userId', creds.userId);
      formData.append('deviceId', creds.deviceId);

      let fetchData = await fetch(SITE_ADDRESS + 'auth/authdashboard', {
        method: 'POST',
        body: formData,
      });
      const response = await fetchData.json();
      if (fetchData.status !== 200) {
        const error = new Error(response.message);
        throw error;
      }
      const user = response.user;
      const sessionId = response.sessionId;
      const deviceId = response.deviceId;
      const FullMenus = [...Menus, ...PrJuSeMenus, ...KGMenus];
      const urlPhoto = response.urlPhoto;
      const authUpdated = authUpdating(user.roles, FullMenus, user.userId);
      const expiresIn = jwtDecode(user.token);
      const setUser = {
        isAuth: true,
        token: user.token,
        sessionId: sessionId,
        deviceId: deviceId,
        vusn: creds.vusn,
        userId: user.userId,
        authorities: authUpdated,
        mainPhoto: urlPhoto,
        username: user.username,
        expiresIn: expiresIn.exp * 1000,
      };
      dispatch(menusGet(FullMenus));
      dispatch(authLogin(setUser));
      dispatch(profilUpdate({ urlPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
      history.push('/dashboard');
    } catch (err) {
      console.log(err);
      toastr.warning('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};
// Change Sibling Student Profile
export const updateAuth = (values, auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'updateAuth' });
    try {
      const vusn = auth.vusn;
      const userIdnew = values.userIdnew;
      const sessionId = auth.sessionId ? auth.sessionId : '';
      const deviceId = auth.deviceId ? auth.deviceId : '';
      const formData = new FormData();
      formData.append('vusn', vusn);
      formData.append('userId', auth.userId);
      formData.append('userIdnew', userIdnew);
      formData.append('usernameNew', values.username);
      formData.append('sessionId', sessionId);
      formData.append('deviceId', deviceId);
      let fetchData = await fetch(SITE_ADDRESS + 'role/sibling-student', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const username = values.username;
      const token = response.token;
      const urlPhoto = response.urlPhoto;
      const expiresIn = jwtDecode(token);
      const setUser = {
        userId: userIdnew,
        username: username,
        token: token,
        mainPhoto: urlPhoto,
        expiresIn: expiresIn.exp * 1000,
      };
      dispatch(authUpdate(setUser));
      dispatch(profilUpdate({ urlPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  };
};
// register parents accounts
export const registerAuth = (creds) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'loginAuth' });
    try {
      const formData = new FormData();
      formData.append('username', creds.username);
      formData.append('password', creds.password);
      let fetchData = await fetch(SITE_ADDRESS + 'auth/', {
        method: 'POST',
        body: formData,
      });
      const response = await fetchData.json();
      if (fetchData.status !== 200) {
        const error = new Error(response.message);
        throw error;
      }
      const user = response.user;
      const sessionId = response.sessionId;
      const deviceId = response.deviceId;
      const FullMenus = [...Menus, ...PrJuSeMenus, ...KGMenus];
      const urlPhoto = response.urlPhoto;
      const authUpdated = authUpdating(user.roles, FullMenus, user.userId);
      const expiresIn = jwtDecode(user.token);
      const setUser = {
        isAuth: true,
        token: user.token,
        sessionId: sessionId,
        deviceId: deviceId,
        vusn: creds.username,
        userId: user.userId,
        authorities: authUpdated,
        mainPhoto: urlPhoto,
        username: user.username,
        expiresIn: expiresIn.exp * 1000,
        registerParents: true,
      };
      dispatch(menusGet(FullMenus));
      dispatch(authLogin(setUser));
      dispatch(profilUpdate({ urlPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (err) {
      console.log(err);
      toastr.warning('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};

export const registerUserRole = (values, auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'updateAuth' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const vusnreg = values.vusnreg;
    const passwordreg = values.passwordreg;
    const rolereg = values.rolereg;
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    formData.append('vusnreg', vusnreg);
    formData.append('pwdreg', passwordreg);
    formData.append('rolereg', rolereg);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'role/register', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      history.push('/register-3');
      dispatch(closeModal());
      dispatch(asyncActionFinish());
    } catch (err) {
      toastr.warning('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};

export const sessionLogout = (elementName, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: elementName });
    try {
      history.push('/');
      dispatch(authLogout());
      dispatch(closeModal());
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
      toastr.warning('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};
