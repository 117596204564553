import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import {
  allMateriGet,
  allTugasGet,
  allLatihanGet,
  allTesGet,
  allTingkatGet,
  allKelasGet,
  materiGet,
  tugasGet,
  latihanGet,
  tesGet,
  latihanAnswerGet,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import { saveAs } from 'file-saver';

export const getAllMateri = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAllMateri' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/materi/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.allMateri;
      const total = response.totalMateri;
      dispatch(allMateriGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getAllTugas = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAllTugas' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tugas/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.allTugas;
      const total = response.totalTugas;
      dispatch(allTugasGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getAllLatihan = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAllLatihan' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/latihan/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.allLatihan;
      const total = response.totalLatihan;
      dispatch(allLatihanGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getAllTes = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAllTes' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.allTes;
      const total = response.totalTes;
      dispatch(allTesGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getAllTingkat = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAllTingkat' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('tingkat', auth.active_tingkat);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tingkat/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const allTingkat = response.allTingkat;
      const allKelas = response.allKelas;
      dispatch(allTingkatGet(allTingkat));
      dispatch(allKelasGet(allKelas));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getMateri = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMateri' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/materi/' + id, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const materi = response.materi;
      dispatch(materiGet(materi));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTugas = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugas' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tugas/' + id, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tugas = response.tugas;
      dispatch(tugasGet(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getLatihan = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihan' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/latihan/' + id, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const latihan = response.latihan;
      dispatch(latihanGet(latihan));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getLatihanAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihanAnswer' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/latihan/answer/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const latihanAnswer = response.latihanAnswer;
      dispatch(latihanAnswerGet(latihanAnswer))
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTes = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTes' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/' + id, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tes = response.tes;
      dispatch(tesGet(tes));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const downloadFile = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadFile' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('filelink', file.filelink);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/materi/download-file/' + data.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(
        blob,
        file.filelink.split('/')[file.filelink.split('/').length - 1]
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
