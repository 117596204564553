import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

let dataUnamePass = localStorage.getItem('unamePass');

export default function PieofChart(props) {
  const { dataChart } = props;
  const defaultLabelStyle = {
    fontSize: '5px',
    fontFamily: 'sans-serif',
    fill: 'white',
  };
  let data =
    dataChart && dataChart.length > 0
      ? dataChart
      : [{ title: 'Tidak ada data', value: 4, color: '#e5e5e5' }];
  if (dataChart.length === 0)
    return (
      <PieChart
        animate={true}
        labelStyle={{
          ...defaultLabelStyle,
        }}
        data={data}
      />
    );

  if (dataUnamePass && dataUnamePass.includes('ios_'))
    return (
      <PieChart
        label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
        labelStyle={{
          ...defaultLabelStyle,
        }}
        data={data}
      />
    );

  return (
    <PieChart
      animate={true}
      label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
      labelStyle={{
        ...defaultLabelStyle,
      }}
      data={data}
    />
  );
}
