import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import {
  progressreportGet,
  progressreportDetailGet,
  progressreportPdfGet,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS, OLD_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import getUnixTime from 'date-fns/getUnixTime';
import { saveAs } from 'file-saver';

export const getProgressreport = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getProgressreport' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'progress-report/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const progressreport = response.progressreport;
      const newArr = progressreport
        .split('content :')
        .join('')
        .split('<br/>')
        .filter((e) => /\S/.test(e));
      let newObj = [];
      if (!progressreport.includes('Tidak ada')) {
        for (let i = 0; i < newArr.length; i++) {
          let id = newArr[i].split('kdrapot=')[1].split(';')[0];
          let content = newArr[i].split('kdrapot=')[1];
          let year = content.split(';')[1];
          let sem = content.split(';')[2];
          let quarter = content.split(';')[3];
          let classgroup = content.split(';')[4];
          let obj = {
            no: year.split('/')[1] + sem.split(' ')[1] + quarter.split(' ')[1] + 1,
            id: id,
            published: '',
            year: year,
            sem: sem,
            quarter: quarter,
            classgroup: classgroup,
          };
          newObj.push(obj);
        }
      }
      dispatch(progressreportGet(newObj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getProgressreportDetail = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getProgressreportDetail' });
    const userId = auth.userId;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('progressreportId', id);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'progress-report/view', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const progressreportContent = response.progressreportDetail;
      let filterContent;
      if (
        progressreportContent.includes(OLD_ADDRESS + 'makarios/ina/tt/rapottk/')
      ) {
        filterContent = progressreportContent
          .split(OLD_ADDRESS + 'makarios/ina/tt/rapottk/')
          .join(SITE_ADDRESS + 'images/report/');
      }
      const obj = {
        id: response.progressreportId,
        progressreportContent: filterContent
          .split('<body>')[1]
          .split('</body>')[0],
      };
      dispatch(progressreportDetailGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
// PDF
export const getProgressreportPdf = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getProgressreportPdf' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'progress-report/pdf', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const progressreportPdf = response.progressreportPdf;
      const newArr = progressreportPdf
        .split('content :')
        .join('')
        .split('<br/>')
        .filter((e) => /\S/.test(e));
      let newObj = [];
      if (!progressreportPdf.includes('Tidak ada Rapor')) {
        for (let i = 0; i < newArr.length; i++) {
          let id = newArr[i].split('IMAGEMURID/')[1].split('.pdf')[0];
          let dateTime = newArr[i].split('pdf-(')[1].split(')')[0];
          let amPM = dateTime.split(' ')[2];
          let dataDate = dateTime.split(' ')[0];
          let Day = dataDate.split('/')[1];
          let Month = dataDate.split('/')[0] - 1;
          let Year = dataDate.split('/')[2];
          let dataTime = dateTime.split(' ')[1];
          let Hour =
            amPM === 'PM'
              ? parseInt(dataTime.split(':')[0]) + 12
              : dataTime.split(':')[0];
          let Minute = dataTime.split(':')[1];
          let Second = dataTime.split(':')[2];
          // get unixTime for unique naming pdf
          let published = getUnixTime(
            new Date(
              parseInt(Year),
              parseInt(Month),
              parseInt(Day),
              parseInt(Hour),
              parseInt(Minute),
              parseInt(Second)
            )
          );
          let content = newArr[i].split(');')[1];
          let year = content.split(';')[0];
          let sem = content.split(';')[1];
          let classgroup = content.split(';')[2];
          let quarter = content.split(';')[3];
          let obj = {
            no: year.split('/')[1] + sem.split(' ')[1] + quarter.split(' ')[1] + 2,
            id: id,
            published: published,
            year: year,
            sem: sem,
            classgroup: classgroup,
            quarter: quarter,
            type: 'pdf',
          };
          newObj.push(obj);
        }
      }
      dispatch(progressreportPdfGet(newObj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const downloadProgressreport = (auth, history, id, published) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadProgressreport' });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('progressreportPdfId', id);
    formData.append('published', published);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'progress-report/pdf-download',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      let name = id.split('/');
      saveAs(blob, `progress_report_${name[0]}_${name[1]}.pdf`);
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getLinkDownloadProgressreport = (auth, history, id, published) => {
  return async (dispatch) => {
    dispatch({
      type: ASYNC_ACTION_START,
      payload: 'getLinkDownloadProgressreport',
    });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('progressreportPdfId', id);
    formData.append('published', published);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'progress-report/pdf-link-download',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const linkRapor = response.url;
      dispatch(asyncActionFinish());
      return linkRapor;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
