import { RAPORSEM_GET, RAPORSEMDETAIL_GET } from './reduxConstant';

export const raporsemGet = (raporsem) => {
  return {
    type: RAPORSEM_GET,
    payload: {
      raporsem,
    },
  };
};

export const raporsemDetailGet = (raporsemDetail) => {
  return {
    type: RAPORSEMDETAIL_GET,
    payload: {
      raporsemDetail,
    },
  };
};
