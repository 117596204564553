import {
  asyncActionFinish,
  asyncActionError,
} from '../../../common/async/asyncActions';
import { userroleGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../common/helpers/checkRes';

export const getUserrole = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getUserrole' });
    try {
      const vusn = auth.vusn;
      const userId = auth.userId;
      const sessionId = auth.sessionId ? auth.sessionId : '';
      const deviceId = auth.deviceId ? auth.deviceId : '';
      const formData = new FormData();
      formData.append('vusn', vusn);
      formData.append('userId', userId);
      formData.append('sessionId', sessionId);
      formData.append('deviceId', deviceId);
      let fetchData = await fetch(SITE_ADDRESS + 'role/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const role = response.role;
      const splitRole = role.split('Role : ')[1].split('<br/>');
      const userRole = splitRole[0];
      const splitSibling = role.split('Grup/Sibling : ')[1].split('<br/>').filter((e) => /\S/.test(e));
      const group = splitSibling[0].split(';').filter((e) => /\S/.test(e));
      const dataGroup = []
      for (let i = 0; i < group.length; i++) {
        let data = {};
        let splitGroup = group[i].split('-userId=')
        data.userId = splitGroup[1];
        data.name = splitGroup[0];
        dataGroup.push(data);
      }
      const splitDetailRole = role.split('Detail Role :')[1].split('<br/>').filter((e) => /\S/.test(e));
      const dataDetailRole = [];
      if(splitDetailRole[0] !== 'Tidak Ada Detail Role') {
        for (let i = 0; i < splitDetailRole.length; i++) {
          let data = {};
          let splitDetail = splitDetailRole[i].split(';');
          data.vusn = splitDetail[0];
          data.linkStudent = splitDetail[1];
          data.role = splitDetail[2];
          dataDetailRole.push(data);
        }
      }
      let obj = {
        role: userRole,
        group: dataGroup,
        detail: dataDetailRole,
      };
      dispatch(userroleGet(obj));
      dispatch(asyncActionFinish());
      return obj;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
