import { USERROLE_GET } from './reduxConstant';

export const userroleGet = (val) => {
  return {
    type: USERROLE_GET,
    payload: {
      val,
    },
  };
};
