import React, { useEffect } from 'react';
import { withRouter, Redirect, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { sessionLogout } from './redux/authApi';
import { getUserrole } from '../navbar/redux/reduxApi';
import { relationWithStudent } from '../../common/helpers/optionHelpers';

function mapState(state) {
  let dataOptionRelation = [];
  if (
    state.userrole &&
    state.userrole.detail &&
    state.userrole.detail.length > 0
  ) {
    let roleDetail = state.userrole.detail;
    let filterData = relationWithStudent;
    for (let i = 0; i < roleDetail.length; i++) {
      let data = filterData.filter((item) => {
        return item.value !== roleDetail[i].role;
      });
      filterData = data;
    }
    dataOptionRelation = filterData;
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    dataOptionRelation: dataOptionRelation,
  };
}

const actions = {
  sessionLogout,
  getUserrole,
};

function Register3(props) {
  const { sessionLogout, auth, loading, getUserrole, dataOptionRelation } =
    props;
  let history = useHistory();

  useEffect(() => {
    getUserrole(auth, history);
  }, [auth, history, getUserrole]);

  const handleSessionLogout = (elementName) => {
    sessionLogout(elementName, history);
  };

  if (auth && !auth.isAuth) return <Redirect to='/register' />;
  if (auth && auth.isAuth && !auth.registerParents)
    return <Redirect to='/dashboard' />;

  return (
    <>
      <section className='hero is-fullheight' style={{ marginTop: -52 }}>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd'></div>
              <div className='column is-two-quarter-tablet is-two-quarter-desktop is-two-quarter-widescreen is-two-quarter-fullhd is-centered'>
                <div className='box'>
                  <div className='media'>
                    <div className='media-content'>
                      <div className='content'>
                        <div className='is-size-3 has-text-centered has-text-weight-semibold'>
                          Register
                        </div>
                        <div className='is-size-6 has-text-centered mb-5'>
                          Orang Tua/Wali
                        </div>
                        {dataOptionRelation.length !== 0 && (
                          <>
                            <div
                              id='fadein2'
                              className='has-text-centered mt-3 mb-3'
                            >
                              <i className='fas fa-check-circle icon is-size-1 has-text-primary' />
                            </div>
                            <div className='is-size-6 has-text-centered is-italic mt-2'>
                              User telah berhasil dibuat.
                            </div>
                            <div className='has-text-link is-size-5 has-text-centered is-italic hand-pointer mt-5'>
                              <Link
                                className='button is-link is-small is-rounded is-outlined mr-2'
                                to='/register-2'
                              >
                                <i className='fas fa-user-plus icon mr-1' />{' '}
                                User
                              </Link>
                              <div
                                className='button custom-grey is-small is-rounded is-outlined'
                                onClick={() => handleSessionLogout('logoutNav')}
                              >
                                <i className='fas fa-sign-in-alt icon mr-1' />{' '}
                                Login
                              </div>
                              <br />
                              <br />
                            </div>
                          </>
                        )}
                        {dataOptionRelation.length === 0 && !loading && (
                          <>
                            <div
                              id='fadein2'
                              className='has-text-centered mt-3 mb-3'
                            >
                              <i className='fas fa-check-circle icon is-size-1 has-text-primary' />
                            </div>
                            <div className='is-size-6 has-text-centered is-italic mt-2'>
                              Semua user orang tua wali telah dibuat.
                            </div>
                            <div className='has-text-link is-size-5 has-text-centered is-italic hand-pointer mt-3'>
                              <div
                                className='button is-link is-small is-rounded is-outlined'
                                onClick={() => handleSessionLogout('logoutNav')}
                              >
                                <i className='fas fa-sign-in-alt icon mr-1' />{' '}
                                Login
                              </div>
                              <br />
                              <br />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='column is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(connect(mapState, actions)(Register3));
