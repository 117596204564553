import { ACCOUNTS_GET, ACCOUNTSDETAIL_GET } from './reduxConstant';

export const accountsGet = (accounts) => {
  return {
    type: ACCOUNTS_GET,
    payload: {
      accounts,
    },
  };
};

export const accountsDetailGet = (accounts) => {
  return {
    type: ACCOUNTSDETAIL_GET,
    payload: {
      accounts,
    },
  };
};
