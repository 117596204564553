import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getLatihan, getLatihanAnswer } from './redux/reduxApi';
import { openModal } from '../../modals/redux/modalActions';
import ViewLatihanForm from './ViewLatihanForm';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state, ownProps) {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'pembelajaran'
    )[0];
  }
  let latihan = {};
  if (
    state.pembelajaran &&
    state.pembelajaran.latihan &&
    state.pembelajaran.latihan.length > 0
  ) {
    latihan = state.pembelajaran.latihan.filter(
      (item) => item.id + '' === id + ''
    )[0];
  }
  let latihanAnswer = {};
  if (
    state.pembelajaran &&
    state.pembelajaran.latihanAnswer &&
    state.pembelajaran.latihanAnswer.length > 0
  ) {
    latihanAnswer = state.pembelajaran.latihanAnswer.filter(
      (item) => item.id + '' === id + ''
    )[0];
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    scope: scope,
    id: id,
    latihan: latihan,
    latihanAnswer: latihanAnswer,
  };
}

const actions = {
  swipeToggleStatus,
  getLatihan,
  getLatihanAnswer,
  openModal,
};

function ViewLatihan(props) {
  let history = useHistory();
  const {
    openModal,
    swipeToggleStatus,
    getLatihan,
    getLatihanAnswer,
    auth,
    id,
    latihan,
    latihanAnswer,
  } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    getLatihan(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (values) => {};

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  return (
    <>
      <PullDownWrapper auth={auth}>
      <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/pembelajaran')}
                        >
                          <i className='fas fa-book icon' /> Pembelajaran
                        </span>
                        <i className='fas fa-angle-right icon' />
                        <span
                          className='hand-pointer has-text-white is-capitalized'
                          onClick={() =>
                            handleLink('/pembelajaran/latihan/' + id)
                          }
                        >
                          Latihan
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content ml-2 mr-2'>
                    <ViewLatihanForm
                      auth={auth}
                      history={history}
                      id={id}
                      initialValues={latihan}
                      latihanAnswer={latihanAnswer}
                      onFormSubmit={onFormSubmit}
                      getLatihanAnswer={getLatihanAnswer}
                      openModal={openModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(ViewLatihan);
