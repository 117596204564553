import { HASILTESTPSIKOLOGISMID_GET, HASILTESTPSIKOLOGISSEM_GET  } from './reduxConstant';

export const hasiltestpsikologismidGet = (hasiltestpsikologismid) => {
  return {
    type: HASILTESTPSIKOLOGISMID_GET,
    payload: {
      hasiltestpsikologismid,
    },
  };
};

export const hasiltestpsikologissemGet = (hasiltestpsikologissem) => {
  return {
    type: HASILTESTPSIKOLOGISSEM_GET,
    payload: {
      hasiltestpsikologissem,
    },
  };
};
