import { HASILTESTPSIKOLOGISMID_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = [];

const hasiltestpsikologismidGet = (state, payload) => {
  return [...payload.hasiltestpsikologismid];
};

export default createReducer(initialState, {
  [HASILTESTPSIKOLOGISMID_GET]: hasiltestpsikologismidGet,
});
