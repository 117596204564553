import { JADWAL_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = [];

const jadwalGet = (state, payload) => {
  return [ ...payload.jadwal ];
};

export default createReducer(initialState, {
  [JADWAL_GET]: jadwalGet,
});
