import React from 'react';

const RadioInput = ({ input, width, type, label, size, checked }) => {
  return (
    <div className={`field${size ? size : ''}`}>
      <div className='control'>
        <label className={`radio custom-check${size ? size : ''}`}>
          <input {...input} type={type} checked={checked} /> <span className={`${size ? size : ''}`}>{label}</span>
        </label>
      </div>
    </div>
  );
};

export default RadioInput;
