export const Menus = [
  {
    id: 'dashboard',
    icon: 'th-large',
    title: 'Dashboard',
    alias: 'dashboard',
    subm: [],
    url: '/dashboard',
    order: '001',
  },
  {
    id: 'profil',
    icon: 'user',
    title: 'Profil',
    alias: 'profil',
    url: '/profil',
    subm: [],
    order: '002',
  },
  {
    id: 'absensi',
    icon: 'tasks',
    title: 'Absensi',
    alias: 'absensi',
    subm: [],
    url: '/absensi',
    order: '003',
  },
  {
    id: 'jadwal',
    icon: 'clock',
    title: 'Jadwal',
    alias: 'jadwal',
    subm: [],
    url: '/jadwal',
    order: '004',
  },
  {
    id: 'hasil-test-psikologis',
    icon: 'file-powerpoint',
    title: 'Hasil Test Psikologis',
    alias: 'hasil-test-psikologis',
    subm: [],
    url: '/hasil-test-psikologis',
    order: '208',
  },
  {
    id: 'accounts',
    icon: 'file-invoice-dollar',
    title: 'Account Statement',
    alias: 'account-statement',
    subm: [],
    url: '/account-statement',
    order: '409',
  },
  {
    id: 'agenda',
    icon: 'bullhorn',
    title: 'Agenda',
    alias: 'agenda',
    subm: [],
    url: '/agenda',
    order: '410',
  },
  {
    id: 'kalender-akademik',
    icon: 'calendar-alt',
    title: 'Kalender Akademik',
    alias: 'kalender-akademik',
    subm: [],
    url: '/kalender-akademik',
    order: '411',
  },
  {
    id: 'pesan-pribadi',
    icon: 'envelope',
    title: 'Pesan Pribadi',
    alias: 'pesan-pribadi',
    subm: [],
    url: '/pesan-pribadi',
    order: '412',
  },
  {
    id: 'pengaturan-akun',
    icon: 'sliders-h',
    title: 'Pengaturan Akun',
    alias: 'pengaturan-akun',
    subm: [],
    url: '/pengaturan-akun',
    order: '413',
  },
];

export const KGMenus = [
  {
    id: 'progressreport',
    icon: 'chart-line',
    title: 'Progress Report',
    alias: 'progress-report',
    subm: [],
    url: '/progress-report',
    order: '104',
  },
  {
    id: 'raporsemtk',
    icon: 'id-card-alt',
    title: 'Rapor Semester',
    alias: 'rapor-sem-tk',
    subm: [],
    url: '/rapor-sem-tk',
    order: '105',
  },
];

export const PrJuSeMenus = [
  {
    id: 'rapor-mid',
    icon: 'file-invoice',
    title: 'Rapor Mid',
    alias: 'rapor-mid',
    subm: [],
    url: '/rapor-mid',
    order: '206',
  },
  {
    id: 'erapor',
    icon: 'id-card',
    title: 'Rapor Semester',
    alias: 'rapor-sem',
    subm: [],
    url: '/rapor-sem',
    order: '207',
  },
];
