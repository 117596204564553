import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { presensiGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';

export const getPresensi = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPresensi' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'presensi/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const presensi = response.presensi;
      const newArr = presensi.split('content :').join('').split('<br/>').filter((e) => /\S/.test(e));
      let obj = {};
      obj.total = newArr[0].split(';')[1];
      obj.sakit = newArr[1].split(';')[1];
      obj.izin = newArr[2].split(';')[1];
      obj.alpha = newArr[3].split(';')[1];
      obj.masuk = newArr[4].split(';')[1].split('(').join(' (');
      dispatch(presensiGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
