import { authLogout } from '../../menu/login/redux/authAction';

export const checkAuthScope = (openModal, aS, pathname, history) => {
  return async (dispatch) => {
    const url = pathname.split('/');
    if (url.length > 0) {
      const menu = aS.m.filter((menu) => {
        return menu.id === url[1];
      })[0];
      if (menu && menu.v !== true) {
        history.push('/');
        dispatch(openModal('NotAuthorizedModal'));
      }
      if (menu && menu.v === true && url.length > 2) {
        const subm = aS.subm.filter((subm) => {
          return subm.id === url[2];
        })[0];
        if (subm && subm.v !== true) {
          history.push('/');
          dispatch(openModal('NotAuthorizedModal'));
        }
        if (subm && subm.c !== true && url[3] === 'create') {
          history.push('/');
          dispatch(openModal('NotAuthorizedModal'));
        }
        if (subm && subm.u !== true && url[3] === 'edit') {
          history.push('/');
          dispatch(openModal('NotAuthorizedModal'));
        }
      }
    }
  };
};

export const checkSession = (history) => {
  return (dispatch) => {
    dispatch(authLogout());
    history.push('/');
  };
};

export const authUpdating = (usrRoles, menus, roles) => {
  let authorities;
  let userRoles = [];
  let menu = [];
  let subm = [];
  if (
    usrRoles === 'student' ||
    usrRoles === 'Siswa' ||
    usrRoles === 'Ayah' ||
    usrRoles === 'Ibu' ||
    usrRoles === 'Wali'
  ) {
    userRoles = ['student'];
    let newObj0 = {};
    let newObj1 = {};
    let newObj2 = {};
    menus.forEach((m) => {
      if (m.subm.length > 0) {
        newObj0 = {
          id: m.id,
          v: true,
          c: false,
          u: false,
          d: false,
          r: false,
          h: false,
          e: false,
          i: false,
        };
        menu.push(newObj0);
        m.subm.forEach((item) => {
          newObj1 = {
            id: item.id,
            v: true,
            c: true,
            u: true,
            d: true,
            r: true,
            h: true,
            e: true,
            i: true,
          };
          subm.push(newObj1);
        });
      } else {
        newObj2 = {
          id: m.id,
          v: true,
          c: true,
          u: true,
          d: true,
          r: true,
          h: true,
          e: true,
          i: true,
        };
        menu.push(newObj2);
      }
    });
    authorities = {
      roles: userRoles,
      detail: {
        m: menu,
        subm: subm,
      },
    };
  } else if (usrRoles === null || usrRoles.length < 1) {
    authorities = {
      roles: userRoles,
      detail: {
        m: menu,
        subm: subm,
      },
    };
  } else {
    userRoles = usrRoles.split(',');
    const details = roles;
    const setAuth = [];
    details.forEach((auth) => {
      for (let i = 0; i < userRoles.length; i++) {
        if (auth.id + '' === userRoles[i] + '') {
          setAuth[setAuth.length] = {
            detail: JSON.parse(auth.authorities),
          };
        }
      }
    });
    const detail = [];
    if (setAuth.length > 0) {
      setAuth.forEach((auth) => {
        const newObj = auth.detail;
        detail.push(newObj);
      });
    }
    const detailMenu = [];
    const detailSubm = [];
    if (detail.length > 0) {
      detail.forEach((detail) => {
        detailMenu.push(detail[0]);
        detailSubm.push(detail[1]);
      });
    }
    if (detailMenu.length > 0) {
      detailMenu.forEach((detailms, index) => {
        if (index === 0) {
          menu = detailms;
        } else {
          menu.forEach((m, j) => {
            let v = false;
            let c = false;
            let u = false;
            let d = false;
            let r = false;
            let h = false;
            let e = false;
            let i = false;
            if (m.v === true || detailms[j].v === true) {
              v = true;
            }
            if (m.c === true || detailms[j].c === true) {
              c = true;
            }
            if (m.u === true || detailms[j].u === true) {
              u = true;
            }
            if (m.d === true || detailms[j].d === true) {
              d = true;
            }
            if (m.r === true || detailms[j].r === true) {
              r = true;
            }
            if (m.h === true || detailms[j].h === true) {
              h = true;
            }
            if (m.e === true || detailms[j].e === true) {
              e = true;
            }
            if (m.i === true || detailms[j].i === true) {
              i = true;
            }
            menu[j].v = v;
            menu[j].c = c;
            menu[j].u = u;
            menu[j].d = d;
            menu[j].r = r;
            menu[j].h = h;
            menu[j].e = e;
            menu[j].i = i;
          });
        }
      });
    }
    if (detailSubm.length > 0) {
      detailSubm.forEach((detailsubms, index) => {
        if (index === 0) {
          subm = detailsubms;
        } else {
          let newObj1;
          let newObj2;
          if (detailsubms.length > subm.length) {
            newObj1 = detailsubms;
            newObj2 = subm;
          } else {
            newObj1 = subm;
            newObj2 = detailsubms;
          }
          newObj1.forEach((obj1) => {
            let checkNewObj2 = newObj2.filter((obj2) => {
              return obj2.id + '' === obj1.id + '';
            })[0];
            let id;
            let v = false;
            let c = false;
            let u = false;
            let d = false;
            let r = false;
            let h = false;
            let e = false;
            let i = false;
            if (checkNewObj2) {
              id = checkNewObj2.id;
              v = checkNewObj2.v;
              c = checkNewObj2.c;
              u = checkNewObj2.u;
              d = checkNewObj2.d;
              r = checkNewObj2.r;
              h = checkNewObj2.h;
              e = checkNewObj2.e;
              i = checkNewObj2.i;
              if (obj1.v === true) {
                v = true;
              }
              if (obj1.c === true) {
                c = true;
              }
              if (obj1.u === true) {
                u = true;
              }
              if (obj1.d === true) {
                d = true;
              }
              if (obj1.r === true) {
                r = true;
              }
              if (obj1.h === true) {
                h = true;
              }
              if (obj1.e === true) {
                e = true;
              }
              if (obj1.i === true) {
                i = true;
              }
              let newSubm = subm.filter((sub) => {
                return sub.id !== id;
              });
              subm = [
                ...newSubm,
                ...[{ id: id, v: v, c: c, u: u, d: d, r: r, h: h, e: e, i: i }],
              ];
            } else {
              id = obj1.id;
              v = obj1.v;
              c = obj1.c;
              u = obj1.u;
              d = obj1.d;
              r = obj1.r;
              h = obj1.h;
              e = obj1.e;
              i = obj1.i;
              subm = [
                ...subm,
                ...[{ id: id, v: v, c: c, u: u, d: d, r: r, h: h, e: e, i: i }],
              ];
            }
          });
        }
      });
    }
    authorities = {
      roles: userRoles,
      detail: {
        m: menu,
        subm: subm,
      },
    };
  }
  return authorities;
};
