import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import {
  getRapormidkolab,
  downloadRapormidkolab,
  getLinkDownloadRapormidkolab,
  getRaporsemkolab,
  downloadRaporsemkolab,
  getLinkDownloadRaporsemkolab,
} from './redux/reduxApi';
import LoadingButton from '../../../main/LoadingButton';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'rapor-kolab'
    )[0];
  }
  return {
    auth: state.auth,
    srapormidkolab: state.srapormidkolab,
    sraporsemkolab: state.sraporsemkolab,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getRapormidkolab,
  downloadRapormidkolab,
  getLinkDownloadRapormidkolab,
  getRaporsemkolab,
  downloadRaporsemkolab,
  getLinkDownloadRaporsemkolab,
};

function Index(props) {
  let history = useHistory();
  const [dataLocal, setDataLocal] = useState('');
  const {
    swipeToggleStatus,
    getRapormidkolab,
    downloadRapormidkolab,
    getLinkDownloadRapormidkolab,
    getRaporsemkolab,
    downloadRaporsemkolab,
    getLinkDownloadRaporsemkolab,
    loading,
    auth,
    srapormidkolab,
    sraporsemkolab,
  } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    let data = localStorage.getItem('unamePass');
    if (data) {
      setDataLocal(data);
    }
  }, []);

  useEffect(() => {
    getRapormidkolab(auth, history);
    getRaporsemkolab(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  const handleDownload = useCallback(
    async (id, published, period) => {
      if (dataLocal) {
        if (period === 'mid') {
          let link = await getLinkDownloadRapormidkolab(
            auth,
            history,
            id,
            published
          );
          window.ReactNativeWebView.postMessage('url:' + link);
        } else {
          let link = await getLinkDownloadRaporsemkolab(
            auth,
            history,
            id,
            published
          );
          window.ReactNativeWebView.postMessage('url:' + link);
        }
      } else {
        if (period === 'mid') {
          await downloadRapormidkolab(auth, history, id, published);
        } else {
          await downloadRaporsemkolab(auth, history, id, published);
        }
      }
    },
    [
      auth,
      history,
      downloadRapormidkolab,
      downloadRaporsemkolab,
      getLinkDownloadRapormidkolab,
      getLinkDownloadRaporsemkolab,
      dataLocal,
    ]
  );

  const RaporMidKolab =
    !loading && srapormidkolab && srapormidkolab.length > 0 ? (
      srapormidkolab.map((item, key) => (
        <tr key={key}>
          {item && item.classgroup && <td>{key + 1}</td>}
          <td className='has-text-left'>{item.classgroup}</td>
          <td>{item.year}
            <span className='is-hidden-tablet'>
              {' '}
              {item?.sem?.replace('Semester', 'Sem')}
            </span></td>
          <td className='is-hidden-mobile'>{item.sem}</td>
          <td>
            <button
              disabled={loading}
              onClick={() => handleDownload(item.id, item.published, 'mid')}
              className='button is-info is-small is-rounded is-outlined'
            >
              Download
            </button>
          </td>
        </tr>
      ))
    ) : (
      <></>
    );

  const RaporSemKolab =
    !loading && sraporsemkolab && sraporsemkolab.length > 0 ? (
      sraporsemkolab.map((item, key) => (
        <tr key={key}>
          {item && item.classgroup && <td>{key + 1}</td>}
          <td className='has-text-left'>{item.classgroup}</td>
          <td>{item.year}
            <span className='is-hidden-tablet'>
              {' '}
              {item?.sem?.replace('Semester', 'Sem')}
            </span></td>
          <td className='is-hidden-mobile'>{item.sem}</td>
          <td>
            <button
              disabled={loading}
              onClick={() => handleDownload(item.id, item.published, 'sem')}
              className='button is-info is-small is-rounded is-outlined'
            >
              {loading ? 'Downloading' : 'Download'}
            </button>
          </td>
        </tr>
      ))
    ) : (
      <></>
    );

  const noRaporKolab =
    !loading &&
    srapormidkolab &&
    srapormidkolab.length < 1 &&
    sraporsemkolab &&
    sraporsemkolab.length < 1 ? (
      <tr>
        <td colSpan={5}>
          <p id='fadein2' className='has-text-left mt-3 is-italic'>
            Tidak ada rapor kolaborasi.
          </p>
        </td>
      </tr>
    ) : (
      <></>
    );

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/rapor-kolab'}>
        <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/rapor-kolab')}
                        >
                          <i className='fas fa-id-card icon' /> Rapor Kolaborasi
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content' style={{ paddingLeft: -20 }}>
                    <div
                      className='content overflow-auto'
                      style={{ marginTop: -16 }}
                    >
                      <table id='fadein' className='table has-text-centered'>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Kelas</th>
                            <th>
                              <span className='is-hidden-mobile'>Tahun Ajaran</span>
                              <span className='is-hidden-tablet'> TA / Sem</span>
                            </th>
                            <th className='is-hidden-mobile'>Semester</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading && (
                            <tr>
                              <td>
                                <LoadingButton />
                              </td>
                            </tr>
                          )}
                          {!loading && (
                            <>
                              {srapormidkolab && srapormidkolab.length > 0 && (
                                <tr>
                                  <td colSpan={5}>
                                    <p className='has-text-left has-text-weight-medium'>
                                      Mid
                                    </p>
                                  </td>
                                </tr>
                              )}
                              {RaporMidKolab}
                              {sraporsemkolab && sraporsemkolab.length > 0 && (
                                <tr>
                                  <td colSpan={5}>
                                    <p className='has-text-left has-text-weight-medium'>
                                      Semester
                                    </p>
                                  </td>
                                </tr>
                              )}
                              {RaporSemKolab}
                              {noRaporKolab}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
