import {
  RAPORSEMTK_GET,
  RAPORSEMTKDETAIL_GET,
  RAPORSEMTKPDF_GET,
} from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = { raporsemtk: [], raporsemtkPdf: [] };

const raporsemtkGet = (state, payload) => {
  return { ...state, raporsemtk: [...payload.raporsemtk] };
};

const raporsemtkDetailGet = (state, payload) => {
  let raporsemtkDetail = {
    ...state.raporsemtk.filter(
      (item) => item.id === payload.raporsemtkDetail.id
    )[0],
    ...payload.raporsemtkDetail,
  };
  return {
    ...state,
    raporsemtk: [
      ...state.raporsemtk.filter((item) => item.id !== payload.raporsemtkDetail.id),
      raporsemtkDetail,
    ],
  };
};

const raporsemtkPdfGet = (state, payload) => {
  return { ...state, raporsemtkPdf: [...payload.raporsemtkPdf] };
};

export default createReducer(initialState, {
  [RAPORSEMTK_GET]: raporsemtkGet,
  [RAPORSEMTKDETAIL_GET]: raporsemtkDetailGet,
  [RAPORSEMTKPDF_GET]: raporsemtkPdfGet,
});
