import React, { useEffect, useRef } from 'react';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { combineValidators, isRequired } from 'revalidate';
import { reduxForm, Field } from 'redux-form';
import CustomTextInput from '../../common/form/CustomTextInput';
import CustomSelectInput from '../../common/form/CustomSelectInput';
import { sessionLogout, registerUserRole } from './redux/authApi';
import { getUserrole } from '../navbar/redux/reduxApi';
import { relationWithStudent } from '../../common/helpers/optionHelpers';

function mapState(state) {
  let dataOptionRelation = [];
  if (state.userrole && state.userrole.detail) {
    let roleDetail = state.userrole.detail;
    let filterData = relationWithStudent;
    for (let i = 0; i < roleDetail.length; i++) {
      let data = filterData.filter((item) => {
        return item.value !== roleDetail[i].role;
      });
      filterData = data;
    }
    dataOptionRelation = filterData;
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    userrole: state.userrole,
    dataOptionRelation: dataOptionRelation,
  };
}

const actions = {
  sessionLogout,
  registerUserRole,
  getUserrole,
};

const validate = combineValidators({
  vusnreg: isRequired({ message: 'Username orang tua/wali harus diisi' }),
  passwordreg: isRequired({ message: 'Password harus diisi' }),
  rolereg: isRequired({ message: 'Hubungan dengan siswa harus diisi' }),
});

function Register2(props) {
  const {
    sessionLogout,
    registerUserRole,
    handleSubmit,
    auth,
    error,
    loading,
    getUserrole,
    dataOptionRelation,
  } = props;
  let history = useHistory();
  const relationData = useRef([]);

  useEffect(() => {
    getUserrole(auth, history);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserrole]);

  const handleRegister = async (authData) => {
    let filterData = relationData.current;
    await registerUserRole(authData, auth, history);
    relationData.current = filterData.filter((item) => {
      return item.value !== authData.rolereg;
    });
  };

  const handleSessionLogout = (elementName) => {
    sessionLogout(elementName, history);
  };

  if (auth && !auth.isAuth) return <Redirect to='/register' />;
  if (auth && auth.isAuth && !auth.registerParents)
    return <Redirect to='/dashboard' />;

  return (
    <>
      <section className='hero is-fullheight' style={{ marginTop: -52 }}>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd'></div>
              <div className='column is-two-quarter-tablet is-two-quarter-desktop is-two-quarter-widescreen is-two-quarter-fullhd is-centered'>
                <div className='box'>
                  <div className='media'>
                    <div className='media-content'>
                      <div className='content'>
                        <div className='is-size-3 has-text-centered has-text-weight-semibold'>
                          Register
                        </div>
                        <div className='is-size-6 has-text-centered mb-5'>
                          Orang Tua/Wali
                        </div>
                        {dataOptionRelation.length !== 0 && (
                          <>
                            <form
                              onSubmit={handleSubmit(handleRegister)}
                              autoComplete='off'
                            >
                              <Field
                                name='rolereg'
                                type='text'
                                autoComplete='new-password'
                                component={CustomSelectInput}
                                placeholder='Hubungan dengan siswa'
                                label='hubungan dengan siswa'
                                icon='users'
                                fullwidth={true}
                                options={dataOptionRelation}
                              />
                              <Field
                                name='vusnreg'
                                type='text'
                                autoComplete='new-password'
                                component={CustomTextInput}
                                placeholder='Username Orang Tua/Wali'
                                label='Username Orang Tua/Wali'
                                icon='user'
                              />
                              <Field
                                name='passwordreg'
                                type='password'
                                autoComplete='new-password'
                                component={CustomTextInput}
                                placeholder='Password'
                                label='Password'
                                icon='lock'
                              />
                              <div
                                className='tags has-addons has-text-centered is-fullwidth'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: 16,
                                }}
                              >
                                <span className='tag is-rounded'>
                                  Langkah 1
                                </span>
                                <span className='tag is-primary is-rounded'>
                                  Langkah 2
                                </span>
                              </div>
                              <div
                                className='field has-text-centered'
                                style={{ marginTop: 10 }}
                              >
                                {error && (
                                  <p className='help is-danger'>{error}</p>
                                )}
                                <button
                                  type='submit'
                                  disabled={loading}
                                  style={{ marginRight: 10 }}
                                  className='button is-link is-small is-rounded is-outlined'
                                >
                                  {loading ? 'Registering' : 'Register'}
                                </button>
                              </div>
                            </form>
                            <div className='has-text-link is-size-7 has-text-right is-italic hand-pointer mt-4'>
                              <div
                                onClick={() => handleSessionLogout('logoutNav')}
                              >
                                <i className='fas fa-sign-in-alt icon' />
                                Kembali ke halaman Login
                              </div>
                            </div>
                          </>
                        )}
                        {dataOptionRelation.length === 0 && (
                          <>
                            <div
                              id='fadein2'
                              className='has-text-centered mt-3 mb-3'
                            >
                              <i className='fas fa-check-circle icon is-size-1 has-text-primary' />
                            </div>
                            <div className='is-size-6 has-text-centered is-italic mt-2'>
                              Semua user orang tua wali telah dibuat.
                            </div>
                            <div className='has-text-link is-size-5 has-text-centered is-italic hand-pointer mt-3'>
                              <div
                                className='button is-link is-small is-rounded is-outlined'
                                onClick={() => handleSessionLogout('logoutNav')}
                              >
                                <i className='fas fa-sign-in-alt icon mr-1' />{' '}
                                Login
                              </div>
                              <br />
                              <br />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='column is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(
  connect(
    mapState,
    actions
  )(reduxForm({ form: 'formRegister2', validate })(Register2))
);
