import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { getAccountsDetailRaw } from './redux/reduxApi';

function formatRupiah(val) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  }).format(val);
}

const actions = {
  swipeToggleStatus,
  getAccountsDetailRaw,
};

function View(props) {
  let history = useHistory();
  const [dataLocal, setDataLocal] = useState('');
  const { getAccountsDetailRaw, auth, saccounts } = props;

  useEffect(() => {
    let data = localStorage.getItem('unamePass');
    if (data) {
      setDataLocal(data);
    }
  }, []);

  useEffect(() => {
    getAccountsDetailRaw(auth, history, auth.userId);
  }, [auth, getAccountsDetailRaw, history]);

  const content = saccounts && saccounts.accountsContent;

  console.log(content);

  let totalTagihan = 0;
  let totalPembayaran = 0;
  let totalSisaTagihan = 0;
  if (content && content.ISI_DETAIL && content.ISI_DETAIL[0]) {
    for (let i = 0; i < content.ISI_DETAIL.length; i++) {
      const item = content.ISI_DETAIL[i];
      const tagihan = parseFloat(item?.TAGIHAN?.replace(/,/g, '')) ?? 0;
      const pembayaran = parseFloat(item?.PEMBAYARAN?.replace(/,/g, '')) ?? 0;
      const sisaTagihan =
        parseFloat(item?.SISA_TAGIHAN?.replace(/,/g, '')) ?? 0;
      if (tagihan) totalTagihan = totalTagihan + tagihan;
      totalPembayaran = totalPembayaran + pembayaran;
      if (sisaTagihan) totalSisaTagihan = totalSisaTagihan + sisaTagihan;
    }
  }

  function handleLink(link) {
    window.ReactNativeWebView.postMessage('openTab:' + link);
  }

  const table = !dataLocal && (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        {content?.JUDUL}
      </div>
      <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 180 }}>Nama</div>
        <div style={{ width: 10 }}>:</div>
        <div style={{ minWidth: 'max-content' }}>
          {auth.username} {content?.NAME}
        </div>
      </div>
      <div
        style={{
          height: 30,
          display: 'flex',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div style={{ width: 180 }}>Periode Transaksi</div>
        <div style={{ width: 10 }}>:</div>
        <div style={{ minWidth: 'max-content' }}>
          {content?.PERIODE_TRANSAKSI}
        </div>
      </div>
      <table className='table is-bordered is-striped is-narrow is-hoverable is-fullwidth'>
        <thead>
          <tr>
            <th className='has-text-centered'>Tanggal Transaksi</th>
            <th className='has-text-centered'>Keterangan</th>
            <th className='has-text-centered'>Tagihan</th>
            <th className='has-text-centered'>Tanggal Pembayaran</th>
            <th className='has-text-centered'>Pembayaran</th>
            <th className='has-text-centered'>Sisa Tagihan</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='has-text-centered has-text-weight-bold' colSpan={2}>
              Saldo Awal
            </td>
            <td colSpan={3}></td>
            <td className='has-text-right' colSpan={1}>
              Rp. {content?.SALDO_AWAL?.replace(/,/g, '.')}
            </td>
          </tr>
          {content &&
            content.ISI_DETAIL &&
            content.ISI_DETAIL.map((detail, key) => (
              <tr key={key}>
                <td className='has-text-centered'>
                  {detail?.TANGGAL_TRANSAKSI}
                </td>
                <td>{detail?.KETERANGAN}</td>
                <td className='has-text-right'>
                  {detail?.TAGIHAN ? 'Rp.' : ''} {detail?.TAGIHAN?.replace(/,/g, '.')}
                </td>
                <td className='has-text-centered'>
                  {detail?.TANGGAL_PEMBAYARAN}
                </td>
                <td className='has-text-right'>
                  Rp. {detail?.PEMBAYARAN.replace(/,/g, '.')}
                </td>
                <td className='has-text-right'>
                {detail?.SISA_TAGIHAN ? 'Rp.' : ''} {detail?.SISA_TAGIHAN.replace(/,/g, '.')}
                </td>
              </tr>
            ))}
          <tr>
            <td className='has-text-weight-bold has-text-centered' colSpan={2}>
              Total
            </td>
            <td className='has-text-right'>
              {totalTagihan && formatRupiah(totalTagihan)}
            </td>
            <td></td>
            <td className='has-text-right'>
              {totalPembayaran && formatRupiah(totalPembayaran)}
            </td>
            <td className='has-text-right'>
              {totalSisaTagihan && formatRupiah(totalSisaTagihan)}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ fontSize: 13, marginTop: 20 }}>
        {content?.KETERANGAN_BAWAH_1 && <div>{content.KETERANGAN_BAWAH_1}</div>}
        {content?.KETERANGAN_BAWAH_2 && <div>{content.KETERANGAN_BAWAH_2}</div>}
        {content?.KETERANGAN_BAWAH_3 && <div>{content.KETERANGAN_BAWAH_3}</div>}
        {content?.KETERANGAN_BAWAH_4 && <div>{content.KETERANGAN_BAWAH_4}</div>}
        {content?.KETERANGAN_BAWAH_5 && <div>{content.KETERANGAN_BAWAH_5}</div>}
        {content?.KETERANGAN_BAWAH_6 && <div>{content.KETERANGAN_BAWAH_6}</div>}
        {content?.KETERANGAN_BAWAH_7 && <div>{content.KETERANGAN_BAWAH_7}</div>}
      </div>
    </div>
  );
  // View used
  return (
    <div id='account-statement'>
      <div>{saccounts && saccounts.accountsContent && table}</div>
      {dataLocal && (
        <span
          className='has-text-info'
          onClick={() =>
            handleLink(
              `http://202.43.173.27:3380/makariosadm/tm/kontak/tm_ctc_printar4ppses.asp?kdctc=${auth?.userId}&deviceid=${auth?.deviceId}`
            )
          }
        >
          Link Account Statement
        </span>
      )}
      {!dataLocal && (
        <a
          className='has-text-info'
          target='_blank'
          rel='noreferrer'
          href={`http://202.43.173.27:3380/makariosadm/tm/kontak/tm_ctc_printar4ppses.asp?kdctc=${auth.userId}&sessionid=${auth.sessionId}`}
        >
          Link Account Statement
        </a>
      )}
    </div>
  );
}

export default connect(null, actions)(View);
