export const ALL_MATERI_GET = 'ALL_MATERI_GET';
export const ALL_TUGAS_GET = 'ALL_TUGAS_GET';
export const ALL_LATIHAN_GET = 'ALL_LATIHAN_GET';
export const ALL_TES_GET = 'ALL_TES_GET';
export const ALL_TINGKAT_GET = 'ALL_TINGKAT_GET';
export const ALL_KELAS_GET = 'ALL_KELAS_GET';
export const MATERI_GET = 'MATERI_GET';
export const TUGAS_GET = 'TUGAS_GET';
export const LATIHAN_GET = 'LATIHAN_GET';
export const TES_GET = 'TES_GET';
export const LATIHAN_ANSWER_GET = 'LATIHAN_ANSWER_GET';