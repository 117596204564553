import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { getAccountsDetailRawFetch } from './redux/reduxApi';

function formatRupiah(val) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  }).format(val);
}

const actions = {
  swipeToggleStatus,
  getAccountsDetailRawFetch,
};

function View(props) {
  let history = useHistory();
  const { getAccountsDetailRawFetch, auth, saccounts } = props;

  useEffect(() => {
    getAccountsDetailRawFetch(auth, history, auth.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const content = saccounts && saccounts.accountsContent;

  let totalTagihan = 0;
  let totalPembayaran = 0;
  let totalSisaTagihan = 0;
  if (content && content.ISI_DETAIL && content.ISI_DETAIL[0]) {
    for (let i = 0; i < content.ISI_DETAIL.length; i++) {
      const item = content.ISI_DETAIL[i];
      const tagihan = parseFloat(item?.TAGIHAN?.replace(/,/g, '')) ?? 0;
      const pembayaran = parseFloat(item?.PEMBAYARAN?.replace(/,/g, '')) ?? 0;
      const sisaTagihan =
        parseFloat(item?.SISA_TAGIHAN?.replace(/,/g, '')) ?? 0;
      totalTagihan = totalTagihan + tagihan;
      totalPembayaran = totalPembayaran + pembayaran;
      totalSisaTagihan = totalSisaTagihan + sisaTagihan;
    }
  }

  const table = (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        {content?.JUDUL}
      </div>
      <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 180 }}>Nama</div>
        <div style={{ width: 10 }}>:</div>
        <div>{auth.username} {content?.NAME}</div>
      </div>
      <div
        style={{
          height: 30,
          display: 'flex',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div style={{ width: 180 }}>Periode Transaksi</div>
        <div style={{ width: 10 }}>:</div>
        <div>{content?.PERIODE_TRANSAKSI}</div>
      </div>
      <table className='table is-bordered is-striped is-narrow is-hoverable is-fullwidth'>
        <thead>
          <tr>
            <th>Tanggal Transaksi</th>
            <th>Keterangan</th>
            <th>Tagihan</th>
            <th>Tanggal Pembayaran</th>
            <th>Pembayaran</th>
            <th className='has-text-right'>Sisa Tagihan</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='has-text-weight-bold' colSpan={5}>
              Saldo Awal
            </td>
            <td className='has-text-right'>Rp. {content?.SALDO_AWAL?.replace(/,/g, '.')}</td>
          </tr>
          {content &&
            content.ISI_DETAIL &&
            content.ISI_DETAIL.map((detail, key) => (
              <tr key={key}>
                <td className='has-text-centered'>
                  {detail?.TANGGAL_TRANSAKSI}
                </td>
                <td>{detail?.KETERANGAN}</td>
                <td className='has-text-right'>Rp. {detail?.TAGIHAN?.replace(/,/g, '.')}</td>
                <td className='has-text-centered'>
                  {detail?.['TANGGAL PEMBAYARAN']}
                </td>
                <td className='has-text-right'>Rp. {detail?.PEMBAYARAN.replace(/,/g, '.')}</td>
                <td className='has-text-right'>Rp. {detail?.SISA_TAGIHAN.replace(/,/g, '.')}</td>
              </tr>
            ))}
          <tr>
            <td className='has-text-weight-bold has-text-centered' colSpan={2}>
              Total
            </td>
            <td className='has-text-right'>
              {totalTagihan && formatRupiah(totalTagihan)}
            </td>
            <td></td>
            <td className='has-text-right'>
            {totalPembayaran && formatRupiah(totalPembayaran)}</td>
            <td className='has-text-right'>
            {totalSisaTagihan && formatRupiah(totalSisaTagihan)}</td>
          </tr>
        </tbody>
      </table>
      <div style={{ fontSize: 13, marginTop: 20 }}>
        {content?.KETERANGAN_BAWAH_1 && <div>{content.KETERANGAN_BAWAH_1}</div>}
        {content?.KETERANGAN_BAWAH_2 && <div>{content.KETERANGAN_BAWAH_2}</div>}
        {content?.KETERANGAN_BAWAH_3 && <div>{content.KETERANGAN_BAWAH_3}</div>}
        {content?.KETERANGAN_BAWAH_4 && <div>{content.KETERANGAN_BAWAH_4}</div>}
        {content?.KETERANGAN_BAWAH_5 && <div>{content.KETERANGAN_BAWAH_5}</div>}
        {content?.KETERANGAN_BAWAH_6 && <div>{content.KETERANGAN_BAWAH_6}</div>}
        {content?.KETERANGAN_BAWAH_7 && <div>{content.KETERANGAN_BAWAH_7}</div>}
      </div>
    </div>
  );
  // const table2 =
  //   saccounts &&
  //   saccounts.accountsContent &&
  //   parse(
  //     saccounts.accountsContent.replace(
  //       /(<(pre|script|style|textarea)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/g,
  //       '$1$3'
  //     )
  //   );

  return (
    <div id='account-statement'>
      <div className='mx-4'>{table}</div>
      {/* <div>{table2}</div> */}
    </div>
  );
}

export default connect(null, actions)(View);
