import { SLIDESHOW_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = [];

const slideshowGet = (state, payload) => {
  return [ ...payload.slideshow ];
};

export default createReducer(initialState, {
  [SLIDESHOW_GET]: slideshowGet,
});
