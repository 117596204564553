import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { rapormidGet, rapormidDetailGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import getUnixTime from 'date-fns/getUnixTime';
import { saveAs } from 'file-saver';

export const getRapormid = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRapormid' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'rapor-mid/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const rapormid = response.rapormid;
      const newArr = rapormid
        .split('content :')
        .join('')
        .split('<br/>')
        .filter((e) => /\S/.test(e));
      let newObj = [];
      if (!rapormid.includes('Tidak ada Rapor')) {
        for (let i = 0; i < newArr.length; i++) {
          if (!rapormid.includes('.pdf')) {
            let content = newArr[i].split('kdrapotnar=')[1];
            let id = content.split(';')[0];
            let year = content.split(';')[1];
            let mid = content.split(';')[2];
            let classgroup = content.split(';')[3];
            let obj = { id: id, published: '', year: year, mid: mid, classgroup: classgroup, type: 'html' };
            newObj.push(obj);
          } else {
            let id = newArr[i].split('IMAGEMURID/')[1].split('MID.pdf')[0];
            let dateTime = newArr[i].split('pdf-(')[1].split(')')[0];
            let amPM = dateTime.split(' ')[2];
            let dataDate = dateTime.split(' ')[0];
            let Day = dataDate.split('/')[1];
            let Month = dataDate.split('/')[0] - 1;
            let Year = dataDate.split('/')[2];
            let dataTime = dateTime.split(' ')[1];
            let Hour = amPM === 'PM' ? parseInt(dataTime.split(':')[0]) + 12 : dataTime.split(':')[0];
            let Minute =  dataTime.split(':')[1];
            let Second = dataTime.split(':')[2];
            // get unixTime for unique naming pdf
            let published = getUnixTime( new Date(parseInt(Year), parseInt(Month), parseInt(Day), parseInt(Hour), parseInt(Minute), parseInt(Second)));
            let content = newArr[i].split(');')[1];
            let year = content.split(';')[0];
            let mid = content.split(';')[1];
            let classgroup = content.split(';')[2];
            let obj = { id: id, published: published, year: year, mid: mid, classgroup: classgroup, type: 'pdf' };
            newObj.push(obj);
          }
        }
      }
      dispatch(rapormidGet(newObj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getRapormidDetail = (auth, history, id, linkRapor) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRapormidDetail' });
    const userId = auth.userId;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('rapormidId', id);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'rapor-mid/view', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const rapormidDetail = response.rapormidDetail;
      const rapormidContent = rapormidDetail.split('content :').join('');
      const obj = {
        id: response.rapormidId,
        content: linkRapor,
        rapormidContent: rapormidContent,
      };
      dispatch(rapormidDetailGet(obj));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const downloadRaporMid = (auth, history, id, published) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadRaporMid' });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('rapormidId', id);
    formData.append('published', published);
    try {
      const fetchData = await fetch(SITE_ADDRESS + 'rapor-mid/download', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const blob = await fetchData.blob();
      let name = id.split('/');
      saveAs(blob, `rapor_mid_${name[0]}_${name[1]}.pdf`);
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getLinkDownloadRaporMid = (auth, history, id, published) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLinkDownloadRaporMid' });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('rapormidId', id);
    formData.append('published', published);
    try {
      const fetchData = await fetch(SITE_ADDRESS + 'rapor-mid/link-download', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const linkRapor = response.url;
      dispatch(asyncActionFinish());
      return linkRapor;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
