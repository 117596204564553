import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../common/form/TextInput';
import TextArea from '../../../common/form/TextArea';
import RadioInput from '../../../common/form/RadioInput';
import { sortArrayObject } from '../../../common/helpers/objectHelpers';
import { stringSimilarity } from 'string-similarity-js';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../../common/util/siteConfig';
import { nanoid } from 'nanoid';

function ViewLatihanReviewForm(props) {
  const { id, initialValues, keyAnswers } = props;
  const [state, setState] = useState({
    title: '',
    description: '',
    questions: [],
    answers: [],
    keyActiveQuestion: '',
    activeQuestion: 1,
    confirmSubmit: false,
    submitStatus: false,
    showResult: false,
    start: true,
  });
  const scoreRef = useRef(0);

  let questions =
    state && state.questions && state.questions[0] ? state.questions : [];

  useEffect(() => {
    let qs = initialValues && initialValues.questions;
    let as = [];
    qs &&
      qs.forEach((item) => {
        return (as = [...as, { no: item.no, key: item.key, answer: '' }]);
      });
    setState({
      ...state,
      title: initialValues.title,
      description: initialValues.description,
      keyActiveQuestion: qs[state.activeQuestion - 1].key,
      questions: qs,
      answers: as,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleInputAnswer = (e, item) => {
    e.preventDefault();
    let answer = {
      no: item.no,
      key: item.key,
      answer: e.target.value,
    };
    let answers = [...state.answers.filter((i) => i.key !== item.key), answer];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const handleCheckRadio = (e, item, opt) => {
    let answer = {
      no: item.no,
      key: item.key,
      answer: opt,
    };
    let answers = [...state.answers.filter((i) => i.key !== item.key), answer];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    setState({
      ...state,
      confirmSubmit: true,
      submitStatus: true,
      showResult: true,
      start: false,
    });
    handleCountScore();
  };

  const handleCountScore = () => {
    let latihanAnswers =
      keyAnswers && keyAnswers.answers && JSON.parse(keyAnswers.answers);
    let count = 0;
    latihanAnswers.forEach((ans, index) => {
      if (questions[index].questionType === 'optionQuestion') {
        const string_chop = function (str, size) {
          if (str == null) return [];
          str = String(str);
          size = ~~size;
          return size > 0
            ? str.match(new RegExp('.{1,' + size + '}', 'g'))
            : [str];
        };
        let firstArray = '';
        if (initialValues.answers[index].answer.length > ans.answer.length) {
          firstArray = string_chop(
            initialValues.answers[index].answer,
            ans.answer.length
          )[0];
        }
        if (firstArray === ans.answer) {
          count = count + 1;
        }
      } else if (questions[index].questionType === 'longQuestion') {
        let addScore = stringSimilarity(
          ans.answer,
          initialValues.answers[index].answer
        );
        count = count + addScore;
      } else {
        if (ans.answer === initialValues.answers[index].answer) {
          count = count + 1;
        }
      }
    });
    let score = (count / latihanAnswers.length) * 100;
    scoreRef.current = score;
  };

  const handlePrevNextQuestion = (e, val) => {
    e.preventDefault();
    let activeQuestion = state.activeQuestion + val;
    setState({
      ...state,
      keyActiveQuestion: state.questions[activeQuestion - 1].key,
      activeQuestion: activeQuestion,
    });
  };

  let explanation =
    keyAnswers &&
    keyAnswers.answers &&
    JSON.parse(keyAnswers.answers)
      .filter((item) => item.key === state.keyActiveQuestion)[0]
      ?.explanation?.trim();

  return (
    <>
      <div className='flex justify-between mb-5'>
        <div className='has-text-weight-bold custom-text-overflow'>
          {state.title}
        </div>
        <div className='flex justify-end'>
          <div className='buttons'>
            <Link
              to={'/pembelajaran/latihan/' + id}
              className='button custom-grey is-small is-rounded is-outlined'
            >
              <i className='fas fa-arrow-left icon' />
            </Link>
          </div>
        </div>
      </div>
      <div className='flex flex-column mb-4'>
        <article className='message'>
          <div className='message-body is-size-7'>
            <p>Deskripsi:</p>
            <p className='is-capitalized' style={{ whiteSpace: 'pre-line' }}>
              {state.description}
            </p>
          </div>
        </article>
      </div>
      {state && state.start && !state.submitStatus && !state.confirmSubmit && (
        <div className='flex justify-center mb-3'>
          <button
            disabled={state.activeQuestion === 1}
            className='button is-small is-rounded is-primary is-outlined mr-1'
            onClick={(e) => handlePrevNextQuestion(e, -1)}
          >
            <i className='fas fa-arrow-left icon' />
          </button>
          <div className='ml-1 mr-1'>
            {state.activeQuestion}/{questions.length}
          </div>
          {state.activeQuestion < questions.length && (
            <button
              disabled={
                state.activeQuestion === questions.length || !state.start
              }
              className='button is-small is-rounded is-primary is-outlined ml-1'
              onClick={(e) => handlePrevNextQuestion(e, 1)}
            >
              <i className='fas fa-arrow-right icon' />
            </button>
          )}
          {state.activeQuestion === questions.length && (
            <button
              onClick={(e) => handleConfirmSubmit(e)}
              className='button is-small is-rounded is-primary is-outlined ml-1'
            >
              <i className='fas fa-arrow-right icon' />
            </button>
          )}
        </div>
      )}
      <div className='columns'>
        <div className='column is-third-quarter'>
          <div className='content'>
            {state && !state.submitStatus && !state.confirmSubmit && (
              <>
                <div className='mb-2' style={{ whiteSpace: 'pre-line' }}>
                  {questions && questions[state.activeQuestion - 1] && (
                    <>
                      <div>
                        {questions[state.activeQuestion - 1].picture &&
                          questions[state.activeQuestion - 1].picture
                            .filelink && (
                            <img
                              src={
                                SITE_ADDRESS_PEMBELAJARAN +
                                questions[state.activeQuestion - 1].picture
                                  .filelink
                              }
                              alt=''
                              style={{ maxHeight: 284 }}
                            />
                          )}
                      </div>
                      <div>{questions[state.activeQuestion - 1].question}</div>
                    </>
                  )}
                </div>
                {questions &&
                  questions[state.activeQuestion - 1] &&
                  questions[state.activeQuestion - 1].questionType !==
                    'optionQuestion' && (
                    <>
                      <div className='field'>
                        <label
                          className='label'
                          htmlFor={'jawaban_' + nanoid()}
                        >
                          Jawaban{' '}
                          {initialValues &&
                            initialValues.answers &&
                            initialValues.answers
                              .filter(
                                (item) => item.key === state.keyActiveQuestion
                              )[0]
                              .answer.includes(
                                keyAnswers &&
                                  keyAnswers.answers &&
                                  JSON.parse(keyAnswers.answers).filter(
                                    (item) =>
                                      item.key === state.keyActiveQuestion
                                  )[0].answer
                              ) && (
                              <span className='has-text-success-dark'>
                                <i className='fas fa-check-circle icon' />
                              </span>
                            )}
                          {initialValues &&
                            initialValues.answers &&
                            !initialValues.answers
                              .filter(
                                (item) => item.key === state.keyActiveQuestion
                              )[0]
                              .answer.includes(
                                keyAnswers &&
                                  keyAnswers.answers &&
                                  JSON.parse(keyAnswers.answers).filter(
                                    (item) =>
                                      item.key === state.keyActiveQuestion
                                  )[0].answer
                              ) && (
                              <span className='has-text-danger-dark'>
                                <i className='fas fa-times-circle icon' />
                              </span>
                            )}
                        </label>
                      </div>
                      <Field
                        readOnly
                        name={
                          'answers_' + questions[state.activeQuestion - 1].key
                        }
                        type='text'
                        component={
                          questions &&
                          questions[state.activeQuestion - 1] &&
                          questions[state.activeQuestion - 1].questionType ===
                            'shortQuestion'
                            ? TextInput
                            : TextArea
                        }
                        defaultValue={
                          initialValues &&
                          initialValues.answers &&
                          initialValues.answers[state.activeQuestion - 1] &&
                          initialValues.answers[state.activeQuestion - 1].answer
                            ? initialValues.answers[state.activeQuestion - 1]
                                .answer
                            : ''
                        }
                        onChange={(e) =>
                          handleInputAnswer(
                            e,
                            questions[state.activeQuestion - 1]
                          )
                        }
                        placeholder='Jawaban'
                        label='noLabel'
                      />
                      {initialValues &&
                        initialValues.answers &&
                        !initialValues.answers
                          .filter(
                            (item) => item.key === state.keyActiveQuestion
                          )[0]
                          .answer.includes(
                            keyAnswers &&
                              keyAnswers.answers &&
                              JSON.parse(keyAnswers.answers).filter(
                                (item) => item.key === state.keyActiveQuestion
                              )[0].answer
                          ) && (
                          <Field
                            readOnly
                            name={'key_answer_' + nanoid()}
                            type='text'
                            component={TextInput}
                            defaultValue={
                              (keyAnswers &&
                                keyAnswers.answers &&
                                JSON.parse(keyAnswers.answers).filter(
                                  (item) => item.key === state.keyActiveQuestion
                                )[0].answer) ||
                              ''
                            }
                            onChange={(e) => handleInputAnswer(e)}
                            placeholder='Jawaban'
                            label='Kunci Jawaban'
                          />
                        )}
                    </>
                  )}
                {questions &&
                  questions[state.activeQuestion - 1] &&
                  questions[state.activeQuestion - 1].options &&
                  questions[state.activeQuestion - 1].options[0] &&
                  questions[state.activeQuestion - 1].options.map(
                    (opt, index) => {
                      let key = questions[state.activeQuestion - 1].key;
                      let sortAnswer = initialValues.answers.filter(
                        (item) => item.key === key
                      )[0];
                      let keyAnswer =
                        keyAnswers &&
                        JSON.parse(keyAnswers.answers).filter(
                          (item) => item.key === key
                        )[0];
                      return (
                        <div
                          key={index}
                          className='has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center'
                        >
                          <Field
                            key={index}
                            disabled={true}
                            name='answer'
                            type='radio'
                            component={RadioInput}
                            value={opt}
                            checked={opt === sortAnswer.answer ? true : false}
                            onChange={(e) =>
                              handleCheckRadio(
                                e,
                                questions[state.activeQuestion - 1],
                                opt
                              )
                            }
                            placeholder={opt}
                            label={opt}
                            size='is-size-6'
                          />
                          {opt.includes(keyAnswer.answer) && (
                            <span
                              className='has-text-success-dark'
                              style={{ position: 'absolute', right: 16 }}
                            >
                              <i className='fas fa-check-circle icon' />
                            </span>
                          )}
                        </div>
                      );
                    }
                  )}
                {explanation &&
                  explanation !== 'Penjelasan jawaban...' &&
                  explanation !== '' && (
                    <Field
                      readOnly
                      name={'explanation_' + nanoid()}
                      type='text'
                      component={TextArea}
                      defaultValue={
                        (keyAnswers &&
                          keyAnswers.answers &&
                          JSON.parse(keyAnswers.answers).filter(
                            (item) => item.key === state.keyActiveQuestion
                          )[0].explanation) ||
                        ' '
                      }
                      onChange={(e) => handleInputAnswer(e)}
                      placeholder='Penjelasan'
                      label='Penjelasan'
                    />
                  )}
              </>
            )}
            {state &&
              state.confirmSubmit &&
              state.submitStatus &&
              state.showResult && (
                <div className='has-text-centered'>
                  <p>Your Score</p>
                  <p style={{ marginTop: -30, marginBottom: 0, fontSize: 80 }}>
                    {scoreRef.current.toFixed(1)}
                  </p>
                  <p>Selesai dalam {initialValues.time}</p>
                  <div className='flex justify-center'>
                    <Link
                      to={`/pembelajaran/latihan/` + id}
                      className='button is-small is-rounded is-primary is-outlined'
                    >
                      Kembali
                    </Link>
                  </div>
                </div>
              )}
            <hr />
            <label className='label'>Tags:</label>
            <div className='tags is-capitalized'>
              {keyAnswers &&
                keyAnswers.tags &&
                JSON.parse(keyAnswers.tags).map((item, index) => (
                  <span key={index} className='tag is-success is-light'>
                    {item}
                  </span>
                ))}
              {keyAnswers && !keyAnswers.tags && <>-</>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'latihanReview',
  enableReinitialize: true,
})(ViewLatihanReviewForm);
