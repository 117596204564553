import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../common/form/TextInput';
import TextArea from '../../../common/form/TextArea';
import RadioInput from '../../../common/form/RadioInput';
import { sortArrayObject } from '../../../common/helpers/objectHelpers';
import { stringSimilarity } from 'string-similarity-js';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../../common/util/siteConfig';

function ViewTugasForm(props) {
  const { history, id, initialValues } = props;
  const [state, setState] = useState({
    title: '',
    description: '',
    questions: [],
    answers: [],
    activeQuestion: 1,
    confirmSubmit: false,
    submitStatus: false,
    showResult: false,
    start: false,
    ss: 0,
    mm: 0,
    hh: 0,
    time: '00:00:00',
  });
  const scoreRef = useRef(0);

  let questions =
    state && state.questions && state.questions[0] ? state.questions : [];

  useEffect(() => {
    let qs =
      initialValues &&
      initialValues.questions &&
      JSON.parse(initialValues.questions);
    let as = [];
    qs &&
      qs.forEach((item) => {
        return (as = [...as, { no: item.no, key: item.key, answer: '' }]);
      });
    setState({
      ...state,
      title: initialValues.title,
      description: initialValues.description,
      questions: qs,
      answers: as,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    let timer;
    if (state.start) {
      timer = setTimeout(() => {
        let ss = state.ss + 1;
        let mm = state.mm;
        let hh = state.hh;
        if (ss > 59) {
          ss = 0;
          mm = state.mm + 1;
        }
        if (mm > 59) {
          mm = 0;
          hh = state.hh + 1;
        }
        setState({
          ...state,
          ss: ss,
          mm: mm,
          hh: hh,
        });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  const handleInputAnswer = (e, item) => {
    e.preventDefault();
    let answer = {
      no: item.no,
      key: item.key,
      answer: e.target.value,
    };
    let answers = [...state.answers.filter((i) => i.key !== item.key), answer];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const handleCheckRadio = (e, item, opt) => {
    let answer = {
      no: item.no,
      key: item.key,
      answer: opt,
    };
    let answers = [...state.answers.filter((i) => i.key !== item.key), answer];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const handleStart = () => {
    setState({
      ...state,
      start: !state.start,
    });
  };

  const handleCountTime = () => {
    let time = `${state.hh}:${state.mm < 10 ? '0' : ''}${state.mm}:${
      state.ss < 10 ? '0' : ''
    }${state.ss}`;
    return time;
  };

  const handleSubmitResetAnswer = (e, val) => {
    e.preventDefault();
    let emptyAnswer = false;
    state &&
      state.answers &&
      state.answers.forEach((item) => {
        if (item.answer === '') {
          emptyAnswer = true;
        }
      });
    if (val === 'submit' && emptyAnswer) {
      setState({
        ...state,
        confirmSubmit: true,
        submitStatus: false,
        showResult: false,
      });
    } else {
      let as = [];
      if (val === 'reset') {
        state &&
          state.answers &&
          state.answers.forEach((item) => {
            let answer = { ...item, answer: '' };
            return (as = [...as, answer]);
          });
        scoreRef.current = 0;
      } else {
        handleCountScore();
      }
      let time = handleCountTime();
      setState({
        ...state,
        answers: val === 'reset' ? as : state.answers,
        activeQuestion: val === 'reset' ? 1 : state.activeQuestion,
        confirmSubmit: val === 'reset' ? false : true,
        submitStatus: val === 'reset' ? false : true,
        showResult: val === 'reset' ? false : true,
        start: false,
        ss: 0,
        mm: 0,
        hh: 0,
        time: time,
      });
    }
  };

  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    let time = handleCountTime();
    setState({
      ...state,
      confirmSubmit: true,
      submitStatus: true,
      showResult: true,
      start: false,
      ss: 0,
      mm: 0,
      hh: 0,
      time: time,
    });
    handleCountScore();
  };

  const handleCountScore = () => {
    let keyAnswers = JSON.parse(initialValues.answers);
    let count = 0;
    keyAnswers.forEach((ans, index) => {
      if (questions[index].questionType === 'optionQuestion') {
        const string_chop = function (str, size) {
          if (str == null) return [];
          str = String(str);
          size = ~~size;
          return size > 0
            ? str.match(new RegExp('.{1,' + size + '}', 'g'))
            : [str];
        };
        let firstArray = '';
        if (state.answers[index].answer.length > ans.answer.length) {
          firstArray = string_chop(
            state.answers[index].answer,
            ans.answer.length
          )[0];
        }
        if (firstArray === ans.answer) {
          count = count + 1;
        }
      } else if (questions[index].questionType === 'longQuestion') {
        let addScore = stringSimilarity(
          ans.answer,
          state.answers[index].answer
        );
        count = count + addScore;
      } else {
        if (ans.answer === state.answers[index].answer) {
          count = count + 1;
        }
      }
    });
    let score = (count / keyAnswers.length) * 100;
    scoreRef.current = score;
  };

  const handleCancelSubmit = (e) => {
    e.preventDefault();
    setState({
      ...state,
      confirmSubmit: !state.confirmSubmit,
    });
  };

  const handlePrevNextQuestion = (e, val) => {
    e.preventDefault();
    let activeQuestion = state.activeQuestion + val;
    setState({
      ...state,
      activeQuestion: activeQuestion,
    });
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/pembelajaran/tugas/' + id}>{state.title}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {state.activeQuestion === questions.length && !state.showResult && (
                <button
                  onClick={(e) => handleSubmitResetAnswer(e, 'submit')}
                  className='button is-small is-rounded is-primary is-outlined'
                >
                  Submit
                </button>
              )}
              {state && state.start && (
                <button
                  className='button is-small is-rounded is-primary is-outlined'
                  style={{ width: 100 }}
                >{`${state.hh < 10 ? '0' : ''}${state.hh}:${
                  state.mm < 10 ? '0' : ''
                }${state.mm}:${state.ss < 10 ? '0' : ''}${state.ss}`}</button>
              )}
              {state && !state.start && !state.showResult && (
                <>
                  <button
                    onClick={(e) => handleStart(e)}
                    className='button is-small is-rounded is-primary is-outlined'
                  >
                    Start
                  </button>
                  <button
                    onClick={() => history.goBack()}
                    className='button custom-grey is-small is-rounded is-outlined'
                  >
                    <i className='fas fa-arrow-left icon' />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {state && state.start && !state.submitStatus && !state.confirmSubmit && (
        <div className='flex justify-center mb-3' style={{ marginTop: -12 }}>
          <button
            disabled={state.activeQuestion === 1}
            className='button is-small is-rounded is-primary is-outlined mr-1'
            onClick={(e) => handlePrevNextQuestion(e, -1)}
          >
            <i className='fas fa-arrow-left icon' />
          </button>
          <div className='ml-1 mr-1'>
            {state.activeQuestion}/{questions.length}
          </div>
          <button
            disabled={state.activeQuestion === questions.length || !state.start}
            className='button is-small is-rounded is-primary is-outlined ml-1'
            onClick={(e) => handlePrevNextQuestion(e, 1)}
          >
            <i className='fas fa-arrow-right icon' />
          </button>
        </div>
      )}
      {state && !state.start && !state.showResult && (
        <div className='flex flex-column mb-4' style={{ marginTop: -12 }}>
          <article className='message'>
            <div className='message-body is-size-7'>
              <p>Deskripsi:</p>
              <p className='is-capitalized' style={{ whiteSpace: 'pre-line' }}>
                {state.description}
              </p>
            </div>
          </article>
        </div>
      )}
      <div className='columns'>
        <div className='column is-third-quarter'>
          <div className='content'>
            {state && !state.submitStatus && !state.confirmSubmit && (
              <>
                <div className='mb-2' style={{ whiteSpace: 'pre-line' }}>
                  {questions && questions[state.activeQuestion - 1] && (
                    <>
                      <div>
                        {questions[state.activeQuestion - 1].picture &&
                          questions[state.activeQuestion - 1].picture
                            .filelink && (
                            <img
                              src={
                                SITE_ADDRESS_PEMBELAJARAN +
                                questions[state.activeQuestion - 1].picture
                                  .filelink
                              }
                              alt=''
                              style={{ maxHeight: 284 }}
                            />
                          )}
                      </div>
                      <div>{questions[state.activeQuestion - 1].question}</div>
                    </>
                  )}
                </div>
                {questions &&
                  questions[state.activeQuestion - 1] &&
                  questions[state.activeQuestion - 1].questionType !==
                    'optionQuestion' && (
                    <Field
                      name={
                        'answers_' + questions[state.activeQuestion - 1].key
                      }
                      type='text'
                      component={
                        questions &&
                        questions[state.activeQuestion - 1] &&
                        questions[state.activeQuestion - 1].questionType ===
                          'shortQuestion'
                          ? TextInput
                          : TextArea
                      }
                      defaultValue={
                        state &&
                        state.answers &&
                        state.answers[state.activeQuestion - 1] &&
                        state.answers[state.activeQuestion - 1].answer
                          ? state.answers[state.activeQuestion - 1].answer
                          : ''
                      }
                      onChange={(e) =>
                        handleInputAnswer(
                          e,
                          questions[state.activeQuestion - 1]
                        )
                      }
                      placeholder='Jawaban'
                      label='Jawaban'
                    />
                  )}
                {questions &&
                  questions[state.activeQuestion - 1] &&
                  questions[state.activeQuestion - 1].options &&
                  questions[state.activeQuestion - 1].options[0] &&
                  questions[state.activeQuestion - 1].options.map(
                    (opt, index) => {
                      return (
                        <div
                          key={index}
                          className='has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center'
                        >
                          <Field
                            key={index}
                            name='answer'
                            type='radio'
                            component={RadioInput}
                            value={opt}
                            checked={
                              opt ===
                              state.answers[state.activeQuestion - 1].answer
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleCheckRadio(
                                e,
                                questions[state.activeQuestion - 1],
                                opt
                              )
                            }
                            placeholder={opt}
                            label={opt}
                            size='is-size-6'
                          />
                        </div>
                      );
                    }
                  )}
              </>
            )}
            {state &&
              state.confirmSubmit &&
              !state.submitStatus &&
              !state.showResult && (
                <div className='has-text-centered'>
                  <p>
                    Belum semua pertanyaan terjawab, <br />
                    apakah tetap ingin submit jawaban?
                  </p>
                  <div
                    className='flex justify-center mt-3'
                    style={{ marginTop: -8 }}
                  >
                    <button
                      onClick={(e) => handleConfirmSubmit(e, 'reset')}
                      className='button is-small is-rounded is-primary is-outlined ml-1'
                    >
                      Konfirm
                    </button>
                    <button
                      onClick={(e) => handleCancelSubmit(e)}
                      className='button is-small is-rounded is-primary is-outlined ml-1'
                    >
                      Batal
                    </button>
                  </div>
                </div>
              )}
            {state &&
              state.confirmSubmit &&
              state.submitStatus &&
              state.showResult && (
                <div className='has-text-centered'>
                  <p>Your Score</p>
                  <p style={{ marginTop: -30, marginBottom: 0, fontSize: 80 }}>
                    {scoreRef.current.toFixed(1)}
                  </p>
                  <p>Selesai dalam {state.time}</p>
                  <div className='flex justify-center'>
                    <button
                      onClick={(e) => handleSubmitResetAnswer(e, 'reset')}
                      className='button is-small is-rounded is-primary is-outlined ml-1'
                    >
                      Reset
                    </button>
                  </div>
                </div>
              )}
            {state && !state.start && !state.showResult && (
              <>
                <hr />
                <label className='label'>Tags:</label>
                <div className='tags is-capitalized'>
                  {initialValues &&
                    initialValues.tags &&
                    JSON.parse(initialValues.tags).map((item, index) => (
                      <span key={index} className='tag is-success is-light'>
                        {item}
                      </span>
                    ))}
                  {initialValues && !initialValues.tags && <>-</>}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'tugasView',
  enableReinitialize: true,
})(ViewTugasForm);
