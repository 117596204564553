import { RAPORMIDKOLAB_GET, RAPORSEMKOLAB_GET  } from './reduxConstant';

export const rapormidkolabGet = (rapormidkolab) => {
  return {
    type: RAPORMIDKOLAB_GET,
    payload: {
      rapormidkolab,
    },
  };
};

export const raporsemkolabGet = (raporsemkolab) => {
  return {
    type: RAPORSEMKOLAB_GET,
    payload: {
      raporsemkolab,
    },
  };
};
