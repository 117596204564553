import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../common/async/asyncActions';
import { agendaGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../common/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../common/util/siteConfig';
import { checkRes, checkErr } from '../../../../common/helpers/checkRes';
import { saveAs } from 'file-saver';

export const getAgenda = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAgenda' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'agenda/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const agenda = response.agenda;
      const newArr = agenda
        .split('content :')
        .join('')
        .split('~~~PEMISAH~~~')
        .filter((e) => /\S/.test(e));
      let arrAgenda = [];
      if (newArr.length > 0) {
        newArr.forEach((item) => {
          let date = item
            .split('<br/>')
            .filter((e) => /\S/.test(e))[0]
            .replace(/(<([^>]+)>)/gi, '');
          let message = item.split('<br/>').filter((e) => /\S/.test(e))[1];
          
          let newFiles = [];
          if (item.includes('<br/>Image ke')) {
            let arrFiles = ('Image ke 1' + item.split('<br/>Image ke 1')[1])
              .split('<br/>')
              .filter((e) => /\S/.test(e));
              newFiles = [...newFiles, ...arrFiles]
          }
          let files = [];
          if (newFiles.length > 0) {
            newFiles.forEach((item) => {
              let newStr = item.split('http://202.43.173.27:3381/IMAGELAIN/')[1].replace('/', '__');
              files.push(newStr);
            })
          }
          let newObj = { date, message, files };
          arrAgenda.push(newObj);
        });
      }
      dispatch(agendaGet(arrAgenda));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const downloadPdf = (auth, url) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadPdf' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    formData.append('url', url);
    try {
      const fetchData = await fetch(SITE_ADDRESS + 'agenda/view-pdf', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
      const blob = await fetchData.blob();
      let name = `${Date.now()}__${url}`;
      saveAs(blob, name);
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  }
}
