import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { openModal } from '../modals/redux/modalActions';

const mapState = (state) => ({
  auth: state.auth,
});

const actions = {
  openModal,
};

export class BottomNavbar extends Component {
  _isMounted = false;
  state = {
    changeProfile: false,
    studentSelected: '',
  };

  componentDidMount = () => {
    const { auth, unreadPesan } = this.props;
    if (unreadPesan && unreadPesan.length > 0) {
      document.querySelector('#bottomNavNotif').style.display = 'block';
    } else {
      document.querySelector('#bottomNavNotif').style.display = 'none';
    }
    this.setState({
      studentSelected: auth && auth.userId,
    });
    document.addEventListener('click', (e) => {
      if (!e.target.closest('#changeProfile')) {
        this.setState({
          changeProfile: false,
        });
      }
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    document.removeEventListener('click', (e) => {
      if (!e.target.closest('#changeProfile')) {
        this.setState({
          changeProfile: false,
        });
      }
    })
  };

  handleLink = (value) => {
    const { pathname, history } = this.props;
    if (pathname === value) {
      return;
    }
    history.push(value);
  };

  handleMenu = (val) => {
    const { changeProfile } = this.state;
    const { auth, sprofil, logout, menus, pathname } = this.props;
    if (val === 'openMenu') {
      let url =
        pathname.split('/').length > 2
          ? '/' + pathname.split('/')[1]
          : pathname;
      this.props.openModal('MenuModal', {
        auth,
        sprofil,
        logout,
        menus,
        pathname,
        url,
      });
    }
    if (val === 'changeProfile') {
      this.setState({
        changeProfile: !changeProfile,
      });
    }
  };

  handleChangeProfile = (val) => {
    const { auth, history, userrole, updateAuth } = this.props;
    const userIdnew = val;
    let selectedItem = userrole.group.filter((item) => {
      return item.userId === userIdnew;
    })[0];
    this.setState({
      studentSelected: userIdnew,
    });

    if (userIdnew !== auth.userId) {
      updateAuth({ userIdnew, username: selectedItem.name }, auth, history);
    }
  };

  render() {
    const { auth, userrole, pathname, uPass } = this.props;
    return (
      <>
        {auth.isAuth && (
          <nav
            id='bottomNavbar'
            className='navbar is-primary is-fixed-bottom is-hidden-tablet'
            role='navigation'
          >
            <div className='navbar-brand'>
              <div
                onClick={() => this.handleLink('/dashboard')}
                className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                  uPass && uPass.includes('ios_') ? ' pb-3' : ''
                }`}
                style={
                  pathname === '/dashboard'
                    ? { backgroundColor: '#00a78e' }
                    : {}
                }
              >
                <i className='fas fa-th-large' />
                <p className='is-size-7'>Dashboard</p>
              </div>
              <div
                onClick={() => this.handleLink('/pesan-pribadi')}
                className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                  uPass && uPass.includes('ios_') ? ' pb-3' : ''
                }`}
                style={
                  pathname === '/pesan-pribadi'
                    ? { backgroundColor: '#00a78e' }
                    : {}
                }
              >
                <i className='fas fa-envelope' />
                <span
                  id='bottomNavNotif'
                  className='is-overlay'
                  style={{ display: 'none' }}
                >
                  <i
                    className='fas fa-circle has-text-danger ml-4'
                    style={{ fontSize: 8 }}
                  />
                </span>
                <p className='is-size-7'>Pesan</p>
              </div>
              {userrole && userrole.role === 'Siswa' && (
                <div
                  onClick={() => this.handleLink('/profil')}
                  className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                    uPass && uPass.includes('ios_')
                      ? ' pb-3'
                      : ''
                  }`}
                  style={
                    pathname === '/profil' ? { backgroundColor: '#00a78e' } : {}
                  }
                >
                  <i className='fas fa-user' />
                  <p className='is-size-7'>Profil</p>
                </div>
              )}
              {userrole &&
                userrole.role !== 'Siswa' &&
                userrole.group &&
                userrole.group.length > 1 && (
                  <div
                    onClick={() => this.handleMenu('changeProfile')}
                    className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                      uPass && uPass.includes('ios_')
                        ? ' pb-3'
                        : ''
                    }`}
                  >
                    <div id='changeProfile' className='customDropdown'>
                      <div className='customlink'>
                        <i className='fas fa-user' />
                        <p className='is-size-7'>Ubah Profil</p>
                      </div>
                    </div>
                    {this.state.changeProfile && (
                      <div className='fadein customdropdownmenu2'>
                        <div className='customdropdowncontent2'>
                          {userrole &&
                            userrole.group &&
                            userrole.group.map((item, key) => (
                              <div
                                key={key}
                                onClick={() =>
                                  this.handleChangeProfile(item.userId)
                                }
                                className={`customdropdowncontentlist${
                                  auth && auth.userId === item.userId
                                    ? ' contentlistactive no-pointer'
                                    : ''
                                }`}
                              >
                                {item.name}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              <div
                onClick={() => this.handleMenu('openMenu')}
                className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                  uPass && uPass.includes('ios_') ? ' pb-3' : ''
                }`}
              >
                <div className='customdropdown'>
                  <div className='customlink'>
                    <i className='fas fa-th' />
                    <p className='is-size-7'>Menu</p>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        )}
      </>
    );
  }
}

export default withRouter(connect(mapState, actions)(BottomNavbar));
