import { PRESENSI_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = {};

const presensiGet = (state, payload) => {
  return payload.presensi;
};

export default createReducer(initialState, {
  [PRESENSI_GET]: presensiGet,
});
