import React from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { combineValidators, isRequired } from 'revalidate';
import { reduxForm, Field } from 'redux-form';
import CustomTextInput from '../../common/form/CustomTextInput';
import { registerAuth } from './redux/authApi';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
  };
}

const actions = {
  registerAuth,
};

const validate = combineValidators({
  username: isRequired({ message: 'Username siswa harus diisi' }),
  password: isRequired({ message: 'Password siswa harus diisi' }),
});

function Register1(props) {
  const { registerAuth, handleSubmit, auth, error, loading } = props;

  const handleLogin = async (authData) => {
    await registerAuth(authData);
  };

  if (auth && auth.isAuth && auth.registerParents) return <Redirect to='/register-2' />;
  if (auth && auth.isAuth && !auth.registerParents) return <Redirect to='/dashboard' />;

  return (
    <>
      <section className='hero is-fullheight' style={{ marginTop: -52 }}>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd'></div>
              <div className='column is-two-quarter-tablet is-two-quarter-desktop is-two-quarter-widescreen is-two-quarter-fullhd is-centered'>
                <div className='box'>
                  <div className='media'>
                    <div className='media-content'>
                      <div className='content'>
                        <div className='is-size-3 has-text-centered has-text-weight-semibold'>
                          Register
                        </div>
                        <div className='is-size-6 has-text-centered mb-5'>
                          Orang Tua/Wali
                        </div>
                        <form
                          onSubmit={handleSubmit(handleLogin)}
                          autoComplete='off'
                        >
                          <Field
                            name='username'
                            type='text'
                            component={CustomTextInput}
                            placeholder='Username'
                            autoComplete='new-password'
                            label='Username Siswa'
                            icon='user'
                          />
                          <Field
                            name='password'
                            type='password'
                            autoComplete='new-password'
                            component={CustomTextInput}
                            placeholder='Password'
                            label='Password Siswa'
                            icon='lock'
                          />
                          <div className='tags has-addons has-text-centered is-fullwidth' style={{display: 'flex', justifyContent: 'center', marginTop: 16}}>
                            <span className='tag is-primary is-rounded'>Langkah 1</span>
                            <span className='tag is-rounded'>Langkah 2</span>
                          </div>
                          <div
                            className='field has-text-centered'
                            style={{ marginTop: 10 }}
                          >
                            {error && <p className='help is-danger'>{error}</p>}
                            <button
                              type='submit'
                              disabled={loading}
                              style={{ marginRight: 10 }}
                              className='button is-link is-small is-rounded is-outlined'
                            >
                              {loading ? 'Next...' : 'Next'}
                            </button>
                          </div>
                        </form>
                        <div className='has-text-link is-size-7 has-text-right is-italic hand-pointer mt-4'>
                          <Link to='/'>
                            <i className='fas fa-sign-in-alt icon' />
                            Kembali ke halaman Login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='column is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(
  connect(
    mapState,
    actions
  )(reduxForm({ form: 'formRegister1', validate })(Register1))
);
