import { RAPORMID_GET, RAPORMIDDETAIL_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = [];

const rapormidGet = (state, payload) => {
  return [...payload.rapormid];
};

const rapormidDetailGet = (state, payload) => {
  let rapormidDetail = {
    ...state.filter((item) => item.id === payload.rapormidDetail.id)[0],
    ...payload.rapormidDetail,
  };
  return [
    ...state.filter((item) => item.id !== payload.rapormidDetail.id),
    rapormidDetail,
  ];
};

export default createReducer(initialState, {
  [RAPORMID_GET]: rapormidGet,
  [RAPORMIDDETAIL_GET]: rapormidDetailGet,
});
