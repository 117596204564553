import { ACCOUNTS_GET, ACCOUNTSDETAIL_GET } from './reduxConstant';
import { createReducer } from '../../../../common/util/reducerUtils';

const initialState = { id: '', content: '', accountsContent: '' };

const accountsGet = (state, payload) => {
  return {...state, ...payload.accounts};
};

const accountsDetailGet = (state, payload) => {
  return {...state, ...payload.accounts};
};

export default createReducer(initialState, {
  [ACCOUNTS_GET]: accountsGet,
  [ACCOUNTSDETAIL_GET]: accountsDetailGet,
});
