import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { stringSimilarity } from 'string-similarity-js';

function ViewLatihanResultForm(props) {
  const { id, initialValues, keyAnswers } = props;
  const [scoreCount, setScoreCount] = useState(0);

  useEffect(() => {
    handleCountScore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, keyAnswers]);

  const handleCountScore = () => {
    let latihanAnswers =
      keyAnswers && keyAnswers.answers && JSON.parse(keyAnswers.answers);
    let count = 0;
    latihanAnswers &&
      latihanAnswers.forEach((ans, index) => {
        if (
          initialValues &&
          initialValues.questions &&
          initialValues.questions[0] &&
          initialValues.questions[index]?.questionType === 'optionQuestion'
        ) {
          const string_chop = function (str, size) {
            if (str == null) return [];
            str = String(str);
            size = ~~size;
            return size > 0
              ? str.match(new RegExp('.{1,' + size + '}', 'g'))
              : [str];
          };
          let firstArray = '';
          if (initialValues.answers[index].answer.length > ans.answer.length) {
            firstArray = string_chop(
              initialValues.answers[index].answer,
              ans.answer.length
            )[0];
          }
          if (firstArray === ans.answer) {
            count = count + 1;
          }
        }

        if (initialValues?.questions[index]?.questionType === 'longQuestion') {
          let addScore = stringSimilarity(
            ans.answer,
            initialValues.answers[index].answer
          );
          count = count + addScore;
        }

        if (initialValues?.questions[index]?.questionType === 'shortQuestion') {
          if (ans.answer === initialValues.answers[index].answer) {
            count = count + 1;
          }
        }
      });
    let score =
      latihanAnswers &&
      latihanAnswers.length &&
      (count / latihanAnswers.length) * 100;
    setScoreCount(score);
  };

  return (
    <>
      <div className='flex justify-between mb-5'>
        <div className='has-text-weight-bold custom-text-overflow'>
          {initialValues?.title}
        </div>
        <div className='flex justify-end'>
          <div className='buttons'>
            <Link
              to={'/pembelajaran/latihan/' + id}
              className='button custom-grey is-small is-rounded is-outlined'
            >
              <i className='fas fa-arrow-left icon' />
            </Link>
          </div>
        </div>
      </div>
      <div className='flex flex-column mb-4'>
        <article className='message'>
          <div className='message-body is-size-7'>
            <p>Deskripsi:</p>
            <p className='is-capitalized' style={{ whiteSpace: 'pre-line' }}>
              {initialValues?.description}
            </p>
          </div>
        </article>
      </div>
      <br />
      <div className='columns'>
        <div className='column is-third-quarter'>
          <div className='content'>
            <div className='has-text-centered'>
              <p>Your Score</p>
              <p style={{ marginTop: -30, marginBottom: 0, fontSize: 80 }}>
                {scoreCount === undefined && (
                  <span className='is-size-5'>Calculating...</span>
                )}
                <span>{scoreCount && scoreCount.toFixed(1)}</span>
              </p>
              <p>Selesai dalam {initialValues.time}</p>
              <div className='flex justify-center'>
                <Link
                  to={{
                    pathname: '/pembelajaran/latihan/review/' + id,
                    state: initialValues,
                  }}
                  className='button is-small is-rounded is-primary is-outlined ml-1'
                >
                  Review
                </Link>
                <Link
                  to={`/pembelajaran/latihan/` + id}
                  className='button is-small is-rounded is-primary is-outlined ml-1'
                >
                  Kembali
                </Link>
              </div>
            </div>
            <hr />
            <label className='label'>Tags:</label>
            <div className='tags is-capitalized'>
              {keyAnswers &&
                keyAnswers.tags &&
                JSON.parse(keyAnswers.tags).map((item, index) => (
                  <span key={index} className='tag is-success is-light'>
                    {item}
                  </span>
                ))}
              {keyAnswers && !keyAnswers.tags && <>-</>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'latihanResult',
  enableReinitialize: true,
})(ViewLatihanResultForm);
