import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getKalender } from './redux/reduxApi';
import Calendar from 'react-awesome-calendar';
import getUnixTime from 'date-fns/getUnixTime';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'kalender-akademik'
    )[0];
  }
  return {
    auth: state.auth,
    skalender: state.skalender,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getKalender,
};

function Index(props) {
  let history = useHistory();
  const elKalender = useRef();
  const [mode, setMode] = useState('monthlyMode');
  const { swipeToggleStatus, getKalender, auth, skalender } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    getKalender(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  function handleMode(date) {
    setMode(date.mode);
  }

  function getUnixtime(dateTime) {
    let dataDateTime = dateTime.split('T');
    let dataDate = dataDateTime[0].split('-');
    let dataYear = dataDate[0];
    let dataMonth = dataDate[1];
    let dataDay = dataDate[2];
    let dataTime = dataDateTime[1].split(':');
    let dataHour = dataTime[0];
    let dataMinute = dataTime[1];
    let eventDate = getUnixTime(
      new Date(
        parseInt(dataYear),
        parseInt(dataMonth),
        parseInt(dataDay),
        parseInt(dataHour),
        parseInt(dataMinute)
      )
    );
    return eventDate;
  }

  let events = skalender && skalender.allEvents ? skalender.allEvents : [];
  let allEventsNext = [];
  for (let i = 0; i < events.length; i++) {
    let eventNext =
      getUnixtime(events[i].to) >= getUnixTime(new Date()) ? true : false;
    if (allEventsNext.length < 5 && eventNext) {
      allEventsNext.push(events[i]);
    }
  }
  let allEventsPast = [];
  for (let i = 0; i < events.length; i++) {
    let eventPast =
      getUnixtime(events[i].to) < getUnixTime(new Date()) ? true : false;
    if (allEventsPast.length < 5 && eventPast) {
      allEventsPast.push(events[i]);
    }
  }

  const ListEventsNext =
    allEventsNext && allEventsNext.length > 0 ? (
      allEventsNext.map((item, key) => (
        <div className='is-size-7' key={key}>
          <i
            className='fas fa-square icon is-size-7 mr-1'
            style={{ color: item.color }}
          />
          {item.title} <br />
          <span className='is-italic has-text-grey' style={{ marginLeft: 28 }}>
            {item.date}
          </span>
        </div>
      ))
    ) : (
      <div className='is-size-7 is-italic'>
        <i className='fas fa-square icon is-size-7 has-text-grey-lighter mr-1' />
        No Event
      </div>
    );

  const ListEventsPast =
    allEventsPast && allEventsPast.length > 0 ? (
      allEventsPast.reverse().map((item, key) => (
        <div className='is-size-7 mb-1' key={key}>
          <i
            className='fas fa-square icon is-size-7 mr-1'
            style={{ color: item.color }}
          />
          {item.title} <br />
          <span className='is-italic has-text-grey' style={{ marginLeft: 28 }}>
            {item.date}
          </span>
        </div>
      ))
    ) : (
      <div className='is-size-7 is-italic'>
        <i className='fas fa-square icon is-size-7 has-text-grey-lighter mr-1' />
        No Event
      </div>
    );

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/kalender-akademik'}>
      <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/kalender-akademik')}
                        >
                          <i className='fas fa-calendar-alt icon' />
                          Kalender Akademik
                        </span>
                      </p>
                      <div>
                        <p className='has-text-right'>
                          {skalender &&
                            skalender.start &&
                            skalender.start.split(' ')[1]}{' '}
                          {skalender &&
                            skalender.start &&
                            skalender.start.split(' ')[2]}{' '}
                          -{' '}
                          {skalender &&
                            skalender.end &&
                            skalender.end.split(' ')[1]}{' '}
                          {skalender &&
                            skalender.end &&
                            skalender.end.split(' ')[2]}
                        </p>
                      </div>
                    </div>
                  </header>
                  <div id='fadein' className='card-content' style={{ paddingLeft: -20 }}>
                    {skalender && !skalender.allEvents && (
                      <p className='ml-2 is-italic'>Tidak ada kalender</p>
                    )}
                    {skalender && skalender.allEvents && (
                      <div className='content overflow-auto'>
                        <div
                          className='content table-container mt-3 ml-2 mr-2'
                          style={mode === 'yearlyMode' ? { minWidth: 460 } : {}}
                        >
                          <Calendar
                            onChange={(date) => handleMode(date)}
                            ref={elKalender}
                            events={skalender.allEvents}
                          />
                          {mode === 'monthlyMode' && (
                            <>
                              <div className='ml-1 mt-3 mb-2 has-text-weight-bold has-text-danger is-size-7'>
                                Next Event
                              </div>
                              {ListEventsNext}
                              <div className='ml-1 mt-3 mb-2 has-text-weight-bold has-text-danger is-size-7'>
                                Past Event
                              </div>
                              {ListEventsPast}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
