import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getPresensi } from './redux/reduxApi';
import PieofChart from '../../../common/components/PieofChart';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter(
      (i) => i.id === 'absensi'
    )[0];
  }
  return {
    auth: state.auth,
    spresensi: state.spresensi,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getPresensi,
};

function Index(props) {
  let history = useHistory();
  const { swipeToggleStatus, getPresensi, auth, spresensi } = props;
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    getPresensi(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  const dayTotal =
    spresensi && spresensi.total ? spresensi.total.split('/')[1] + '' : '';
  const dayPresence =
    spresensi && spresensi.total
      ? parseInt(spresensi.total.split('/')[1]) -
        parseInt(spresensi.total.split('/')[0])
      : '';
  const sakit = spresensi && spresensi.sakit && parseInt(spresensi.sakit);
  const izin = spresensi && spresensi.izin && parseInt(spresensi.izin);
  const alpha = spresensi && spresensi.alpha && parseInt(spresensi.alpha);
  const dataVal = [
    { title: 'Hadir', value: dayPresence, color: '#3f8ed0' },
    { title: 'Sakit', value: sakit, color: '#f9d855' },
    { title: 'Izin', value: izin, color: '#49c78e' },
    { title: 'Alpha', value: alpha, color: '#dc3912' },
  ];

  const dataChart = dataVal.filter((i) => {
    return parseInt(i.value) > 0;
  });

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/absensi'}>
      <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/absensi')}
                        >
                          <i className='fas fa-tasks icon' />
                          Absensi
                        </span>
                      </p>
                    </div>
                  </header>
                  <div className='card-content'>
                    <div id='fadein'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div
                        className='ml-4 mr-4 mt-4 mb-4'
                        style={{
                          flexGrow: 1,
                        }}
                      >
                        <PieofChart dataChart={dataChart} />
                      </div>
                      <div
                        className='mt-4 mb-4'
                        style={{
                          flexGrow: 4,
                          alignSelf: 'center',
                        }}
                      >
                        <div className='ml-4 mr-4'>
                          <div
                            className='mt-2 mb-2'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div className='has-text-weight-medium custom-text-14'>
                              <i className='fas fa-stop mr-1 has-text-info' />{' '}
                              Kehadiran
                            </div>
                            <div className='custom-text-14'>{dayPresence}</div>
                          </div>
                          <hr
                            style={{ marginTop: 0, marginBottom: 0, height: 1 }}
                          />
                          <div
                            className='mt-2 mb-2'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div className='has-text-weight-medium custom-text-14'>
                              <i className='fas fa-stop mr-1 has-text-warning' />{' '}
                              Sakit
                            </div>
                            <div className='custom-text-14'>{sakit}</div>
                          </div>
                          <hr
                            style={{ marginTop: 0, marginBottom: 0, height: 1 }}
                          />
                          <div
                            className='mt-2 mb-2'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div className='has-text-weight-medium custom-text-14'>
                              <i className='fas fa-stop mr-1 has-text-success' />{' '}
                              Izin
                            </div>
                            <div className='custom-text-14'>{izin}</div>
                          </div>
                          <hr
                            style={{ marginTop: 0, marginBottom: 0, height: 1 }}
                          />
                          <div
                            className='mt-2 mb-2'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div className='has-text-weight-medium custom-text-14'>
                              <i className='fas fa-stop mr-1 has-text-danger' />{' '}
                              Alpha
                            </div>
                            <div className='custom-text-14'>{alpha}</div>
                          </div>
                          <hr
                            style={{ marginTop: 0, marginBottom: 0, height: 1 }}
                          />
                          <div
                            className='mt-2 mb-2'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div className='has-text-weight-medium custom-text-14'>
                              <i className='fas fa-stop mr-1 has-text-grey-lighter' />{' '}
                              Total Hari
                            </div>
                            <div className='custom-text-14'>{dayTotal}</div>
                          </div>
                          <hr
                            style={{ marginTop: 0, marginBottom: 0, height: 1 }}
                          />
                          <div
                            className='mt-2 mb-2'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div className='has-text-weight-medium custom-text-14'>
                              <i className='fas fa-stop mr-1 has-text-grey-light' />{' '}
                              Absensi Terakhir
                            </div>
                            <div className='custom-text-14'>
                              {spresensi.masuk}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
