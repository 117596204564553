import React, { Component, Fragment } from 'react';
import { SITE_ADDRESS } from '../../../common/util/siteConfig';
import Menu from './menu/Menu';
import UserProfile from '../../../../images/user-default.png';

class SideMenu extends Component {
  state = {
    studentSelected: '',
  };

  componentDidMount() {
    this.handleTogglerLoad();
    this.setState({
      studentSelected: this.props.auth && this.props.auth.userId,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.swipe !== this.props.swipe) {
      let sideMenu = document.getElementsByClassName('menu-container')[0];
      sideMenu.classList.add('active');
      this.props.handleTogglerOnOff(this.props.isTogglerActive);
      this.props.swipeToggleStatus(this.props.swipe);
      const burger = document.getElementById('burger');
      if (burger) {
        burger.classList.add('active');
        burger.classList.remove('not-active');
      }
    }
  }

  handleTogglerLoad = () => {
    let container = document.body.parentElement;
    let navBurger = document.getElementsByClassName('navbar-burger')[0];
    let sideMenu = document.getElementsByClassName('menu-container')[0];
    let logoutBtn = document.getElementById('logoutBtn');
    let menuList = document.getElementsByClassName('menu-list')[1];
    container.addEventListener('click', (event) => {
      if (sideMenu.contains(event.target) || navBurger.contains(event.target)) {
        this.props.handleInsideClick(true);
      } else {
        this.props.handleInsideClick(false);
      }
      if (
        this.props.toggler &&
        sideMenu.classList.contains('active') &&
        menuList.contains(event.target)
      ) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
        sideMenu.classList.remove('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.add('not-active');
          burger.classList.remove('active');
        }
      }
      if (!this.props.inside && sideMenu.classList.contains('active')) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
        sideMenu.classList.remove('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.add('not-active');
          burger.classList.remove('active');
        }
      }
      if (this.props.swipe && sideMenu.classList.contains('active')) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
        sideMenu.classList.remove('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.add('not-active');
          burger.classList.remove('active');
        }
      }
      if (logoutBtn.contains(event.target)) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
      }
    });
  };

  handleDropDown = (e) => {
    const userIdnew = e.target.value;
    let selectedItem =
      this.props.userrole.group.filter((item) => {
        return item.userId === userIdnew;
      })[0];
    const { auth, history } = this.props;
    this.setState({
      studentSelected: userIdnew,
    });

    if (userIdnew !== auth.userId) {
      this.props.updateAuth(
        { userIdnew, username: selectedItem.name },
        auth,
        history
      );
    }
  };

  render() {
    const { auth, menus, logout, toggler, pathname, sprofil, userrole } =
      this.props;
    return (
      <Fragment>
        <div
          id='sm'
          className={
            toggler === true
              ? 'menu-container active px-1 has-background-white'
              : 'menu-container px-1 has-background-white'
          }
        >
          <div className='menu-wrapper py-1'>
            <aside id='fadein' className='menu'>
              <div className='menu-list profile-sidebar'>
                <div className='level' style={{ marginBottom: 8 }}>
                  <div className='level-item'>
                    <figure className='image is-48x48'>
                      <div
                        style={{
                          width: 48,
                          height: 48,
                          backgroundImage: `url(${
                            sprofil && sprofil.urlPhoto
                              ? SITE_ADDRESS + sprofil.urlPhoto
                              : UserProfile
                          })`,
                          borderRadius: '50%',
                          backgroundSize: 'cover',
                        }}
                      />
                    </figure>
                  </div>
                </div>
                <div style={{ marginBottom: 4, wordWrap: 'break-word' }}>
                  <div className='flex-column-center'>
                    {userrole && userrole.role !== 'Siswa' && (
                      <>
                        {userrole &&
                          userrole.group &&
                          userrole.group.length <= 1 && (
                            <div
                              className='title is-7 is-capitalized has-text-centered'
                              style={{ marginBottom: -2 }}
                            >
                              {auth.username}
                            </div>
                          )}
                        {userrole &&
                          userrole.group &&
                          userrole.group.length > 1 && (
                            <div className='select is-small is-primary'>
                              <select
                                value={this.state.studentSelected}
                                onChange={this.handleDropDown}
                              >
                                {userrole &&
                                  userrole.group &&
                                  userrole.group.map((item, key) => (
                                    <option value={item.userId} key={key}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          )}
                        <div className='subtitle is-7 mt-1 has-text-weight-medium'>
                          {auth.vusn}
                        </div>
                      </>
                    )}
                    {userrole && userrole.role === 'Siswa' && (
                      <div className='title is-7 is-capitalized has-text-centered'>
                        {auth.username}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <ul className='menu-list'>
                {menus.map((i) => (
                  <Menu
                    auth={auth}
                    sprofil={sprofil}
                    pathname={pathname}
                    key={i.id}
                    m={i}
                    c={auth.authorities.detail}
                  />
                ))}
                <li>
                  <div
                    id='logoutBtn'
                    onClick={() => logout('logoutNav')}
                    className='disable-select custom-text-14 custom-text-overflow custom-hover-light-info'
                  >
                    <i className='fas fa-sign-out-alt icon' /> Logout
                  </div>
                </li>
              </ul>
              <br />
              <br />
            </aside>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SideMenu;
