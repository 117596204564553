import React from 'react';

const TextArea = ({
  input,
  width,
  rows,
  type,
  defaultValue,
  placeholder,
  readOnly,
  label,
  style,
  meta: { touched, error },
}) => {
  return (
    <div className='field'>
      {label && label !== 'noLabel' && <label className='label'>{label}</label>}
      <div className='control'>
        {defaultValue && (
        <textarea
          className={touched && !!error ? 'textarea is-danger' : 'textarea'}
          rows={rows}
          {...input}
          value={defaultValue}
          type={type}
          placeholder={placeholder}
          style={style}
          readOnly={readOnly}
        />)}
        {!defaultValue && (
        <textarea
          className={touched && !!error ? 'textarea is-danger' : 'textarea'}
          rows={rows}
          {...input}
          type={type}
          placeholder={placeholder}
          style={style}
          readOnly={readOnly}
        />)}
      </div>
      {touched && error && <p className='help is-danger'>{error}</p>}
    </div>
  );
};

export default TextArea;
