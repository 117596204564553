import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../../common/swipe/reduxApi';
import { getDay, getDate, getMonth, getYear } from 'date-fns';
import { SWIPE_STYLE } from '../../../common/styles/swipe_style';
import { getJadwal } from './redux/reduxApi';
import PullDownWrapper from '../../../common/layout/PullDownWrapper';

const NEWDATE = new Date();
const DAYS = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
const MONTHS = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];
const TODAYDATE = `${DAYS[getDay(NEWDATE)]}, ${getDate(NEWDATE)} ${
  MONTHS[getMonth(NEWDATE)]
} ${getYear(NEWDATE)}`;

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities.detail.m.filter((i) => i.id === 'jadwal')[0];
  }
  return {
    auth: state.auth,
    sjadwal: state.sjadwal,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  swipeToggleStatus,
  getJadwal,
};

function Index(props) {
  let history = useHistory();
  const { swipeToggleStatus, getJadwal, auth, sjadwal } = props;
  const [daynow, setDaynow] = useState(0);
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });

  useEffect(() => {
    setDaynow(getDay(NEWDATE));
  }, []);

  useEffect(() => {
    getJadwal(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  const schedule = sjadwal.filter((e) => {
    return e.split(';')[0] === DAYS[daynow];
  });

  const ListItems = schedule.map((item, key) => (
    <tr key={key}>
      <td>{item && item.split(';')[1]}</td>
      <td>{item && item.split(';')[2]}</td>
      <td>{item && item.split(';')[3]}</td>
    </tr>
  ));

  const handleDay = (value) => {
    setDaynow(value);
  };

  return (
    <>
      <PullDownWrapper auth={auth} history={history} link={'/jadwal'}>
      <div {...handlers} style={SWIPE_STYLE} />
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-primary has-text-white'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <span
                          className='hand-pointer'
                          onClick={() => handleLink('/jadwal')}
                        >
                          <i className='fas fa-clock icon' />
                          Jadwal
                        </span>
                      </p>
                      <div>
                        <p className='has-text-right'>{TODAYDATE}</p>
                      </div>
                    </div>
                  </header>
                  <div id='fadein' className='card-content' style={{ paddingLeft: -20 }}>
                    {sjadwal && sjadwal.length < 1 && (
                      <div id='fadein2' className='ml-2 is-italic'>
                        Tidak ada jadwal
                      </div>
                    )}
                    {sjadwal && sjadwal.length > 0 && (
                      <div className='content' style={{ marginTop: -16 }}>
                        <div className='custom-content-flex'>
                          <div
                            className={`custom-box-flex has-text-centered custom-weekend${
                              daynow === 0 ? '-active' : ''
                            }`}
                            onClick={() => handleDay(0)}
                          >
                            Min<span className='is-hidden-mobile'>ggu</span>{' '}
                          </div>
                          <div
                            className={`custom-box-flex has-text-centered custom-weekday${
                              daynow === 1 ? '-active' : ''
                            }`}
                            onClick={() => handleDay(1)}
                          >
                            Sen<span className='is-hidden-mobile'>in</span>
                          </div>
                          <div
                            className={`custom-box-flex has-text-centered custom-weekday${
                              daynow === 2 ? '-active' : ''
                            }`}
                            onClick={() => handleDay(2)}
                          >
                            Sel<span className='is-hidden-mobile'>asa</span>
                          </div>
                          <div
                            className={`custom-box-flex has-text-centered custom-weekday${
                              daynow === 3 ? '-active' : ''
                            }`}
                            onClick={() => handleDay(3)}
                          >
                            Rab<span className='is-hidden-mobile'>u</span>
                          </div>
                          <div
                            className={`custom-box-flex has-text-centered custom-weekday${
                              daynow === 4 ? '-active' : ''
                            }`}
                            onClick={() => handleDay(4)}
                          >
                            Kam<span className='is-hidden-mobile'>is</span>
                          </div>
                          <div
                            className={`custom-box-flex has-text-centered custom-weekday${
                              daynow === 5 ? '-active' : ''
                            }`}
                            onClick={() => handleDay(5)}
                          >
                            Jum<span className='is-hidden-mobile'>at</span>
                          </div>
                          <div
                            className={`custom-box-flex has-text-centered custom-weekend${
                              daynow === 6 ? '-active' : ''
                            }`}
                            onClick={() => handleDay(6)}
                          >
                            Sab<span className='is-hidden-mobile'>tu</span>
                          </div>
                        </div>
                        <p
                          style={{
                            marginTop: 7,
                            marginBottom: -0,
                            marginLeft: 28,
                          }}
                        >
                          {DAYS[daynow]}
                        </p>
                        <div className='custom-content-flex'>
                          {schedule.length < 1 && (
                            <p className='ml-5 mt-2 is-italic'>
                              Tidak ada jadwal
                            </p>
                          )}
                          <div className='custom-schedule has-text-left ml-4 mr-4'>
                            <table>
                              <tbody>{ListItems}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
